import { DateTime } from "luxon";
import {
  AggregateData,
  IUIFriendlyPowerData,
  PlantPowerData,
  PowerData,
  ThunkState
} from "../../types/redux";
import {
  EnvironmentalData,
  FinancialData,
  DeviceStatus
} from "../../types/plant";
import { RootState } from "../store/configureStore";
import { plantDataToUIFriendlyPowerData } from "../utils";
import { selectCurrentPlant } from "./plants";
import { datetimeToDateKey } from "../../util/time";
import { getIsGridConected } from "../../util/plant";

export const getCurrentPlantMostRecentPowerData = (state: RootState):
  IUIFriendlyPowerData | null => {
  const currentPlant = selectCurrentPlant(state);

  if (!currentPlant || currentPlant.currentPlantData === undefined) return null;
  return plantDataToUIFriendlyPowerData(currentPlant.currentPlantData);
};

export const isPowerDataFetching = (state: RootState):
  boolean => state.appData.powerData.fetchState !== ThunkState.done;

export const selectEpochOfLastPowerDataFetch = (state: RootState):
  DateTime | null =>
  state.appData.powerData.epochOfLastFetched
    ? DateTime.fromMillis(state.appData.powerData.epochOfLastFetched) : null;

export const getPowerDataForCurrentPlant = (state: RootState): PlantPowerData | null => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return null;
  return state.appData.powerData.data[currentPlant.plantId] ?? null;
};

export const getAllPowerData = (state: RootState): PowerData => state.appData.powerData.data;

export const getAggregatePlantData = (state: RootState): AggregateData =>
  state.appData.aggregatePlantData.data;

export const getIsAggregateDataFetching = (state: RootState): boolean =>
  state.appData.aggregatePlantData.fetchState !== ThunkState.done;

export const getDeviceStatus = (state: RootState): {
  solarStatus: DeviceStatus,
  gridStatus: DeviceStatus,
  generatorStatus: DeviceStatus,
  batteryDischargingStatus: DeviceStatus,
  batteryChargingStatus: DeviceStatus,
  homeStatus: DeviceStatus,
  tradingStatus: DeviceStatus,
  evStatus: DeviceStatus,
} => {

  const currentPlant = selectCurrentPlant(state);
  const currentPowerData = currentPlant?.currentPlantData;

  if (!currentPowerData || !currentPlant) return {
    solarStatus: DeviceStatus.Unavailable,
    gridStatus: DeviceStatus.Unavailable,
    batteryChargingStatus: DeviceStatus.Unavailable,
    batteryDischargingStatus: DeviceStatus.Unavailable,
    generatorStatus: DeviceStatus.Unavailable,
    homeStatus: DeviceStatus.Unavailable,
    tradingStatus: DeviceStatus.Unavailable,
    evStatus: DeviceStatus.Unavailable,
  };

  const isGridConnected = getIsGridConected(currentPlant);
  const hasSmartEvCharger = currentPlant.hasSmartEvCharger ?? false;

  const {
    gridImport,
    gridExport,
    pvPower,
    loadPower,
    generatorPower,
    evPower,
    batteryDischarge,
    batteryCharge
  } = currentPowerData;

  const determineStatus = (
    val: number | undefined | null,
    preferenceForValue: "positive" | "negative" | "either" = "positive"
  ): DeviceStatus => {
    if (val === undefined || val === null) return DeviceStatus.Disabled;
    return (
      (preferenceForValue === "positive" && val > 0) ||
      (preferenceForValue === "negative" && val < 0) ||
      (preferenceForValue === "either" && (val > 0 || val < 0))
    ) ? DeviceStatus.Enabled : DeviceStatus.Disabled;
  };

  return {
    solarStatus: determineStatus(pvPower),
    batteryChargingStatus: determineStatus(batteryCharge),
    batteryDischargingStatus: determineStatus(batteryDischarge),
    tradingStatus: isGridConnected
      ? determineStatus(gridExport)
      : DeviceStatus.Unavailable,
    gridStatus: isGridConnected
      ? determineStatus(gridImport)
      : DeviceStatus.Unavailable,
    generatorStatus: !isGridConnected
      ? determineStatus(generatorPower)
      : DeviceStatus.Unavailable,
    homeStatus: determineStatus(loadPower, "either"),
    evStatus: hasSmartEvCharger
      ? determineStatus(evPower)
      : DeviceStatus.Unavailable
  };
};

export function getYesterdaysCumulativeData(state: RootState): {
  yesterdayFinData: FinancialData,
  todaysEnviroData: EnvironmentalData,
} {
  const blankEnviroData: EnvironmentalData = {
    plantId: "",
    date: "",
    unixTimestamp: 0,
    pvWattHours: 0,
    nonRenewableWattHours: 0,
    activePowerUsageWattHours: 0,
    gridImportWattHours: 0,
    gridExportWattHours: 0,
    batteryDischargeWattHours: 0,
    batteryChargeWattHours: 0
  };

  const blankFinData: FinancialData = {
    plantId: "",
    unixTimestamp: 0,
    date: "",
    estimatedCost: 0,
    withoutPvCost: 0,
    energyEarnings: 0,
    cryptoEarningsETH: 0,
    cryptoEarningsAUD: 0
  };
  const yesterdayDateKey = datetimeToDateKey(DateTime.now().minus({ days: 1 }));
  const todaysDateKey = datetimeToDateKey(DateTime.now());

  const allFinData = state.appData.cumulativeFinancialData.data;
  const allEnviroData = state.appData.cumulativeEnvironmentalData.data;
  return {
    yesterdayFinData: allFinData[yesterdayDateKey]
      ? allFinData[yesterdayDateKey] : blankFinData,
    todaysEnviroData: allEnviroData[todaysDateKey]
      ? allEnviroData[todaysDateKey] : blankEnviroData
  };
}