/** RedPi endpoint helper functions */

import {
  RedPiDeviceThunkResponse,
  RedPiCommandsThunkResponse,
  RedPiLogsThunkResponse,
  RedPiCommandResponseThunkResponse,
} from "../types/redux";
import {
  GetRedPiDevicesQueryParams,
  GetRedPiLogsQueryParams,
  PostRedPiCommandBody,
  RequestResult
} from "../types/server";
import { request } from ".";

export function fetchRedPiDevices(
  query: GetRedPiDevicesQueryParams,
): RequestResult<RedPiDeviceThunkResponse> {

  return request(
    "v3/redpi",
    "GET",
    undefined,
    undefined,
    query
  );
}

export function fetchRedPiCommands(): RequestResult<RedPiCommandsThunkResponse> {
  return request(
    "v3/redpi/commands",
    "GET",
    undefined,
    undefined,
    undefined
  );
}

export function fetchRedPiLogs(
  query: GetRedPiLogsQueryParams,
): RequestResult<RedPiLogsThunkResponse> {
  return request(
    "v3/redpi/logs",
    "GET",
    undefined,
    undefined,
    query
  );
}

export function postRedPiCommand(
  id: string, body: PostRedPiCommandBody
): RequestResult<RedPiCommandResponseThunkResponse | undefined> {
  return request(
    `v3/redpi/commands/${id}`,
    "POST",
    body,
    undefined
  );
}