import { OrderDirection } from "../types/trading";

export const tradingOrderToSemanticName: Record<OrderDirection, string> = {
  None: "None",
  MaximiseImports: "Maximise Imports",
  ChargeBattery: "Charge Battery",
  ChargeBatteryBanExports: "Charge Battery Ban Exports",
  SelfConsumption: "Self Consumption",
  SelfConsumptionBanExports: "Self Consumption Ban Exports",
  MaximiseExports: "Maximise Exports",
  NoData: "No Data",
  TrickleCharge: "Trickle Charge",
  MaximiseImportsBanExports: "Maximise Imports Ban Exports",
  MaximiseExportsBatteryPassive: "Maximise Exports Battery Passive",
};