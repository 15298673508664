import React, { useEffect, useState } from "react";
import Loading from "components/Loading/Loading";
import { useAppSelector } from "apps-middleware/redux/store/hooks";
import { selectCurrentPlantId } from "apps-middleware/redux/selectors/plants";
import { getGraphDataForCurrentPlant } from "redux/graphing-selectors";
import { WarningText } from "components/WarningText/WarningText";
import { getEnergyChartOptions } from "util/localStorage";
import { PieChart } from "@mui/x-charts/PieChart";
import { EnvironmentalData, PlantId } from "apps-middleware/types/plant";
import { DateTime } from "luxon";
import { usePlant } from "apps-middleware/hooks/usePlant";
import { useFinancialData } from "apps-middleware/hooks/useCachedData";
import PowerFlowDiagram from "./PowerFlowDiagram";

import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Divider,
  styled,
} from "@mui/material";
import styles from "../Home/home.module.scss";
import { powerProfileDataKeys } from "constants/graph";
import { UNITS } from "apps-middleware/constants/general";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import {
  fetchEnvironmentalData,
  fetchWeather,
} from "apps-middleware/api/plant";
import {
  CachedDataIntervals,
  ICustomResponse,
  IWeatherResponse,
} from "apps-middleware/types/server";
import { wattToKiloWatt } from "apps-middleware/util/general";
import { IDateRange } from "apps-middleware/types/time";
import { getBodyContentHeight } from "util/general";
import { TimeScaleLineChart } from "components/Charts/TimeScaleLineChart";
import { Restore } from "@mui/icons-material";
import { BarChart } from "@mui/x-charts";
import {
  LabelledTimeRange,
  TIME_RANGES,
  UTC_DATE_FORMAT,
} from "apps-middleware/util/time";

function PageBody({ id }: { id: PlantId }): JSX.Element | null {
  // Function to fetch data for today
  async function fetchEnvironmentalDataForToday(plantId: string) {
    const today = DateTime.local(); // Get today"s date
    const startDate = today
      .startOf("day")
      .toISO({ suppressMilliseconds: true });
    const endDate = today.endOf("day").toISO({ suppressMilliseconds: true });

    try {
      const result = await fetchEnvironmentalData(
        plantId,
        CachedDataIntervals.Daily,
        {
          startDate: encodeURIComponent(startDate),
          endDate: encodeURIComponent(endDate),
        }
      );
      return result?.data?.data; // Or do something else with the result
    } catch (error) {
      console.error("Error fetching environmental data:", error);
      throw error;
    }
  }

  const [epochOfLastUpdate, setEpochOfLastUpdate] = useState(DateTime.now());
  const plant = usePlant({
    plantId: id,
    defaultTradeHistoryFidelity: getEnergyChartOptions().tradeHistoryFidelity,
  });

  const { combinedData, dataKeysWithData } = useAppSelector(
    getGraphDataForCurrentPlant
  );

  const isolatedSolarSparkData = combinedData.map(
    (dataPoint) => dataPoint.pvPower
  ) as number[];

  const isolatedGridSparkData = combinedData.map(
    (dataPoint) => dataPoint.gridPower
  ) as number[];

  const isolatedGeneratorSparkData = combinedData.map(
    (dataPoint) => dataPoint.generatorPower
  ) as number[];

  const isolatedEVSparkData = combinedData.map(
    (dataPoint) => dataPoint.evPower
  ) as number[];

  const isolatedTimestampsSparkData = combinedData.map((dataPoint) => {
    const unixTimestamp = dataPoint.unixTimestamp;
    return unixTimestamp ? new Date(unixTimestamp) : new Date();
  }) as Date[];

  useEffect(() => {
    const intervalId = setInterval(grabData, 15000); // 60000ms = 60 seconds
    function grabData() {
      if (!plant) return;

      plant.plantInfo.refresh();
      setEpochOfLastUpdate(DateTime.now());
    }

    return () => clearInterval(intervalId);
  }, []);

  const [environmentalData, setEnvironmentalData] = useState<
    EnvironmentalData | undefined
  >(undefined);

  const financialData = useFinancialData({
    plantId: id,
  });

  const [plotBounds, setPlotBounds] = useState<LabelledTimeRange>(
    TIME_RANGES.TODAY
  );

  const [forceDataRefresh, setForceDataRefresh] = useState<number>(1);

  useEffect(
    function collectDataIfNecessary() {
      if (!plant) return;
      plant.plantInfo.refresh();
      fetchEnvironmentalDataForToday(id).then((data) => {
        const todaysDate = new Date().toISOString()
          .split("T")[0];
        const formattedData: Record<string, EnvironmentalData> | undefined =
          data;
        const todaysEnvironmentalData = formattedData?.[todaysDate];
        setEnvironmentalData(todaysEnvironmentalData);
      });
      plant.powerData.refresh(plotBounds);
      plant.priceData.refresh(plotBounds);
      financialData.get(CachedDataIntervals.Daily, {
        startDate: plotBounds.range.startDateTime.toFormat(UTC_DATE_FORMAT),
        endDate: plotBounds.range.endDateTime.toFormat(UTC_DATE_FORMAT),
      });
      plant.tradingHistory.refresh(plotBounds);

      setEpochOfLastUpdate(DateTime.now());
    },
    [plotBounds, id, forceDataRefresh]
  );

  const [zoomedDomain, setZoomedDomain] = useState<IDateRange | null>(null);

  const [graphHeight, setGraphHeight] = React.useState<number>(
    getBodyContentHeight() * 0.8
  );

  const commonGraphProps = {
    plotBounds: plotBounds.range,
    graphHeight: graphHeight,
    zoomedDomain,
    setZoomedDomain,
    syncId: "ENERGY_CHARTS",
    dataKeysToConsider: dataKeysWithData,
    data: combinedData.filter(
      (row) =>
        row.unixTimestamp &&
        row.unixTimestamp > plotBounds.range.startDateTime.toMillis() &&
        row.unixTimestamp < plotBounds.range.endDateTime.toMillis()
    ),
  };

  const GridBarData = [
    {
      import: wattToKiloWatt(
        (environmentalData?.gridImportWattHours as number) || 0
      ),
      export: wattToKiloWatt(
        (environmentalData?.gridExportWattHours as number) || 0
      ),
    },
  ];

  const GeneratorBarData = [
    {
      generatorWattHours: wattToKiloWatt(
        (environmentalData?.generatorWattHours as number) || 0
      ),
      batteryChargeWattHours: wattToKiloWatt(
        (environmentalData?.batteryChargeWattHours as number) || 0
      ),
    },
  ];

  const BatteryPieData = [
    {
      id: 0,
      value: wattToKiloWatt(
        (environmentalData?.batteryChargeWattHours as number) || 0
      ),
      label: "Battery Filled (kWh)",
    },
    {
      id: 1,
      value: wattToKiloWatt(
        (environmentalData?.batteryDischargeWattHours as number) || 0
      ),
      label: "Battery Used (kWh)",
    },
  ];

  const loadingComponent = (
    <div
      style={{
        minHeight: "calc(100vh - 110px)",
        display: "flex",
        alignContent: "center",
      }}
    >
      <Loading informationText={"Fetching Data..."} />
    </div>
  );

  function resetZoomedDomain() {
    setZoomedDomain(null);
  }

  // Need to check plants fetching before the !currentPlant, as the current
  // plant might not be set till plants finish fetching and show the incorrect
  // screen pre-load
  if (!plant) {
    return loadingComponent;
  } else if (!combinedData) {
    return (
      <WarningText
        message="There was an error when loading the device."
        showNetworkWarning
      />
    );
  }

  const valueFormatter = (value: number | null) => `${value} kWh`;

  const [weatherData, setWeatherData] = useState<IWeatherResponse | null>(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      if (plant?.plantId) {
        try {
          const response: ICustomResponse<IWeatherResponse, number> | null =
            await fetchWeather(plant.plantId);
          if (response && response.ok) {
            setWeatherData(response.data); // Extract data from the response
          } else if (response) {
            console.error(`Error: ${response.statusText}`);
          } else {
            console.error("Failed to fetch weather data");
          }
        } catch (error) {
          console.error("Failed to fetch weather data");
        }
      }
    };

    fetchWeatherData();
  }, []); // Empty dependency array to run effect only once

  interface GridPriceInfoProps {
    exportPrice: number;
    importPrice: number;
    gridImport: number;
    netSavingsOrCost: number;
    gridUsage: number;
    netUsageSavings: number;
  }

  const financialCurrentDiv = styled("div")({
    maxWidth: "170px",
    textAlign: "center",
    margin: "0 auto",
  });

  const GridPriceInfo: React.FC<GridPriceInfoProps> = ({
    exportPrice,
    importPrice,
    gridImport,
    netSavingsOrCost,
    netUsageSavings,
    gridUsage,
  }) => {
    return (
      <Box sx={{ padding: 0 }}>
        <Typography variant="h6" gutterBottom>
          Grid Price Information
        </Typography>
        <Typography variant="body1">
          <strong>Current Export Price:</strong> ${(exportPrice / 100).toFixed(2)} per
          kWh
        </Typography>
        <Typography variant="body1">
          <strong>Current Import Price:</strong> ${(importPrice / 100).toFixed(2)} per
          kWh
        </Typography>
        <Typography variant="body1">
          <strong>Realtime Grid Usage:</strong> {(gridUsage > 0)
            ? (Math.abs(gridUsage / 1000).toFixed(2) + " kW import")
            : (Math.abs(gridUsage / 1000).toFixed(2) + " kW Export")}
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ maxWidth: "170px", textAlign: "center", padding: "0 10px" }}>
            <Typography variant="body2" gutterBottom>
              <strong>
                {netSavingsOrCost >= 0 ? "Your system is currently making you"
                  : "Electricity is costing you"}
              </strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: netSavingsOrCost >= 0 ? "lime" : "red" }}
            >
              <strong>
                ${Math.abs(netSavingsOrCost / 100000).toFixed(2)} per hour
              </strong>
            </Typography>
          </div>

          <div style={{ maxWidth: "170px", textAlign: "center", padding: "0 10px" }}>
            <Typography variant="body2" gutterBottom>
              <strong>
                Your system is currently saving you
              </strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: netUsageSavings >= 0 ? "lime" : "red" }}
            >
              <strong>
                ${netUsageSavings > 0 ? (netUsageSavings / 100000).toFixed(2) : 0} per hour
              </strong>
            </Typography>
          </div>
        </div>

      </Box>
    );
  };

  const PriceCalculator = () => {
    const priceData = plant?.priceData?.current?.raw;

    if (!plant?.isPricingConfigured) {
      return <Typography>Pricing not configured...</Typography>;
    }

    if (!priceData) {
      return <Typography>Loading...</Typography>;
    }

    const exportPrice = priceData?.exportPrice;
    const importPrice = priceData?.importPrice;
    const gridImport = ((plant?.currentPlantData?.gridImport ?? 0));
    const gridExport = ((plant?.currentPlantData?.gridExport ?? 0));
    const gridUsage = gridImport - gridExport;

    const systemGeneration = plant?.currentPlantData?.loadPower as number - gridImport;

    const netUsageSavings = (systemGeneration) * importPrice;

    // Calculate savings or costs per hour
    const costPerHour = gridImport * importPrice;
    const savingsPerHour = gridExport * exportPrice;
    const netSavingsOrCost = savingsPerHour - costPerHour;

    return (
      <GridPriceInfo
        exportPrice={exportPrice}
        importPrice={importPrice}
        gridImport={gridImport}
        netSavingsOrCost={netSavingsOrCost}
        gridUsage={gridUsage}
        netUsageSavings={netUsageSavings}
      />
    );
  };

  const renderedRealtimeData = {
    SOC: plant?.powerData?.current?.uiFriendly?.batterySoc?.value,
    BatteryPower: plant?.powerData?.current?.uiFriendly?.batteryPower?.value,
    GridPower: plant?.powerData?.current?.uiFriendly?.gridPower?.value,
    LoadPower: plant?.powerData?.current?.uiFriendly?.loadPower?.value,
    PvPower: plant?.powerData?.current?.uiFriendly?.pvPower?.value,
    lastUpdated: epochOfLastUpdate,
    chassis: plant?.chassisId,
    postcode: plant?.userLocation?.postcode,
    state: plant?.userLocation?.state,
    EVPower: plant?.powerData?.current?.uiFriendly?.evPower?.value,
    Capacity: plant?.batteryInfo?.capacityCurrent,
    ConfigType: plant?.configurationType,
    GeneratorPower:
      plant?.powerData?.current?.uiFriendly?.generatorPower?.value,
    GeneratorVoltage:
      plant?.powerData?.current?.uiFriendly?.generatorVoltage?.value,
    WeatherData: weatherData,
    isOffGrid: plant?.isOffGrid,
    lastDataReceived: DateTime.fromMillis(plant?.currentPlantData?.unixTimestamp || 0 * 1000),
  };

  return (
    <Grid container spacing={2} direction="row">
      {/* Power Flow Diagram */}
      <Grid item xs={4} style={{ minWidth: "414px" }}>
        <Card style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              height: "100%",
              minWidth: "414px",
              maxWidth: "414px",
            }}
          >
            <PowerFlowDiagram data={renderedRealtimeData} />
          </CardContent>
        </Card>
      </Grid>

      {/* PV Production */}
      <Grid item xs={8}>
        <Paper className={styles.valueBoxDashboard}>
          <div className={styles.content}>
            <Typography variant="subtitle2">Today's PV Production</Typography>
            <Typography variant="h5">
              {`${wattToKiloWatt((environmentalData?.pvWattHours as number) || 0)}
              ${UNITS.KILOWATTHOURS}`}
            </Typography>
          </div>
          <Box className={styles.icon} style={{ height: 70, width: "100%" }}>
            <SparkLineChart
              data={isolatedSolarSparkData}
              curve="natural"
              valueFormatter={(value) => (value === null ? "" : `${value} kW`)}
              showHighlight
              showTooltip
              colors={["orange"]}
              xAxis={{
                scaleType: "time",
                data: isolatedTimestampsSparkData,
                valueFormatter: (value) => {
                  const hours = value.getHours();
                  const minutes = value.getMinutes().toString()
                    .padStart(2, "0");
                  const amPm = hours >= 12 ? "PM" : "AM";
                  return `${hours % 12 || 12}:${minutes} ${amPm}`;
                },
              }}
            />
          </Box>
        </Paper>

        <Grid container spacing={2}>
          {/* Generator or Grid Usage */}
          <Grid item xs={6}>
            <Paper className={styles.valueBoxDashboard}>
              <div className={styles.content}>
                <Typography variant="subtitle2">
                  {plant?.isOffGrid ? "Net Generator Usage Today" : "Net Grid Usage Today"}
                </Typography>
                <Typography variant="h5">
                  {plant?.isOffGrid
                    ? `${wattToKiloWatt(Math.abs((environmentalData?.generatorWattHours as number)
                      || 0))}
                    ${UNITS.KILOWATTHOURS}`
                    : `${wattToKiloWatt(Math.abs((environmentalData?.gridImportWattHours as number)
                      ||
                    0- (environmentalData?.gridExportWattHours as number) ||
                    0))} ${UNITS.KILOWATTHOURS}`}
                </Typography>
                <Typography variant="caption">
                  {plant?.isOffGrid ? "import" : ((environmentalData?.gridImportWattHours as number)
                  ||
                  0) -
                  ((environmentalData?.gridExportWattHours as number) || 0) <
                  0 ? "Export" : "import"}
                </Typography>
              </div>
              <Box className={styles.icon} style={{ height: 70, width: "100%" }}>
                <SparkLineChart
                  data={plant?.isOffGrid ? isolatedGeneratorSparkData : isolatedGridSparkData}
                  curve="natural"
                  valueFormatter={(value) => (value === null ? "" : `${value} kW`)}
                  showHighlight
                  showTooltip
                  colors={plant?.isOffGrid ? ["lime"] : ["lime"]}
                  xAxis={{
                    scaleType: "time",
                    data: isolatedTimestampsSparkData,
                    valueFormatter: (value) => {
                      const hours = value.getHours();
                      const minutes = value.getMinutes().toString()
                        .padStart(2, "0");
                      const amPm = hours >= 12 ? "PM" : "AM";
                      return `${hours % 12 || 12}:${minutes} ${amPm}`;
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* EV Charging */}
          <Grid item xs={6}>
            <Paper className={styles.valueBoxDashboard}>
              <div className={styles.content}>
                <Typography variant="subtitle2">EV Charging Today</Typography>
                <Typography variant="h5">
                  {`${wattToKiloWatt((environmentalData?.evWattHours as number) ||
                    0)} ${UNITS.KILOWATTHOURS}`}
                </Typography>
              </div>
              <Box className={styles.icon} style={{ height: 70, width: "100%" }}>
                <SparkLineChart
                  data={isolatedEVSparkData}
                  curve="natural"
                  valueFormatter={(value) => (value === null ? "" : `${value} kW`)}
                  showHighlight
                  showTooltip
                  colors={["purple"]}
                  xAxis={{
                    scaleType: "time",
                    data: isolatedTimestampsSparkData,
                    valueFormatter: (value) => {
                      const hours = value.getHours();
                      const minutes = value.getMinutes().toString()
                        .padStart(2, "0");
                      const amPm = hours >= 12 ? "PM" : "AM";
                      return `${hours % 12 || 12}:${minutes} ${amPm}`;
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Charts and Calculators */}
          <Grid container spacing={2} style={{ paddingLeft: "20px" }}>
            <Grid item xs={6}>
              <Paper className={styles.valueBoxBottom} style={{ padding: 20,
                display: "flex", flexDirection: "column", alignItems: "center", height: "260px" }}>
                <Box style={{ height: "100%",
                  width: "100%", display: "flex",
                  flexDirection: "column", justifyContent: "space-between" }}>
                  <Box className={styles.icon} style={{ height: "100%", width: "100%" }}>
                    {plant?.isOffGrid ? (
                      <PieChart
                        colors={["cyan", "#c000ff"]}
                        series={[
                          {
                            arcLabel: (item) => `${item.value}`,
                            arcLabelMinAngle: 45,
                            data: BatteryPieData,
                            innerRadius: 25,
                            outerRadius: 75,
                            paddingAngle: 5,
                            cornerRadius: 5,
                            cx: "30%",
                            cy: "50%",
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "column",
                            position: { vertical: "middle", horizontal: "right" },
                            padding: 0,
                          },
                        }}
                      />
                    ) : (
                      <PriceCalculator />
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper className={styles.valueBoxBottom} style={{ padding: 20, display: "flex",
                flexDirection: "column", alignItems: "center", height: "262px" }}>
                <Box style={{ height: "100%", width: "100%", display: "flex",
                  flexDirection: "column", justifyContent: "space-between" }}>
                  <Box className={styles.icon} style={{ height: "100%", width: "100%" }}>
                    <Typography variant="body1">Today's Grid / Generator Usage</Typography>
                    <BarChart
                      dataset={plant?.isOffGrid ? GeneratorBarData : GridBarData}
                      xAxis={[{ scaleType: "band", dataKey: "kWh" }]}
                      series={[
                        {
                          dataKey: plant?.isOffGrid ? "generatorWattHours" : "import",
                          label: plant?.isOffGrid ? "Import" : "Import",
                          valueFormatter,
                          color: "red",
                        },
                        {
                          dataKey: plant?.isOffGrid ? "batteryChargeWattHours" : "export",
                          label: plant?.isOffGrid ? "Battery Charge" : "Export",
                          valueFormatter,
                          color: "lime",
                        },
                      ]}
                      height={250}
                      borderRadius={10}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Time Scale Line Chart */}
      <Grid item xs={12}>
        <Paper>
          <Box style={{ paddingTop: "20px", position: "relative" }}>
            {zoomedDomain && (
              <Button
                onClick={resetZoomedDomain}
                variant="contained"
                color="info"
                startIcon={<Restore />}
                style={{
                  position: "absolute",
                  right: 20,
                  top: 20,
                  zIndex: 1,
                }}
              >
                Reset Zoom
              </Button>
            )}
            <TimeScaleLineChart
              tradingChoices={plant?.tradingHistory.tradingChoices}
              isLoading={plant.powerData.isFetching}
              leftYAxis={{ datakey: powerProfileDataKeys }}
              defaultHiddenDataKeys={["pvFromAC", "pvFromDC"]}
              {...commonGraphProps}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>

  );
}
function DashboardHomePage(): JSX.Element | null {
  const currentPlantId = useAppSelector(selectCurrentPlantId);
  if (currentPlantId) {
    return <PageBody id={currentPlantId} />;
  } else {
    return null;
  }
}
export default DashboardHomePage;
