import { MS_IN_A_SECOND, SECONDS_IN_A_MINUTE } from "../util/time";

const PROD_API = "https://api.redearth.cloud/";
const DEV_API = "https://api.staging.redearth.cloud/";
const LOCAL_API = "http://localhost:3000/";

let tempApiUrl = DEV_API; //bias dev api for now
/*
  NODE_ENV is the build in Create-React-App variable for npm run test/start/build
  REACT_APP_ENV is our custom variable so we can change api for npm run start
*/
// console.log("process.env.NODE_ENV: " + process.env.NODE_ENV);
// console.log("process.env.REACT_APP_ENV: " + process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "production") {
  tempApiUrl = PROD_API;
} else if (process.env.REACT_APP_ENV === "test") {
  tempApiUrl = DEV_API;
} else if (process.env.REACT_APP_ENV === "local") {
  tempApiUrl = LOCAL_API;
} else if (process.env.REACT_APP_ENV === undefined) {
  tempApiUrl = PROD_API; // xcode doesn't inject variables so we need this for production build until we have a better solution
}
export const BASE_API_URL = tempApiUrl;

export const AllPlantDataTypes = [1, 2, 3, 4, 5];

// 1 minute in MS
export const SERVER_POLLING_INTERVAL = MS_IN_A_SECOND * SECONDS_IN_A_MINUTE * 1;
export const EMAIL_VERIFICATION_REFRESH_TIME = MS_IN_A_SECOND * 5;

export const POWER_DATA_DESCRIPTIONS = {
  "1": {
    "display_name": "Solar Power",
    "unit": "kw",
    "description": "Total Power being generated by the PV array."
  },
  "2": {
    "display_name": "Battery Power",
    "unit": "kw",
    "description": "The power being used from the battery. " +
      "A negative value means the battery is being charged."
  },
  "3": {
    "display_name": "Grid Power",
    "unit": "kw",
    "description": "The power being used from the grid. " +
      "A negative value means power is being fed back into the grid."
  },
  "4": {
    "display_name": "Battery Charge",
    "unit": "%",
    "description": "The percentage charge in the battery."
  },
  "5": {
    "display_name": "Load Power",
    "unit": "kw",
    "description": "The total power being consumed by loads."
  },
};