/* eslint-disable max-len */
import React from "react";
import { useNavigate } from "react-router-dom";
import { CardActionArea, Paper, Typography, Box} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "assets/jss/material-dashboard-react/components/customdevicecard";
import { FixMeLater } from "apps-middleware/types/general";
import { IUIFriendlyDataFormat } from "apps-middleware/types/redux";
import { usePlant } from "apps-middleware/hooks/usePlant";
import Loading from "components/Loading/Loading";
import { palette } from "apps-middleware/constants/palette";
import { FeatureIconRow } from "components/PlantFeatureIcons/iconRow";
import { customDeviceCardStyles } from "./CustomDeviceCardStyles";
import LastUpdatedFeature from "./LastUpdatedFeature";

export const DeviceCardManager = ({
  plantId
}: {
  plantId?: string
}) => {

  if (plantId) {
    return (
      <CustomDeviceCard plantId={plantId} />
    );
  } else {
    return <div style={customDeviceCardStyles.DeviceCardManager}>
      <Loading size={30} />
    </div>;
  }
};

export const CustomDeviceCard = React.memo(
  ({
    plantId,
  }: {
    plantId: string,
  }): JSX.Element | null => {
    const plant = usePlant({
      plantId,
      getFullInfoASAP: false,
    });
    const navigate = useNavigate();

    const useStyles = makeStyles(styles as FixMeLater);
    const classes = useStyles();
    const uiFriendlyPowerData = plant?.powerData.current?.uiFriendly;

    const selectCard = (e: React.MouseEvent) => {
      e.preventDefault();
      navigate("/device/" + plantId);
    };

    // default state
    const statusColour = {
      backgroundColor: palette.neutral.B1,
      color: palette.primary.white,
      border: palette.primary.white
    };
    const alertColour = {
      backgroundColor: palette.neutral.B1,
      color: palette.primary.white
    };

    if (plant && plant.status === "INSTALLED") {
      statusColour.backgroundColor = "#fff";
      statusColour.border = "3px solid #90EE90";
    } else {
      statusColour.backgroundColor = "rgba(211, 211, 211, 0.5)";
      statusColour.border = "2px solid rgba(0, 0, 0, 0.3)";
    }
    if (plant && plant.alerts) {
      if (plant.alerts.find((alert) => alert.priority === "High")) {
        alertColour.backgroundColor = "#EF4239";
      } else if (plant.alerts.find((alert) => alert.priority === "Medium")) {
        alertColour.backgroundColor = "#EF4239";
      } else {
        alertColour.backgroundColor = "";
      }
    }
    const isVip = plant?.isVip ?? false;
    const isAlert = plant?.highestAlertPriority === "Medium" ?? false;
    const isSevereAlert = plant?.highestAlertPriority === "High" ?? false;

    const lastTimeStamp = plant?.powerData?.current?.raw?.unixTimestamp || null;

    const borders = isSevereAlert ? "3px solid rgba(239, 66, 57, 1)" : isAlert
      ? "3px solid rgba(255, 149, 0, 1)"
      : isVip
        ? "3px solid rgba(255, 223, 0, 1)"
        : statusColour.border;

    const renderValue = (field: IUIFriendlyDataFormat): string =>
      (field.value !== undefined ? field.value : "--") + field.unit;

    return (
      <Paper style={{
        ...customDeviceCardStyles.DeviceCardContainer,
        border: borders,
        boxShadow: isVip ? "-3px 1px 22px 2px rgba(255,223,0,0.3)" : "0 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: alertColour.backgroundColor,
      }}>
        <div className={classes.card}>
          <CardActionArea onClick={selectCard} sx={customDeviceCardStyles.CardActionArea}>
            <Box style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-around"}}>
              <Typography variant="subtitle2"
                style={customDeviceCardStyles.PlantNameHeader}>
                {plant?.name ?? "---"}
              </Typography>

              <Typography variant="caption">
                {plant?.warrantyName ? `${plant?.warrantyName}` : "No Warranty Details"}
              </Typography>
            </Box>
            <Box style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center"}}>
              <FeatureIconRow plantId={plantId} iconColour={"rgba(0, 0, 0, 0.2)"} rowWidth={150} />
            </Box>

            <Box style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "flex-start",
              alignItems: "flex-start",
              width: "100%"
            }}>
              <Typography variant="caption"
                style={{
                  fontSize: "0.65em",
                }}>
                {plant?.chassisId ? `Chassis ID: ${plant?.chassisId}` : "Config not completed"}
              </Typography>
              <Typography variant="caption"
                style={{
                  fontSize: "0.65em",
                }}>
                <LastUpdatedFeature timestamp={lastTimeStamp}/>
              </Typography>
            </Box>

          </CardActionArea>
        </div >
      </Paper>
    );
  });

