import React from "react";
import { Grid, Card, CardContent } from "@mui/material";
import { SimDetails } from "./simDetails";

interface SimDetailsPageProps {
  simId: string | undefined;
}

const SimDetailsPage: React.FC<SimDetailsPageProps> = ({ simId }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {simId && <SimDetails simId={simId} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SimDetailsPage;
