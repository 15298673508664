/* eslint-disable max-len */
import {
  Grid,
  SvgIcon
} from "@mui/material";
import { usePlant } from "apps-middleware/hooks/usePlant";

export const FeatureIconRow = ({
  plantId,
  rowWidth,
  iconColour,
  activeIconColour = "#90EE90",
}: {
  plantId: string,
  rowWidth?: number,
  iconColour: string,
  activeIconColour?: string,
}) => {

  const plant = usePlant({
    plantId: plantId,
    getFullInfoASAP: false,
  });

  const optimusStatus = plant?.isOptimus ?? false;
  const tradingStatus = plant?.tradingAllowed ?? false;
  const smartEvStatus = plant?.hasSmartEvCharger ?? false;
  const smartSwitchboardStatus = plant?.hasLoadControl ?? false;
  const cryptoStatus = plant?.cryptoAllowed ?? false;

  const commonProps = {
    iconColour,
    activeIconColour
  };

  return (
    <Grid
      style={rowWidth ? { width: rowWidth } : undefined}
      container
      flexDirection="row"
      justifyContent={"space-between"}
      flexWrap={"nowrap"}>
      {/* Optimus */}
      <BottomIcon isOn={optimusStatus} title={"Optimus"} {...commonProps}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 37 36">
          <path fillRule="evenodd" d="M6.044 11.73c0 3.11 2.371 5.239 4.818 5.239.921 0 1.778-.276 2.521-.776 1.723-1.157 2.673-3.545 2.147-5.772-.569-2.409-2.575-3.93-4.668-3.93-2.447 0-4.818 2.127-4.818 5.239Zm4.818 11.239c2.163 0 4.178-.66 5.868-1.796-.671-9.443 1.395-9.885 4.64-12.13C20.21 4.133 15.945.49 10.861.49 4.888.49.044 5.522.044 11.73c0 6.207 4.844 11.239 10.818 11.239Z" clipRule="evenodd" />
          <path d="M29 15.34c-1.122-.324-2.701-.144-4.223 1.01v19.462h-5.07V15.209c0-.673.268-1.317.743-1.793 2.887-2.887 6.684-3.89 9.954-2.947 3.359.968 5.827 3.921 5.827 7.995 0 3.608-2.483 6.224-5.083 7.376a8.287 8.287 0 0 1-4.294.671v-5.027c.853.19 1.743.109 2.472-.267 1.217-.629 1.822-1.61 1.837-2.791.066-1.474-.954-2.738-2.162-3.086Z" />
        </svg>
      </BottomIcon>
      {/* Trading */}
      <BottomIcon isOn={tradingStatus} title="Energy Trading" {...commonProps}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 37 37">
          <path d="M18.623.122a17.853 17.853 0 0 0-9.959 3.039 18.008 18.008 0 0 0-6.602 8.091 18.126 18.126 0 0 0-1.02 10.417A18.062 18.062 0 0 0 5.948 30.9a17.895 17.895 0 0 0 9.178 4.934 17.83 17.83 0 0 0 10.357-1.026 17.948 17.948 0 0 0 8.045-6.64 18.103 18.103 0 0 0 3.022-10.017c0-4.781-1.89-9.367-5.25-12.748A17.874 17.874 0 0 0 18.622.123Zm1.393 24.848v2.937h-2.553v-3.015a9.724 9.724 0 0 1-4.642-2.042l1.335-2.666c1.392 1.226 3.481 2.043 4.893 2.043 1.083 0 1.76-.409 1.76-1.186 0-2.413-7.156-.973-7.156-5.798 0-2.081 1.47-3.52 3.81-3.968v-2.88h2.553v2.783c1.625.175 3.21.72 4.372 1.478L23.09 15.36c-1.431-.836-3.23-1.42-4.332-1.42-.89 0-1.49.33-1.49.992 0 2.354 7.157 1.012 7.157 5.933 0 2.413-1.876 3.813-4.41 4.105Z" />
        </svg>
      </BottomIcon>
      {/* Smart EV */}
      {/* TODO: add smart EV here when it exists */}
      <BottomIcon isOn={smartEvStatus} title="Smart EV" {...commonProps}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 35 37">
          <path d="M2.46 4.63A4.507 4.507 0 0 1 6.967.121H20.49a4.507 4.507 0 0 1 4.508 4.507v18.03a4.507 4.507 0 0 1 4.507 4.507v1.127a1.127 1.127 0 0 0 2.254 0v-7.888c0-.581-.235-.85-.805-1.43l-.015-.019c-.58-.588-1.434-1.458-1.434-3.058v-6.76a1.13 1.13 0 0 1 .33-.798l1.126-1.127a1.127 1.127 0 0 1 1.596 0l1.126 1.127a1.127 1.127 0 0 1 .33.798v19.156a3.38 3.38 0 0 1-6.762 0v-1.127a2.254 2.254 0 0 0-2.253-2.253v9.014h1.127a1.127 1.127 0 0 1 0 2.254H1.334a1.127 1.127 0 1 1 0-2.254H2.46V4.63Zm4.507 1.126v11.269a1.127 1.127 0 0 0 1.127 1.127h11.269a1.127 1.127 0 0 0 1.126-1.127V5.756a1.127 1.127 0 0 0-1.126-1.127H8.094a1.127 1.127 0 0 0-1.127 1.127Z" />
        </svg>
      </BottomIcon>
      {/* Smart Switchboard */}
      {/* TODO: add smart home here when it exists */}
      <BottomIcon isOn={smartSwitchboardStatus} title="Smart Switchboard" {...commonProps}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 25 36">
          <path d="M7.652 35.946v-5.932l-6.92-6.92V12.22c0-1.087.388-2.019 1.163-2.794.773-.773 1.704-1.16 2.791-1.16h1.977l-1.977 1.977V.357h3.955v7.909h7.908V.357h3.954v9.886l-1.977-1.977h1.977c1.088 0 2.019.387 2.794 1.16.774.775 1.16 1.707 1.16 2.794v10.874l-6.92 6.92v5.932H7.653Z" />
        </svg>
      </BottomIcon>
      {/* Crypto */}
      <BottomIcon isOn={cryptoStatus} title="Blockchain" {...commonProps}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36">
          <path fillRule="evenodd" d="M.286 18.151C.286 8.324 8.253.357 18.08.357c9.828 0 17.795 7.967 17.795 17.794 0 9.828-7.967 17.795-17.794 17.795-9.828 0-17.795-7.967-17.795-17.794Zm16.177-9.705a1.618 1.618 0 0 0-3.235 0v1.617H11.61a1.618 1.618 0 1 0 0 3.236h1.618v9.705H11.61a1.618 1.618 0 1 0 0 3.236h1.618v1.617a1.618 1.618 0 1 0 3.235 0V26.24h1.617v1.617a1.618 1.618 0 1 0 3.236 0V26.24c.797 0 1.959-.227 2.966-.951 1.094-.788 1.887-2.08 1.887-3.902 0-1.377-.455-2.453-1.149-3.236.694-.782 1.149-1.858 1.149-3.235 0-1.821-.795-3.115-1.887-3.902a5.23 5.23 0 0 0-2.966-.95V8.445a1.617 1.617 0 1 0-3.235 0v1.617h-1.618V8.446Zm0 8.088v-3.235h4.853c.28 0 .737.097 1.077.343.257.184.54.507.54 1.274s-.283 1.09-.54 1.276a2.02 2.02 0 0 1-1.077.342h-4.853Zm0 3.235h4.853c.28 0 .737.097 1.077.341.257.187.54.51.54 1.277 0 .767-.283 1.09-.54 1.276-.32.214-.693.332-1.077.341h-4.853V19.77Z" clipRule="evenodd" />
        </svg>
      </BottomIcon>
    </Grid>
  );

};

const BottomIcon = ({
  children,
  isOn = false,
  title,
  iconColour,
  activeIconColour
}: {
  children: JSX.Element,
  isOn?: boolean,
  title: string,
  iconColour: string,
  activeIconColour: string,
}) => (
  <Grid
    title={title + " " + (isOn ? "Enabled" : "Disabled")}
    item
    padding={0.1}
    xs={2}
  >
    <SvgIcon sx={{
      width: "100%",
      height: "100%",
      color: isOn ? activeIconColour : iconColour,
      opacity: 1,
      transform: isOn ? "scale(1.25)" : "scale(0.65)",
    }}>
      {children}
    </SvgIcon>
  </Grid>
);
