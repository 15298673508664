/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, IconButton} from "@mui/material";

import {
  LabelledTimeRange,
  TIME_RANGES
} from "apps-middleware/util/time";

import redearth_blinking from "../../assets/img/RedEarthLogoBlinking.svg";
import redearth_solid from "../../assets/img/RedEarthLogoRed.svg";
import styles from "./home.module.scss";
import {
  useAppDispatch,
  useAppSelector
} from "apps-middleware/redux/store/hooks";
import { IDateRange } from "apps-middleware/types/time";
import {
  cumulativeDailyEnvironmentalDataThunk,
  cumulativeDailyFinancialDataThunk,
  fetchAggregatePlantDataThunkAction,
  fetchPlantsThunk
} from "apps-middleware/redux/asyncThunks";
import {
  getYesterdaysCumulativeData
} from "apps-middleware/redux/selectors/powerData";

import { ITimeScaledGraphData } from "types/graph";
import { getAggregateGraphData } from "redux/graphing-selectors";

import { DateTime } from "luxon";
import { UNITS } from "apps-middleware/constants/general";
import { wattToMegaWatt } from "apps-middleware/util/general";

import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

function HomeTop(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const [totalPlantsCount, setTotalPlantsCount] = useState(Infinity);

  const graphData: ITimeScaledGraphData[] = useAppSelector(getAggregateGraphData);
  const isolatedSolarSparkData = graphData.map((dataPoint) => dataPoint.pvPower) as number[];

  const isolatedLoadSparkData = graphData.map((dataPoint) => dataPoint.loadPower) as number[];

  const isolatedTimestampsSparkData = graphData.map((dataPoint) => {
    const unixTimestamp = dataPoint.unixTimestamp;
    return unixTimestamp ? new Date(unixTimestamp) : new Date();
  }) as Date[];

  // const isGraph = useAppSelector(getHomePageStateIsGraph);

  const { yesterdayFinData, todaysEnviroData } = useAppSelector(getYesterdaysCumulativeData);

  const energyEarnings = yesterdayFinData ? yesterdayFinData.energyEarnings : 0;
  const estimatedCost = yesterdayFinData ? yesterdayFinData.estimatedCost : 0;
  const withoutPvCost = yesterdayFinData ? yesterdayFinData.withoutPvCost : 0;

  const financialsum = energyEarnings + (withoutPvCost - estimatedCost);

  const formattedFinancialSum = financialsum !== 0 ? "$" + financialsum.toFixed(2) : "--";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [plotBounds, setPlotBounds] = useState<LabelledTimeRange>(TIME_RANGES.TODAY);

  useEffect(function onDomainChange() {
    if (!plotBounds || !plotBounds.range) return;
    dispatch(fetchAggregatePlantDataThunkAction(plotBounds.range));
  }, [plotBounds.toMillisDifference]);

  useEffect(function onFirstRender() {
    let mounted = true;
    const now = DateTime.now();

    const range: IDateRange = {
      startDateTime: now.minus({ "days": 2 }).startOf("day"),
      endDateTime: now.endOf("day")
    };

    dispatch(cumulativeDailyFinancialDataThunk(range));
    dispatch(cumulativeDailyEnvironmentalDataThunk(range));

    // set resize handlers
    dispatch(fetchPlantsThunk({
      "page": 1,
      "limit": 1
    }))
      .unwrap()
      .then((res) => {

        if (mounted && res.pagination) {
          setTotalPlantsCount(res.pagination.totalAvailable);
        }
      });

    return () => {
      mounted = false;
    };

  }, []);
  return (
    <Grid container spacing={2} style={{ padding: 20 }}>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Paper className={styles.valueBox} style={{ height: 100 }}>
          <div className={styles.content}>
            <Typography variant="subtitle2">
              Fleet Size
            </Typography>
            <Typography variant="h5">
              {totalPlantsCount === Infinity
                ? "---"
                : totalPlantsCount.toString()}{" "}
              <span style={{ fontSize: "0.8rem" }}>
                {totalPlantsCount > 1 ? "Private Power Plants" : "Private Power Plant"}
              </span>
            </Typography>
          </div>
          <Box className={styles.icon}>
            <IconButton>
              <img
                src={totalPlantsCount === Infinity ? redearth_blinking : redearth_solid}
                alt="RedEarth logo"
                style={{ width: "45px", height: "45px" }}
              />
            </IconButton>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Paper className={styles.valueBox}>
          <div className={styles.content}>
            <Typography variant="subtitle2">
              Today's PV Production
            </Typography>
            <Typography variant="h5">{wattToMegaWatt(todaysEnviroData.pvWattHours) + UNITS.MEGAWATTHOURS}</Typography>
          </div>
          <Box className={styles.icon} style={{height: 80, width:"100%"}}>
            <SparkLineChart data={isolatedSolarSparkData} curve="natural"
              valueFormatter={(value: number | null) => (value === null ? "" : value + " kW")}
              showHighlight={true} showTooltip={true} colors={["orange"]}
              xAxis={{
                scaleType: "time",
                data: isolatedTimestampsSparkData,
                valueFormatter: (value) => {
                  let hours = value.getHours();
                  const minutes = value.getMinutes().toString()
                    .padStart(2, "0");
                  const amPm = hours >= 12 ? "PM" : "AM";
                  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
                  return `${hours}:${minutes} ${amPm}`;
                }
              }}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Paper className={styles.valueBox}>
          <div className={styles.content}>
            <Typography variant="subtitle2" >
              Today's Usage
            </Typography>
            <Typography variant="h5">{wattToMegaWatt(todaysEnviroData.activePowerUsageWattHours) + UNITS.MEGAWATTHOURS}</Typography>
          </div>
          <Box className={styles.icon} style={{height: 80, width:"100%"}}>
            <SparkLineChart data={isolatedLoadSparkData} curve="natural"
              valueFormatter={(value: number | null) => (value === null ? "" : value + " kW")}
              showHighlight={true} showTooltip={true} colors={["cyan"]}
              xAxis={{
                scaleType: "time",
                data: isolatedTimestampsSparkData,
                valueFormatter: (value) => {
                  let hours = value.getHours();
                  const minutes = value.getMinutes().toString()
                    .padStart(2, "0");
                  const amPm = hours >= 12 ? "PM" : "AM";
                  hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
                  return `${hours}:${minutes} ${amPm}`;
                }
              }} />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Paper className={styles.valueBox}>
          <div className={styles.content}>
            <Typography variant="subtitle2" >
              Financial Impact
            </Typography>
            <Typography variant="h5">{formattedFinancialSum}</Typography>
          </div>
          <Box className={styles.icon} style={{height: 80, width:"100%"}}>
            <SparkLineChart
              plotType="bar"
              data={[
                (yesterdayFinData.energyEarnings),
                ((yesterdayFinData.withoutPvCost - yesterdayFinData.estimatedCost))
              ]}
              xAxis={{
                scaleType: "band",
                data: ["Money Earned", "Money Saved"],
              }}
              showHighlight={true}
              showTooltip={true}
              colors={["lightgreen", "green"]}
              valueFormatter={(value: number | null) => (value === null ? "" : "$ "+value)}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default HomeTop;
