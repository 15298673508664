/* eslint-disable no-console */
import { BASE_API_URL } from "../constants/server";
import { getIdToken, signOut } from "../../auth";
import { ErrorBody, ICustomResponse, RequestMethod } from "../types/server";
import { getUser, User, reloadUser } from "../../auth/getUser";

/*
  One of the downfalls of fetch is it doesn't do the best response reporting.
  If a response fails on the server for any reason if you return the entire
  negative response you won't be able to break down the negative response
  entirely. Custom response is just a way to collect and report back all the
  available data about a response.
*/

export async function request(
  _url: string,
  method: RequestMethod,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any>,
  noTokenCall = false,
  query: Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<ICustomResponse<any> | null> {
  // Get the currently logged in user
  let url: string = _url;
  let user: User | null = null;
  let authToken: string | null = null;

  //if any query exists then prepare the URL
  if (Object.keys(query).length > 0) {
    url += "?";
    for (const key in query) {
      if (query[key]) {
        let value = query[key];
        if (typeof value === "string") {
          value = value.replace(/\s+/g, "+");
        }
        url += `${key}=${value}&`;
      }
    }
    url = url.substring(0, url.length - 1);
  }

  if (!noTokenCall) {
    user = getUser();
    if (user === null) {
      console.warn(`Failed Fetch for ${url}, user is not logged in!. Logging user out.`);
      signOut();
      return null;
    }

    url = url.replace("{userId}", `${user?.uid}`);

    // Get the auth token, firebase will refresh the token for us if required
    try {
      authToken = await getIdToken(false);
    } catch (error) {
      console.warn(`Token Refresh Failed for path: ${url}. Err: ${error}`);
    }

    if (!authToken) {
      console.warn("Token refresh failed. Attempting hard refresh.");
      //reload the user
      await reloadUser();
      //if the first token grab fails, retry with the force refresh prop
      authToken = await getIdToken(true);
      //if the force refresh fails. Then signout.
      if (!authToken) {
        console.warn(`No Auth Token, signing out. Failed on path: ${url}`);
        signOut();
        return null;
      }
    }
  }

  const makeRequest = (token: string | null) => {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (!noTokenCall) headers["Authorization"] = `Bearer ${token}`;

    return fetch(`${BASE_API_URL}${url}`, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : undefined
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prepareResponse = async (response: any) => {
    try {
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  let response = await makeRequest(authToken);
  let readResponse = await prepareResponse(response);

  if (response.status === 401) {
    /** Attempt to refresh user and remake call */
    const reloadResult = await reloadUser();

    if (reloadResult) {
      // on reload success, get a reloaded token
      const reloadedToken = await getIdToken(true);
      //make a repeat request
      response = await makeRequest(reloadedToken);
      readResponse = await prepareResponse(response);

      //if 401 again, then signout
      if (response.status === 401) {
        signOut();
      }
    } else {
      //on reload failure, signout
      signOut();
    }
  }

  if (!response.ok) {
    const error = readResponse as ErrorBody;
    console.log("");
    console.log(`Function: Failed Fetch (${method}) for ${BASE_API_URL}${url}`);
    console.log(`status: ${response.status}, statusText: ${response.statusText}`);
    console.log(`body: ${readResponse === null ? null : JSON.stringify(readResponse)}`);
    if (response.status === 400 && error) {
      console.log("400 Message: " + error.message);
    }
    console.log("");
  }

  return {
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
    data: response.ok ? readResponse : readResponse as ErrorBody,
  };
}