import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuList
} from "@mui/material";
import React from "react";

type SimpleDenseDropdownOption = string | {
  value: string, //unique
  name: string,
}

export default function SimpleDenseDropdown({
  label,
  options,
  onChange
}: {
  label: string,
  options: Array<SimpleDenseDropdownOption>,
  onChange: (newVal: string) => void
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEntry = (newVal: string) => {
    onChange(newVal);
    handleClose();
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList dense>
          {
            options.map((option, i) => {
              const semanticName = typeof option === "string" ? option : option["name"];
              const onClickArg = typeof option === "string" ? option : option["value"];
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleClickEntry(onClickArg)}>
                  <ListItemText>{semanticName}</ListItemText>
                </MenuItem>
              );
            })
          }
        </MenuList>
      </Menu>
    </div>
  );
}
