import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import {
  selectCurrentPlantChassisId,
  selectCurrentPlantSerialNumber,
  selectPlantConfigDetails
} from "apps-middleware/redux/selectors/plants";
import { useAppSelector } from "apps-middleware/redux/store/hooks";
import { useEffect, useRef, useState } from "react";

const StyledTableCell = styled(TableCell)({
  border: `solid 1.5px ${"white"}`,
  whiteSpace: "pre"
});
interface ConfigurationRow {
  title: string;
  value: string | undefined;
}

/**
 * Helper function to check if a string is included in any of the items
 * of the provided list and return its index
 * @param v the value to search for
 * @param l the list to check the contents of
 * @returns if the index of the value if it is included in any item of the list
 */
function listContainsLike(v: string, l: string[]): number | undefined {
  for (let idx = 0; idx < l.length; idx++) {
    const item: string = l[idx];
    if (v.toLowerCase().includes(item.toLowerCase())) {
      return idx;
    }
  }
}

/**
 * Sorts the table according to more and less important items, which are then
 * index sorted within the importance groupings.
 *    HIGH IMPORTANCE (sorted by index)
 *    UNSPECIFIED IMPORTANCE (sorted alphabetically)
 *    LOW IMPORTANCE (sorted by index)
 * @param a The first configuration row to compare against
 * @param b The second configuration row to compare against
 * @param itemsAtStart Items of high importance which should be near the top
 * @param itemsAtEnd Items of low importance which should be near the bottom
 * @returns a number [-1|0|1] donating the ordering (lower is more important)
 */
function sortConfig(
  a: ConfigurationRow,
  b: ConfigurationRow,
  itemsAtStart: string[],
  itemsAtEnd: string[]
): number {
  const aStartIdx: number | undefined = listContainsLike(a.title, itemsAtStart);
  const bStartIdx: number | undefined = listContainsLike(b.title, itemsAtStart);
  const aEndIdx: number | undefined = listContainsLike(a.title, itemsAtEnd);
  const bEndIdx: number | undefined = listContainsLike(b.title, itemsAtEnd);

  const aAtStart: boolean = aStartIdx !== undefined;
  const bAtStart: boolean = bStartIdx !== undefined;
  const aAtEnd: boolean = aEndIdx !== undefined;
  const bAtEnd: boolean = bEndIdx !== undefined;

  // If either item is only in the start or end list we immediately know the
  // ordering to return
  if (aAtStart && !bAtStart) {
    return -1;
  } else if (bAtStart && !aAtStart) {
    return 1;
  } else if (aAtEnd && !bAtEnd) {
    return 1;
  } else if (bAtEnd && !aAtEnd) {
    return -1;
  }

  // If both items are in either start or end list, we return an ordering
  // by index, where lower index comes first
  if (aStartIdx && bStartIdx) {
    return aStartIdx < bStartIdx
      ? -1
      : aStartIdx > bStartIdx
        ? 1
        : 0;
  } else if (aEndIdx && bEndIdx) {
    return aEndIdx < bEndIdx
      ? -1
      : aEndIdx > bEndIdx
        ? 1
        : 0;
  }

  // Otherwise want to just order alphabetically
  return a.title.localeCompare(b.title);
}

export const PlantConfig = (): JSX.Element => {
  const completeConfiguration: ConfigurationRow[] = [];

  const configurationJSON = useAppSelector(selectPlantConfigDetails);
  const chassisId = useAppSelector(selectCurrentPlantChassisId);
  const plantSerialNumber = useAppSelector(selectCurrentPlantSerialNumber);

  completeConfiguration.push({ title: "Serial Number", value: plantSerialNumber });
  completeConfiguration.push({ title: "Chassis Id", value: chassisId?.toString() });
  if (configurationJSON !== undefined) {
    const configItems: ConfigurationRow[] = Object
      .entries(configurationJSON)
      .map(([k, v]) => ({ title: k, value: configurationToString(v, 0) }));

    // NOTE: The ordering of these items will be reflected in the table
    const itemsAtStart: string[] = ["phases", "inverters"];
    const itemsAtEnd: string[] = ["batteries"];
    configItems
      .sort((a, b) => sortConfig(a, b, itemsAtStart, itemsAtEnd))
      .forEach((ci) => completeConfiguration.push(ci));
  }

  const [configuration, setConfiguration] = useState<ConfigurationRow[]>(completeConfiguration);

  return (
    <Grid container item xs={10}
      gap={2}
      justifySelf="center"
      alignSelf={"center"}
      flexDirection="column"
      justifyContent="center">
      <Typography variant="h5" align="center" fontStyle={{ lineHeight: "unset" }}>
        Configuration Details
      </Typography>
      <PlantConfigSearch
        configuration={completeConfiguration}
        setFilteredConfiguration={setConfiguration}
      />
      <PlantConfigTable configuration={configuration} />
    </Grid>
  );
};

const PlantConfigTable = (props: { configuration: ConfigurationRow[] }): JSX.Element => {
  const ConfigRow = ({ title, value }: {
    title: string,
    value: string | undefined
  }) => {
    const stringToCell = (s: string, variant: Variant): JSX.Element =>
      <Typography variant={variant}>{s}</Typography>;

    const resolveCellValue = (v: string | undefined, variant: Variant): JSX.Element =>
      v === undefined
        ? stringToCell("No Data", variant)
        : typeof v === "string"
          ? stringToCell(v, variant)
          : v;

    return (
      <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
        <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
          {resolveCellValue(
            title
              .toString()
              .toUpperCase()
              .replaceAll("_", " "),
            "h6")
          }
        </StyledTableCell>
        <StyledTableCell style={{ width: "80%" }}>
          {resolveCellValue(
            value,
            "body1"
          )}
        </StyledTableCell>
      </TableRow>
    );
  };

  // Refs are used here to get the initial height on the first render.
  // This height is stored to set the height of the table to, so that
  // on filtering later we don't move the viewport around a bunch
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | undefined>();
  useEffect(() => {
    if (ref?.current?.clientHeight !== undefined && height === undefined) {
      setHeight(ref?.current?.clientHeight);
    }
  }, [ref.current]);

  return (
    <div ref={ref} style={{ height: height }}>
      <Table>
        <TableBody>
          {
            props.configuration.map((config, i) =>
              <ConfigRow title={config.title} value={config.value} key={i} />
            )
          }
        </TableBody>
      </Table>
    </div>
  );
};

interface IPlantConfigSearchProps {
  configuration: ConfigurationRow[],
  setFilteredConfiguration: (v: ConfigurationRow[]) => void
}

const PlantConfigSearch = (props: IPlantConfigSearchProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>("");

  return (
    <TextField
      label="Search Configuration"
      variant="outlined"
      size="small"
      key={"searchconfiguniquekey"}
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchText: string = event.target.value;
        setSearchText(newSearchText);
        props.setFilteredConfiguration(props.configuration.filter(
          (config) => config.title.toLowerCase().includes(newSearchText.toLowerCase()) ||
            (config.value !== undefined &&
              config.value.toLowerCase().includes(newSearchText.toLowerCase()))
        ));
        // TODO Filter results
      }}
    />
  );
};

type valueTypes = "primitive" | "array" | "object" | "null";
const improvedTypeOf = (val: unknown): valueTypes => {
  if (val === null || val === undefined) return "null";
  else if (Array.isArray(val)) return "array";
  else if (typeof val === "object") return "object";
  else return "primitive";
};

/**
 * Turns the provided configuration value into a more human friendly output
 * @param v the value of the configuration component
 * @param depth the depth to output at (used for formatting)
 * @returns a human readable output value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function configurationToString(v: any, depth: number): string | undefined {
  const newLine: string = "\n" + "\t".repeat(depth);
  switch (improvedTypeOf(v)) {
    case "primitive":
      return v.toString();
    case "array":
      return v.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (v2: any, idx: number) => (idx + 1).toString() + ": " + configurationToString(v2, depth + 1)
      ).join("\n" + newLine);
    case "object":
      return "\t".repeat(depth) +
        Object
          .entries(v)
          .map(([k, v2]) => k + ": " + configurationToString(v2, depth + 1))
          .join(newLine);
    default:
      return undefined;
  }
}