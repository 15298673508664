import {
  PlantId
} from "../../types/plant";

import {
  fetchPriceDataThunkAction,
  environmentalDataThunk,
  financialDataThunk,
  fetchPowerDataThunkAction,
  fetchAggregatePlantDataThunkAction,
  fetchPlantsThunk,
  updatePlantThunk,
  cumulativeDailyFinancialDataThunk,
  cumulativeDailyEnvironmentalDataThunk,
  getAlertListThunk,
  getNotificationsThunk,
  notificationPermissionsThunk,
  getUserPersonalDetailsThunk,
  getChallengesForPlantThunk,
  getSpecificChallengeThunk,
  getTradingHistoryThunk,
  getFirebaseUserClaimsThunkAction,
  getProductsThunk,
  getTradingStatusThunk,
  getAccessoryThunk,
  getAccessoryStatusThunk,
  getSimInfoThunk,
  getSimDataThunk,
  sendSimCommandThunk,
  fetchSchedulesThunk
} from "../../redux/asyncThunks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  makeAsyncDataObject, defaultPowerDataValue,
  defaultPriceDataValue
} from "../../util/general";
import {
  ThunkState,
  IUserSettings,
  UIFlagName,
  AggregateData,
  PowerIntervalType
} from "../../types/redux";
import {
  defaultSettings,
  generateDefaultCachedDataObjectForPlant,
  initialState
} from "../../constants/redux";
import { UTC_DATE_FORMAT } from "../../util/time";
import { CustomAppState } from "../../types/general";
import { DateTime } from "luxon";
import {
  isPlantData,
  isPlantThunkResponse,
  isPlantInfo,
  isPaginationResponse,
  isPriceV2Data,
  isNotificationPermissions,
  isTradingHistory,
  isFirebaseClaims,
  isProduct,
  isPlantAccessory,
  isServerPlantAccessoryStatus,
  isFinancialData,
  isEnvironmentalData,
  isSchedulePlantCommandEvent
} from "../../util/type";
import {
  CachedDataIntervals
} from "../../types/server";

export const appDataSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    updateSettingsLastRoute: (state, action: PayloadAction<string>) => {
      if (state.settings) state.settings.lastRoute = action.payload;
    },
    updateSettingsLastPlantId: (state, action) => {
      if (state.settings) state.settings.lastPlantID = action.payload;
    },
    updateSettingsShowDemoPlants: (state, action: PayloadAction<boolean>) => {
      if (state.settings) state.settings.showDemoPlants = action.payload;
    },
    setAppState: (state, action: PayloadAction<CustomAppState>) => {
      state.appState = action.payload;
    },
    setCurrentPlant: (state, action: PayloadAction<string | undefined>) => { // TODO: Fix typo
      state.currentPlantId = action.payload;
    },
    emptyAllCurrentPlantData: (state) => {
      state.powerData = defaultPowerDataValue();
      state.priceData = defaultPriceDataValue();
      state.financialData = makeAsyncDataObject({});
      state.environmentalData = makeAsyncDataObject({});
    },
    resetLocalState: () => initialState,
    forceDataRefresh: (state) => {
      state.forceDataRefresh++;
    },
    setUIFlag: (state, action: PayloadAction<[UIFlagName, boolean]>) => {
      const [flagName, newState] = action.payload;
      state.uiFlags[flagName] = newState;
    },
    addNotificationToDismissed: (state, action: PayloadAction<number>) => {
      if (!state.settings) return;
      if (!state.settings.dismissedNotifications.includes(action.payload)) {
        state.settings.dismissedNotifications.push(action.payload);
      }
    },
    setSettings: (state, action: PayloadAction<IUserSettings | null>) => {
      state.settings = !action.payload ? defaultSettings : action.payload;
    },
    /**
     *
     * Based on the current state, considering the plants, demo plants and possible plants
     * provide a best match for what should be the currentPlantId and if the
     * currentTariffSetupPlantId should be set:
     *
     * priority:
     *
     * 1. if there are plants, select the plant id that matches the most recent
     * plant. Else pick the first plant in the list.
     *
     * @param state Root State
     */
    determineInitialCurrentPlantId: (state) => {
      const plants = state.plants.data;
      const demoPlants = state.demoPlants.data;
      const possiblePlants = state.possiblePlants.data;
      const lastIdSelected = (state.settings && state.settings.lastPlantID)
        ? state.settings.lastPlantID : undefined;

      if (Object.values(plants).length > 0) {
        state.currentPlantId = (
          lastIdSelected &&
          plants[lastIdSelected] !== undefined
        ) ? lastIdSelected : Object.values(plants)[0].plantId;
      } else if (Object.values(possiblePlants).length > 0) {
        state.currentPlantId = undefined;
      } else if (Object.values(demoPlants).length > 0) {
        state.currentPlantId = (
          lastIdSelected &&
          demoPlants[lastIdSelected] !== undefined
        ) ? lastIdSelected : Object.values(demoPlants)[0].plantId;
      } else {
        state.currentPlantId = undefined;
      }
    },
  }
  ,
  extraReducers: (builder) => {
    // current price data thunk
    builder.addCase(fetchPriceDataThunkAction.fulfilled,
      function priceThunkFulfilled(state, action) {
        state.priceData.epochOfLastFetched = DateTime.now().toMillis();
        state.priceData.fetchState = ThunkState.done;

        const plantId: PlantId | undefined = action.meta.arg !== undefined
          ? action.meta.arg.plantId : state.currentPlantId;

        if (
          !action.payload ||
          !Array.isArray(action.payload) ||
          action.payload.length === 0 ||
          !plantId
        ) return;

        if (!(plantId in state.priceData.data)) {
          state.priceData.data[plantId] = {
            5: {},
            30: {},
          };
        }

        action.payload.forEach((data) => {
          if (
            !data ||
            !isPriceV2Data(data) ||
            typeof data.unixTimestamp !== "number" ||
            typeof data.resolution !== "number"
          ) return;
          state.priceData.data[plantId][data.resolution][data.unixTimestamp] = data;
        });
      }
    );
    builder.addCase(fetchPriceDataThunkAction.pending,
      function priceThunkPending(state) {
        state.priceData.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(fetchPriceDataThunkAction.rejected,
      function priceThunkReject(state) {
        state.priceData.fetchState = ThunkState.done;
        state.priceData.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    // /////////////////////////////////
    // Current Power Data Thunk
    builder.addCase(fetchPowerDataThunkAction.fulfilled,
      function powerThunkFulfilled(state, action) {
        state.powerData.fetchState = ThunkState.done;
        state.powerData.epochOfLastFetched = DateTime.now().toMillis();

        if (!action.payload || Object.keys(action.payload).length === 0) return;

        Object.entries(action.payload).forEach(([plantId, plantData]) => {
          //do not go forward if there is no plantId

          if (!plantId || !Array.isArray(plantData) || plantData.length === 0) return;

          const interval: PowerIntervalType =
            (action.meta.arg !== undefined ? action.meta.arg.minuteInterval : 5);

          // Add keys as required
          if (!(plantId in state.powerData.data)) {
            state.powerData.data[plantId] = {
              5: {},
              15: {},
              30: {},
              60: {}
            };
          }

          const newData = {
            ...state.powerData.data[plantId][interval]
          };

          plantData.forEach((row) => {
            //do not go forward if timestamp does not exist
            if (
              row.unixTimestamp === null ||
              !isPlantData(row) ||
              row.unixTimestamp > DateTime.now().toMillis()
            ) return;
            newData[row.unixTimestamp] = row;
          });

          state.powerData.data[plantId][interval] = newData;
        });
      });
    builder.addCase(fetchPowerDataThunkAction.pending, function powerThunkPending(state) {
      state.powerData.fetchState = ThunkState.pending;
    });

    builder.addCase(fetchPowerDataThunkAction.rejected, function powerThunkReject(state) {
      state.powerData.epochOfLastFetched = DateTime.now().toMillis();
      state.powerData.fetchState = ThunkState.done;
    });
    // /////////////////////////////

    // Fetch Financial Data thunk
    builder.addCase(
      financialDataThunk.fulfilled,
      function financialDataThunkFulfilled(state, action) {
        state.financialData.fetchState = ThunkState.done;
        state.financialData.epochOfLastFetched = DateTime.now().toMillis();

        const { arg } = action.meta;

        const id: string | undefined = arg?.plantId ?? state.currentPlantId;
        if (!id) return;

        const interval = action.payload.interval;

        const noDataForPlantYet = state.financialData.data[id] === undefined;
        //create the data for the plant if it has none. We make sure we provide a
        //stringify/parsed version of the default object or else javascript will
        // yell at us because we pointed to an object that will not be empty after the
        // first setting. Dont get bitten by js.
        if (noDataForPlantYet) {
          state.financialData.data[id] = generateDefaultCachedDataObjectForPlant();
        }
        const targetData = state.financialData.data[id][interval];

        Object.entries(action.payload.data)
          .forEach(
            ([key, val]) => {
              if (!isFinancialData(val)) return;
              targetData[key] = val;
            }
          );
      }
    );
    builder.addCase(financialDataThunk.pending,
      function financialDataThunkPending(state) {
        state.financialData.fetchState = ThunkState.pending;
      });
    builder.addCase(financialDataThunk.rejected,
      function financialDataThunkRejected(state) {
        state.financialData.fetchState = ThunkState.done;
        state.financialData.epochOfLastFetched = DateTime.now().toMillis();
      });
    // //////////////////////////
    builder.addCase(
      cumulativeDailyFinancialDataThunk.fulfilled,
      function cumulativeDailyFinancialDataFulfilled(state, action) {
        state.cumulativeFinancialData.fetchState = ThunkState.done;
        state.cumulativeFinancialData.epochOfLastFetched = DateTime.now().toMillis();
        if (
          action.payload === null ||
          !Array.isArray(action.payload) ||
          (Array.isArray(action.payload) && action.payload.length === 0)
        ) return;

        action.payload.forEach((dailyData) => {
          const dateKey = DateTime.fromMillis(dailyData.unixTimestamp).toFormat(UTC_DATE_FORMAT);
          state.cumulativeFinancialData.data[dateKey] = dailyData;
        });
      }
    );
    builder.addCase(
      cumulativeDailyFinancialDataThunk.pending,
      function cumulativeDailyFinancialDataPending(state) {
        state.cumulativeFinancialData.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      cumulativeDailyFinancialDataThunk.rejected,
      function cumulativeDailyFinancialDataRejected(state) {
        state.cumulativeFinancialData.fetchState = ThunkState.done;
        state.cumulativeFinancialData.epochOfLastFetched = DateTime.now().toMillis();
      }
    );

    // //////////////////////////
    // Fetch todays Environmental Data thunk
    builder.addCase(
      environmentalDataThunk.fulfilled,
      function environmentalDataThunkFulfilled(state, action) {
        state.environmentalData.fetchState = ThunkState.done;
        state.environmentalData.epochOfLastFetched = DateTime.now().toMillis();

        const { arg } = action.meta;

        const id: string | undefined = arg?.plantId ?? state.currentPlantId;
        if (!id) return;

        const interval: CachedDataIntervals = action.payload.interval;

        const noDataForPlantYet = state.environmentalData.data[id] === undefined;

        //create the data for the plant if it has none
        if (noDataForPlantYet) {
          state.environmentalData.data[id] = generateDefaultCachedDataObjectForPlant();
        }
        const targetData = state.environmentalData.data[id][interval];

        Object.entries(action.payload.data)
          .forEach(
            ([key, val]) => {
              if (!isEnvironmentalData(val)) return;
              targetData[key] = val;
            }
          );
      }
    );
    builder.addCase(environmentalDataThunk.pending,
      function environmentalDataThunkPending(state) {
        state.environmentalData.fetchState = ThunkState.pending;
      });
    builder.addCase(environmentalDataThunk.rejected,
      function environmentalDataThunkRejected(state) {
        state.environmentalData.fetchState = ThunkState.done;
        state.environmentalData.epochOfLastFetched = DateTime.now().toMillis();
      });
    // /////////////////////////////////////
    // cumulative environmental data
    builder.addCase(
      cumulativeDailyEnvironmentalDataThunk.fulfilled,
      function cumulativeEnvironmentalDataFulfilled(state, action) {
        state.cumulativeEnvironmentalData.fetchState = ThunkState.done;
        state.cumulativeEnvironmentalData.epochOfLastFetched = DateTime.now().toMillis();
        if (
          action.payload === null ||
          !Array.isArray(action.payload) ||
          (Array.isArray(action.payload) && action.payload.length === 0)
        ) return;

        action.payload.forEach((dailyData) => {
          const dateKey = DateTime.fromMillis(dailyData.unixTimestamp).toFormat(UTC_DATE_FORMAT);
          state.cumulativeEnvironmentalData.data[dateKey] = dailyData;
        });
      }
    );
    builder.addCase(
      cumulativeDailyEnvironmentalDataThunk.pending,
      function cumulativeEnvironmentalDataPending(state) {
        state.cumulativeEnvironmentalData.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      cumulativeDailyEnvironmentalDataThunk.rejected,
      function cumulativeEnvironmentalDataRejected(state) {
        state.cumulativeEnvironmentalData.fetchState = ThunkState.done;
        state.cumulativeEnvironmentalData.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    // /////////////////////////////////////////
    builder.addCase(fetchAggregatePlantDataThunkAction.fulfilled,
      function aggregateThunkFulfilled(state, action) {
        state.aggregatePlantData.fetchState = ThunkState.done;
        state.aggregatePlantData.epochOfLastFetched = DateTime.now().toMillis();

        if (!action.payload ||
          typeof action.payload !== "object" ||
          !Array.isArray(action.payload.points) ||
          action.payload.points.length === 0) return;

        const newState: AggregateData = {
          ...state.aggregatePlantData.data
        };

        const now = DateTime.now().toMillis();

        action.payload.points.forEach((row) => {
          if (!row.unixTimestamp || row.unixTimestamp > now) return;
          newState[row.unixTimestamp] = row;
        });

        state.aggregatePlantData.data = newState;
      });
    builder.addCase(fetchAggregatePlantDataThunkAction.pending,
      function aggregateThunkPending(state) {
        state.aggregatePlantData.fetchState = ThunkState.pending;
      });
    builder.addCase(fetchAggregatePlantDataThunkAction.rejected,
      function aggregateThunkRejected(state) {
        state.aggregatePlantData.fetchState = ThunkState.done;
        state.aggregatePlantData.epochOfLastFetched = DateTime.now().toMillis();
      });
    // //////////////////////////////////////
    builder.addCase(fetchPlantsThunk.fulfilled,
      function getPlantsFulfilled(state, action) {

        //set a bunch of new meta data for the async object
        const newMetaData = {
          epochOfLastFetched: DateTime.now().toMillis(),
          fetchState: ThunkState.done,
          numberOfConsecutiveFailedRequests: 0,
        };
        state.plants = { ...state.plants, ...newMetaData };
        state.possiblePlants = { ...state.possiblePlants, ...newMetaData };
        state.demoPlants = { ...state.demoPlants, ...newMetaData };

        //first check that the payload and contents are fine.
        if (!action.payload) return;
        const { data } = action.payload;
        if (
          !data ||
          !isPlantThunkResponse(data)
        ) return;

        data.plants.forEach((p) => state.plants.data[p.plantId] = {
          // we dont want the minified version completely replacing the full plant
          // info so just merge the two objects
          ...state.plants.data[p.plantId],
          ...p,
        });

        // we always refresh the possible plants and demo plants from each pull
        // as those plants may have been moved into the real plant list.
        if (data.possiblePlant) {
          state.possiblePlants.data = {};
          data.possiblePlant.forEach((p) => state.possiblePlants.data[p.plantId] = p);
        }
        if (data.demoPlants) {
          state.demoPlants.data = {};
          data.demoPlants.forEach((p) => state.demoPlants.data[p.plantId] = p);
        }

        // deal with a scenario where new plant list no longer contains a current plantId
        if (
          state.currentPlantId &&
          state.plants.data &&
          state.plants.data[state.currentPlantId] === undefined
        ) {
          state.currentPlantId = undefined;
        }
      });
    builder.addCase(fetchPlantsThunk.pending, function getPlantsPending(state) {
      state.plants.fetchState = ThunkState.pending;
    });
    builder.addCase(fetchPlantsThunk.rejected, function getPlantsRejected(state) {
      state.plants.epochOfLastFetched = DateTime.now().toMillis();
      state.plants.fetchState = ThunkState.done;

      state.plants.numberOfConsecutiveFailedRequests
        ? state.plants.numberOfConsecutiveFailedRequests++
        : state.plants.numberOfConsecutiveFailedRequests = 1;

      state.possiblePlants.numberOfConsecutiveFailedRequests
        ? state.possiblePlants.numberOfConsecutiveFailedRequests++
        : state.possiblePlants.numberOfConsecutiveFailedRequests = 1;
    });
    // ///////////////////////////////////////////
    builder.addCase(updatePlantThunk.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.plantId || !isPlantInfo(action.payload.data)) return;

      state.lastCurrentPlantUpdateEpoch = DateTime.now().toMillis();

      // we need to check if this is a demo plant and whether to update the demo
      // store or the real plant store.
      const targetStore = action.payload.data.accessType === "demo"
        ? state.demoPlants : state.plants;

      if (state.plants.data === undefined) return;

      // We're doing a complete replace here rather than a copy over
      // of old properties because the new object may no longer have
      // properties associated with the plant. If we copy and replace
      // the old ones it will be stale data. Instead just do a complete
      // replace.
      targetStore.data[action.payload.plantId] = action.payload.data;
    });
    // /////////////////////////////////////////////////
    builder.addCase(
      getAlertListThunk.fulfilled,
      function alertListThunkFulfilled(state, action) {
        state.alertList.epochOfLastFetched = DateTime.now().toMillis();
        state.alertList.fetchState = ThunkState.done;
        if (
          !action.payload ||
          !Array.isArray(action.payload.plantIds) ||
          !isPaginationResponse(action.payload.pagination)
        ) return;

        const { plantIds, pagination } = action.payload;
        const bottomIndex = (pagination.numberPerPage * pagination.currentPage) -
          pagination.numberPerPage;

        if (state.alertList.data.length !== pagination.totalAvailable) {
          state.alertList.data = Array(pagination.totalAvailable);
        }

        plantIds.forEach((id, index) => {
          state.alertList.data[bottomIndex + index] = id;
        });
      }
    );
    builder.addCase(
      getAlertListThunk.pending,
      function getAlertListThunkPending(state) {
        state.alertList.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getAlertListThunk.rejected,
      function getAlertListThunkRejected(state) {
        state.alertList.fetchState = ThunkState.done;
        state.alertList.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    /////////////////////////////////////////////////
    builder.addCase(getNotificationsThunk.fulfilled,
      function getNotificationsThunkFulfilled(state, action) {
        state.notifications.fetchState = ThunkState.done;
        state.notifications.epochOfLastFetched = DateTime.now().toMillis();
        if (
          !action.payload ||
          !Array.isArray(action.payload)
        ) return;

        state.notifications.data = action.payload;
      }
    );
    builder.addCase(getNotificationsThunk.pending,
      function getNotificationsThunkPending(state) {
        state.notifications.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(getNotificationsThunk.rejected,
      function getNotificationsThunkRejected(state) {
        state.notifications.fetchState = ThunkState.done;
        state.notifications.epochOfLastFetched = DateTime.now().toMillis();
      }
    );

    /////////////////////////////////////////////////
    builder.addCase(
      notificationPermissionsThunk.fulfilled,
      function notificationPermissionsThunkFulfilled(state, action) {
        state.notificationPermissions.epochOfLastFetched = DateTime.now().toMillis();
        state.notificationPermissions.fetchState = ThunkState.done;
        if (
          !action.payload ||
          typeof action.payload !== "object" ||
          !isNotificationPermissions(action.payload)
        ) return;
        state.notificationPermissions.data = action.payload;
      }
    );
    builder.addCase(
      notificationPermissionsThunk.pending,
      function notificationPermissionsThunkPending(state) {
        state.notificationPermissions.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      notificationPermissionsThunk.rejected,
      function notificationPermissionsThunkRejected(state) {
        state.notificationPermissions.fetchState = ThunkState.done;
        state.notificationPermissions.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    //////////////////////
    builder.addCase(
      getUserPersonalDetailsThunk.fulfilled,
      function getUserPersonalDetailsThunkFulfilled(state, action) {
        state.userPersonalDetails.fetchState = ThunkState.done;
        state.userPersonalDetails.epochOfLastFetched = DateTime.now().toMillis();
        if (
          !action.payload ||
          typeof action.payload !== "object"
        ) return;
        state.userPersonalDetails.data = action.payload;
      }
    );
    builder.addCase(
      getUserPersonalDetailsThunk.pending,
      function getUserPersonalDetailsThunkPending(state) {
        state.userPersonalDetails.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getUserPersonalDetailsThunk.rejected,
      function getUserPersonalDetailsThunkRejected(state) {
        state.userPersonalDetails.fetchState = ThunkState.done;
        state.userPersonalDetails.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    // //////////////////////////////////
    builder.addCase(
      getChallengesForPlantThunk.fulfilled,
      function getAllChallengesForPlantThunkFulfilled(state, action) {

        state.challenges.fetchState = ThunkState.done;
        state.challenges.epochOfLastFetched = DateTime.now().toMillis();

        const subjectPlantId: PlantId | undefined = action.meta.arg !== undefined
          ? action.meta.arg.plantId : state.currentPlantId;

        //defend against any unsavory responses
        if (
          !Array.isArray(action.payload) ||
          subjectPlantId === undefined
        ) return;

        //if this plant hasn't been recorded yet then create a record for it
        if (state.challenges.data[subjectPlantId] === undefined) {
          state.challenges.data[subjectPlantId] = {};
        }

        //loop through the aquired challenges and set them to our plantID record
        for (let i = 0; i < action.payload.length; i++) {
          const challenge = action.payload[i];
          state.challenges.data[subjectPlantId][challenge.id] = challenge;
        }
      }
    );
    builder.addCase(
      getChallengesForPlantThunk.pending,
      function getChallengesForPlantThunkRejected(state) {
        state.challenges.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getChallengesForPlantThunk.rejected,
      function getChallengesForPlantThunkRejected(state) {
        state.challenges.fetchState = ThunkState.done;
        state.challenges.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    // ////////////////////////////////////////
    builder.addCase(
      getSpecificChallengeThunk.fulfilled,
      function getSpecificChallengeThunkFulfilled(state, action) {
        const targetChallengeId = action.meta.arg.challengeId;
        const targetPlantId = action.meta.arg.plantId
          ? action.meta.arg.plantId : state.currentPlantId;

        const newChallenge = action.payload.find((c) => c.id === targetChallengeId);

        if (newChallenge !== undefined && targetPlantId !== undefined) {
          state.challenges.data[targetPlantId][targetChallengeId] = newChallenge;
        }
      }
    );
    // //////////////////////////////////////////
    builder.addCase(
      getTradingHistoryThunk.fulfilled,
      function getTradingHistoryThunkFulfilled(state, action) {
        state.tradingHistory.fetchState = ThunkState.done;
        state.tradingHistory.epochOfLastFetched = DateTime.now().toMillis();

        Object.entries(action.payload).forEach(([id, history]) => {
          // if this plant hasn't been given a history yet. Create a record for it
          if (state.tradingHistory.data[id] === undefined) state.tradingHistory.data[id] = {};
          history.forEach((entry) => {
            if (!isTradingHistory(entry)) {
              return;
            }
            state.tradingHistory.data[id][entry.timestamp] = entry;
          });

        });
      }
    );
    builder.addCase(
      getTradingHistoryThunk.pending,
      function getTradingHistoryThunkFulfilled(state) {
        state.tradingHistory.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getTradingHistoryThunk.rejected,
      function getTradingHistoryThunkRejected(state) {
        state.tradingHistory.fetchState = ThunkState.done;
        state.tradingHistory.epochOfLastFetched = DateTime.now().toMillis();
      }
    );
    // //////////////////////////////////////////
    builder.addCase(
      getFirebaseUserClaimsThunkAction.fulfilled,
      function getFirebaseClaimsThunkPending(state, action) {
        if (
          action.payload === null ||
          !isFirebaseClaims(action.payload)
        ) return;
        state.firebaseClaims = action.payload;
      }
    );
    // //////////////////////////////////////////

    builder.addCase(
      getProductsThunk.fulfilled,
      function getProductsFulfilled(state, action) {
        state.products.fetchState = ThunkState.done;
        state.products.epochOfLastFetched = DateTime.now().toMillis();

        if (
          action.payload === null ||
          !Array.isArray(action.payload)
        ) return;

        action.payload.forEach((product) => {
          if (!isProduct(product)) return;
          state.products.data[product.id] = product;
        });
      });
    builder.addCase(
      getProductsThunk.pending,
      (state) => {
        state.products.fetchState = ThunkState.pending;
      });
    builder.addCase(
      getProductsThunk.rejected,
      (state) => {
        state.products.fetchState = ThunkState.done;
        state.products.epochOfLastFetched = DateTime.now().toMillis();
      });
    //////////////////////////////////////////////////
    builder.addCase(getTradingStatusThunk.fulfilled, (state, action) => {
      state.tradingStatus.epochOfLastFetched = DateTime.now().toMillis();
      state.tradingStatus.fetchState = ThunkState.done;

      state.tradingStatus.data[action.payload.viewId] = {
        "freshness": DateTime.now().toMillis(),
        "plantTradingStatus": action.payload.data
      };
    });
    builder.addCase(getTradingStatusThunk.pending, (state) => {
      state.tradingStatus.fetchState = ThunkState.pending;
    });
    builder.addCase(getTradingStatusThunk.rejected, (state) => {
      state.tradingStatus.epochOfLastFetched = DateTime.now().toMillis();
      state.tradingStatus.fetchState = ThunkState.done;
    });
    // TODO: make a new slice. This one is getting chunky
    // /////////////////////////////////////////
    builder.addCase(
      getAccessoryThunk.fulfilled,
      function getAccessoryThunkFulfilled(state, action) {
        state.accessories.epochOfLastFetched = DateTime.now().toMillis();
        state.accessories.fetchState = ThunkState.done;

        const { plantId, data } = action.payload;

        if (
          typeof plantId !== "string" ||
          !Array.isArray(data)
        ) return;

        //if no accessories for this plant exist, make an empty record
        if (state.accessories.data[plantId] === undefined) {
          state.accessories.data[plantId] = {};
        }

        data.forEach((accessory) => {
          if (!isPlantAccessory(accessory)) return;

          //if no entry for this accessory, make a new entry
          if (state.accessories.data[plantId][accessory.id] === undefined) {
            state.accessories.data[plantId][accessory.id] = {
              "info": accessory,
              "data": undefined,
            };
          } else {
            //add that accessory to the plant
            state.accessories.data[plantId][accessory.id].info = accessory;
          }
        });

        /**
         * we need to go through and clean out any old accessories no longer
         * in our list if they've been removed from the plant. If we dont
         * do this then the redux will hold onto them after theyve been removed.
         *
         * This reducer handles calls for both the list and individual accessory thunk
         * so we only want to do this when we know its a "get all the accessory" side
         * of the thunk. Thats what `isEntireListForPlant` will tell us
         */
        if (
          !action.payload.isEntireListForPlant ||
          state.accessories.data[plantId] === undefined
        ) return;

        //get all the accessories in the redux and in our response
        const allAccessoryIdsInRedux = Object.keys(state.accessories.data[plantId])
          .map((key) => Number(key));
        const allAccessoryIdsInResponse = data.map((accessory) => accessory.id);

        //find the ones from the redux which are not in the response list.
        const idsThatHaveBeenRemoved = allAccessoryIdsInRedux
          .filter((id) => !allAccessoryIdsInResponse.includes(id));

        //loop through and delete each of those entries.
        idsThatHaveBeenRemoved.forEach((id) => {
          delete state.accessories.data[plantId][id];
        });
      });
    builder.addCase(getAccessoryThunk.pending, (state) => {
      state.accessories.fetchState = ThunkState.pending;
    });
    builder.addCase(getAccessoryThunk.rejected, (state) => {
      state.accessories.epochOfLastFetched = DateTime.now().toMillis();
      state.accessories.fetchState = ThunkState.done;
    });
    // //////////////////////////////
    builder.addCase(
      getAccessoryStatusThunk.fulfilled,
      function getAccessoryStatusThunkFulfilled(state, action) {
        state.accessories.epochOfLastFetched = DateTime.now().toMillis();
        state.accessories.fetchState = ThunkState.done;

        const { plantId, data, accessoryId } = action.payload;

        if (
          typeof plantId !== "string" ||
          !isServerPlantAccessoryStatus(data)
        ) return;

        state.accessories.data[plantId][accessoryId].data = data;
      });
    builder.addCase(getAccessoryStatusThunk.pending, (state) => {
      state.accessories.fetchState = ThunkState.pending;
    });
    builder.addCase(getAccessoryStatusThunk.rejected, (state) => {
      state.accessories.epochOfLastFetched = DateTime.now().toMillis();
      state.accessories.fetchState = ThunkState.done;
    });
    //////////////////////////////////////////////////
    builder.addCase(
      getSimInfoThunk.fulfilled,
      function getSimInfoThunkFulfilled(state, action) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;

        const { simId } = action.payload;

        if (simId) {
          if (!state.sims.data[simId]) {
            state.sims.data[simId] = {};
          }

          state.sims.data[simId].info = action.payload;
        }
      }
    );
    builder.addCase(
      getSimInfoThunk.pending,
      function getSimInfoThunkPending(state) {
        state.sims.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getSimInfoThunk.rejected,
      function getSimInfoThunkRejected(state) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;
      }
    );
    //////////////////////////////////////////////////
    builder.addCase(
      getSimDataThunk.fulfilled,
      function getSimDataThunkFulfilled(state, action) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;

        if (!state.sims.data[action.meta.arg.simId]) {
          state.sims.data[action.meta.arg.simId] = {};
        }

        const data = state.sims.data[action.meta.arg.simId].data ?? {};

        data[action.meta.arg.period] = action.payload;
        state.sims.data[action.meta.arg.simId].data = data;
      }
    );
    builder.addCase(
      getSimDataThunk.pending,
      function getSimDataThunkPending(state) {
        state.sims.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      getSimDataThunk.rejected,
      function getSimDataThunkRejected(state) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;
      }
    );
    //////////////////////////////////////////////////
    builder.addCase(
      sendSimCommandThunk.fulfilled,
      function sendSimCommandThunkFulfilled(state, action) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;

        const { simId } = action.payload;

        if (simId) {
          if (!state.sims.data[simId]) {
            state.sims.data[simId] = {};
          }

          state.sims.data[simId].info = action.payload;
        }
      }
    );
    builder.addCase(
      sendSimCommandThunk.pending,
      function sendSimCommandThunkPending(state) {
        state.sims.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      sendSimCommandThunk.rejected,
      function sendSimCommandThunkRejected(state) {
        state.sims.epochOfLastFetched = DateTime.now().toMillis();
        state.sims.fetchState = ThunkState.done;
      }
    );
    // ////////////////////////////////////////
    builder.addCase(
      fetchSchedulesThunk.fulfilled,
      function getSchedulesThunkFulfilled(state, action) {
        state.schedules.epochOfLastFetched = DateTime.now().toMillis();
        state.schedules.fetchState = ThunkState.done;

        const {targetPlantId, data, scheduleIdThatWasDeleted} = action.payload;
        const requestAction = action.payload.action;

        if(typeof targetPlantId !== "string") return;
        const storeExistsForPlantId = state.schedules.data[targetPlantId] !== undefined;

        if(requestAction === "get" && Array.isArray(data)) {
          state.schedules.data[targetPlantId] = data;
        } else if (requestAction === "delete" && typeof scheduleIdThatWasDeleted === "number") {
          if(storeExistsForPlantId) {
            const indexOfDeleted = state.schedules.data[targetPlantId].findIndex((schedule) =>
              schedule.id === scheduleIdThatWasDeleted);
            if(indexOfDeleted > -1) {
              const newState = [...state.schedules.data[targetPlantId]];
              newState.splice(indexOfDeleted, 1);
              state.schedules.data[targetPlantId] = newState;
            }
          }
        } else {
          // deal with any other action that involves returning a single
          // event object e.g. single get, update or create

          if(data === undefined || !isSchedulePlantCommandEvent(data)) return;

          // provide a default if there is no store and we want to start
          // splicing and searching it.
          if(!storeExistsForPlantId) state.schedules.data[targetPlantId] = [];

          // check if this entry already exists and we want to update rather than push
          const indexOfAlreadyExistingEntry = state.schedules.data[targetPlantId]
            .findIndex((schedule) => (
              typeof data.id === "number" &&
              schedule.id === data.id
            ));

          if(indexOfAlreadyExistingEntry > -1) {
            // splice out the old one for the new updated one.
            const newState = [...state.schedules.data[targetPlantId]];
            newState.splice(indexOfAlreadyExistingEntry, 1, data);
            state.schedules.data[targetPlantId] = newState;
          } else {
            // if this isn't an already existing entry, just push it to the store
            state.schedules.data[targetPlantId].push(data);
          }
        }
      }
    );
    builder.addCase(
      fetchSchedulesThunk.pending,
      (state) => {
        state.schedules.fetchState = ThunkState.pending;
      }
    );
    builder.addCase(
      fetchSchedulesThunk.rejected,
      (state) => {
        state.schedules.epochOfLastFetched = DateTime.now().toMillis();
        state.schedules.fetchState = ThunkState.done;
      }
    );
  }
});

export const {
  setAppState,
  setCurrentPlant,
  emptyAllCurrentPlantData,
  forceDataRefresh,
  updateSettingsLastRoute,
  updateSettingsLastPlantId,
  updateSettingsShowDemoPlants,
  resetLocalState,
  setSettings,
  setUIFlag,
  determineInitialCurrentPlantId,
  addNotificationToDismissed,
} = appDataSlice.actions;

export default appDataSlice.reducer;