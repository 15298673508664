import { configureStore } from "@reduxjs/toolkit";
import appDataReducer from "../slices/appDataSlice";

const store = configureStore({
  reducer: {
    appData: appDataReducer,
  },
});

export type Store = typeof store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
