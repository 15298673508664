import { DateBounds } from "../api/plant";
import {
  EnvironmentalData,
  FinancialData,
  IPlantData,
  IPlantDataV1
} from "../types/plant";
import { UTC_DATE_FORMAT } from "../util/time";
import { DateTime } from "luxon";
import { UNITS } from "../constants/general";
import {
  potentiallyNullRoundToTwoDecimals,
  potentiallyNullWattToKiloWatt,
  roundToTwoDecimals
} from "../util/general";
import { IUIFriendlyPowerData, IUIFriendlyPriceData, IAppData } from "../types/redux";
import { IDateRange } from "../types/time";
import { initialState } from "../constants/redux";
import { IPriceDataV2 } from "../types/server";

export const getFreshState = (): IAppData => JSON.parse(JSON.stringify(initialState));

//TODO: cleanup
// export function financialDataReponseToReduxReadyObject( // TODO: Fix typo
//   response: FinancialData
// ): FinancialData {
//   const dataIsWorthy = response !== undefined;
//   const utcdate = dataIsWorthy
//     ? DateTime.fromMillis(response.unixTimestamp).toFormat(UTC_DATE_FORMAT) : "";

//   const { plantId, estimatedCost, withoutPvCost, energyEarnings, cryptoEarningsETH,
//     cryptoEarningsAUD, unixTimestamp } =
//     dataIsWorthy
//       ? response
//       : {
//         plantId: "",
//         unixTimestamp: 0,
//         estimatedCost: 0,
//         withoutPvCost: 0,
//         energyEarnings: 0,
//         cryptoEarningsAUD: 0,
//         cryptoEarningsETH: 0,
//       };

//   return {
//     plantId,
//     date: utcdate,
//     unixTimestamp,
//     estimatedCost: Math.round(estimatedCost),
//     withoutPvCost: Math.round(withoutPvCost),
//     energyEarnings: Math.round(energyEarnings),
//     cryptoEarningsAUD: Math.round(cryptoEarningsAUD),
//     cryptoEarningsETH: Math.round(cryptoEarningsETH),
//   };
// }

// export function environmentalDataResponseToReduxReadyObject(
//   response: EnvironmentalData
// ): EnvironmentalData {
//   const dataIsWorthy = response !== undefined;
//   const date = dataIsWorthy
//     ? DateTime.fromMillis(response.unixTimestamp).toFormat(UTC_DATE_FORMAT) : "";

//   // this looks horrid. Sorry about that
//   const {
//     plantId,
//     unixTimestamp,
//     pvWattHours,
//     nonRenewableWattHours,
//     activePowerUsageWattHours,
//     gridImportWattHours,
//     gridExportWattHours,
//     batteryDischargeWattHours,
//     batteryChargeWattHours,
//     selfSufficiency
//   } =
//     dataIsWorthy
//       ? response
//       : {
//         plantId: "",
//         unixTimestamp: 0,
//         pvWattHours: 0,
//         nonRenewableWattHours: 0,
//         activePowerUsageWattHours: 0,
//         gridImportWattHours: 0,
//         gridExportWattHours: 0,
//         batteryDischargeWattHours: 0,
//         batteryChargeWattHours: 0,
//         selfSufficiency: 0,
//       };

//   return {
//     plantId,
//     date,
//     unixTimestamp,
//     pvWattHours: Math.round(pvWattHours),
//     nonRenewableWattHours: Math.round(nonRenewableWattHours),
//     activePowerUsageWattHours: Math.round(activePowerUsageWattHours),
//     gridImportWattHours: Math.round(gridImportWattHours),
//     gridExportWattHours: Math.round(gridExportWattHours),
//     batteryDischargeWattHours: Math.round(batteryDischargeWattHours),
//     batteryChargeWattHours: Math.round(batteryChargeWattHours),
//     selfSufficiency: selfSufficiency !== undefined ? roundToTwoDecimals(selfSufficiency) : undefined
//   };
// }

export function plantDataToUIFriendlyPowerData(data: IPlantData): IUIFriendlyPowerData {
  const friendlyPowerData: IUIFriendlyPowerData = {
    pvPower: {
      value: potentiallyNullWattToKiloWatt(data.pvPower),
      label: "Solar Power",
      unit: UNITS.KILOWATT
    },
    pvFromDC: {
      value: potentiallyNullWattToKiloWatt(data.pvFromDC),
      label: "DC Solar Power",
      unit: UNITS.KILOWATT
    },
    pvFromAC: {
      value: potentiallyNullWattToKiloWatt(data.pvFromAC),
      label: "AC Solar Power",
      unit: UNITS.KILOWATT
    },
    batteryPower: {
      value: potentiallyNullWattToKiloWatt(data.batteryPower),
      label: "Battery Power",
      unit: UNITS.KILOWATT
    },
    gridPower: {
      value: potentiallyNullWattToKiloWatt(data.gridPower),
      label: "Battery Power",
      unit: UNITS.KILOWATT
    },
    batterySoc: {
      value: typeof data.batterySoc === "number"
        ? Math.round(data.batterySoc) : undefined,
      label: "Battery Charge",
      unit: UNITS.PERCENT
    },
    reportedBatterySoc: {
      value: potentiallyNullRoundToTwoDecimals(data.reportedBatterySoc),
      label: "Battery Charge",
      unit: UNITS.PERCENT
    },
    calculatedBatterySoc: {
      value: potentiallyNullRoundToTwoDecimals(data.calculatedBatterySoc),
      label: "Battery Charge",
      unit: UNITS.PERCENT
    },
    loadPower: {
      value: potentiallyNullWattToKiloWatt(data.loadPower),
      label: "Load Power",
      unit: UNITS.KILOWATT
    },
    batteryCharge: {
      value: potentiallyNullWattToKiloWatt(data.batteryCharge),
      label: "Battery Charge",
      unit: UNITS.KILOWATT
    },
    batteryCurrent: {
      value: potentiallyNullRoundToTwoDecimals(data.batteryCurrent),
      label: "Battery Current",
      unit: UNITS.AMP
    },
    batteryDischarge: {
      value: potentiallyNullWattToKiloWatt(data.batteryDischarge),
      label: "Battery Discharge",
      unit: UNITS.KILOWATT
    },
    batteryVoltage: {
      value: potentiallyNullRoundToTwoDecimals(data.batteryVoltage),
      label: "Battery Voltage",
      unit: UNITS.VOLT
    },
    generatorCurrent: {
      value: potentiallyNullRoundToTwoDecimals(data.generatorCurrent),
      label: "Generator Current",
      unit: UNITS.AMP
    },
    generatorFrequency: {
      value: potentiallyNullRoundToTwoDecimals(data.generatorFrequency),
      label: "Generator Frequency",
      unit: UNITS.HERTZ
    },
    generatorVoltage: {
      value: potentiallyNullRoundToTwoDecimals(data.generatorVoltage),
      label: "Generator Voltage",
      unit: UNITS.VOLT
    },
    gridConnectionStatus: {
      value: data.gridConnectionStatus,
      label: "Grid Connection Status",
      unit: "ON/OFF State"
    },
    generatorPower: {
      value: potentiallyNullWattToKiloWatt(data.generatorPower),
      label: "Generator Power",
      unit: UNITS.KILOWATT
    },
    gridExport: {
      value: potentiallyNullWattToKiloWatt(data.gridExport),
      label: "Grid Export",
      unit: UNITS.KILOWATT
    },
    gridImport: {
      value: potentiallyNullWattToKiloWatt(data.gridImport),
      label: "Grid Import",
      unit: UNITS.KILOWATT
    },
    loadVoltage: {
      value: potentiallyNullRoundToTwoDecimals(data.loadVoltage),
      label: "Load Voltage",
      unit: UNITS.VOLT
    },
    loadCurrent: {
      value: potentiallyNullRoundToTwoDecimals(data.loadCurrent),
      label: "Load Current",
      unit: UNITS.AMP
    },
    pvCurrent: {
      value: potentiallyNullRoundToTwoDecimals(data.pvCurrent),
      label: "Solar Current",
      unit: UNITS.AMP
    },
    pvVoltage: {
      value: potentiallyNullRoundToTwoDecimals(data.pvVoltage),
      label: "Solar Voltage",
      unit: UNITS.VOLT
    },
    //ev
    evPower: {
      value: potentiallyNullWattToKiloWatt(data.evPower),
      label: "EV Power",
      unit: UNITS.KILOWATT
    },
    evVoltage: {
      value: potentiallyNullRoundToTwoDecimals(data.evVoltage),
      label: "EV Voltage",
      unit: UNITS.VOLT
    },
    evCurrent: {
      value: potentiallyNullRoundToTwoDecimals(data.evCurrent),
      label: "EV Current",
      unit: UNITS.AMP
    },
    evFrequency: {
      value: potentiallyNullRoundToTwoDecimals(data.evFrequency),
      label: "EV Frequency",
      unit: UNITS.HERTZ
    },
  };

  return friendlyPowerData;
}

export function priceDataToUIFriendlyPriceData(data: IPriceDataV2): IUIFriendlyPriceData {
  return {
    exportPrice: {
      value: roundToTwoDecimals(data.exportPrice),
      label: "Export Price",
      unit: UNITS.CENTS_PER_KILOWATT_HOUR
    },
    importPrice: {
      value: roundToTwoDecimals(data.importPrice),
      label: "Import Price",
      unit: UNITS.CENTS_PER_KILOWATT_HOUR
    }
  };
}

export const PlantDataV1ToV2 = (oldData: IPlantDataV1): IPlantData => ({
  ...generateEmptyPlantData(),
  pvPower: oldData["1"],
  pvFromAC: 0,
  pvFromDC: oldData["1"],
  batteryPower: oldData["2"],
  gridPower: oldData["3"],
  batterySoc: oldData["4"],
  reportedBatterySoc: oldData["4"],
  calculatedBatterySoc: oldData["4"],
  loadPower: oldData["5"],
  unixTimestamp: oldData["timestamp"].toMillis()
});

const generateEmptyPlantData = (): IPlantData => {
  const result: IPlantData = {
    batteryCharge: null,
    batteryCurrent: null,
    batteryDischarge: null,
    batteryPower: null,
    batterySoc: null,
    reportedBatterySoc: null,
    calculatedBatterySoc: null,
    batteryVoltage: null,
    generatorCurrent: null,
    generatorFrequency: null,
    generatorVoltage: null,
    gridConnectionStatus: false,
    gridExport: null,
    gridImport: null,
    gridPower: null,
    loadPower: null,
    loadVoltage: null,
    pvCurrent: null,
    pvPower: null,
    pvFromAC: null,
    pvFromDC: null,
    pvVoltage: null,
    unixTimestamp: null,
    loadCurrent: null,
    generatorPower: null,
    evPower: null,
    evCurrent: null,
    evFrequency: null,
    evVoltage: null,
  };
  return result;
};

/*

THese time functions below fall into a grey area of location. Theyre time related
but are specifically only used to create time based information for interacting
with the server/redux so they're located in the redux specific util file.

*/
/*
    Get an array of all the dates in the bounds which currently
    aren't in the redux appDataSlice store.
  */
export function getDatesNotInBounds(
  dateBasedObject: Record<string, FinancialData | EnvironmentalData>,
  range: IDateRange): Array<string> {
  const result: Array<string> = [];
  let current = range.startDateTime;

  while (current.endOf("day") <= range.endDateTime) {
    const k = current.toFormat(UTC_DATE_FORMAT);
    if (!dateBasedObject[k]) {
      result.push(current.toFormat(UTC_DATE_FORMAT));
    }
    current = current.plus({ days: 1 });
  }
  return result;
}

/*
    Orange a list of dates into proper ranges to query server.
    Recieves a list of individual date strings. Returns the max and min of
    the provided range
*/
//TODO: cleanup
export function organizeDatesIntoRanges(unsortedDates: Array<string>): Array<DateBounds> {
  const dates: DateTime[] = unsortedDates
    .map((value) => DateTime.fromSQL(value))
    .sort((a, b) => a.diff(b).milliseconds);

  let ranges: Array<DateBounds>;
  if (dates.length === 0) {
    ranges = [];
  } else if (dates.length === 1) {
    ranges = [[dates[0].toFormat(UTC_DATE_FORMAT), dates[0].toFormat(UTC_DATE_FORMAT)]];
  } else {
    ranges = [
      [dates[0].toFormat(UTC_DATE_FORMAT), dates[dates.length - 1].toFormat(UTC_DATE_FORMAT)]
    ];
  }
  return ranges;
}

export function getRangesNotFilledFromBounds(
  totalRange: IDateRange,
  dateBasedObject: Record<string, FinancialData | EnvironmentalData>
): Array<DateBounds> | null {
  const toGet = getDatesNotInBounds(dateBasedObject, totalRange);

  if (toGet.length > 0) {
    const ranges = organizeDatesIntoRanges(toGet);
    return ranges;
  } else {
    return null;
  }
}