/* The device screen for a redpi */
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import {
  KeyboardBackspace,
  Refresh,
  ArrowLeft,
  ArrowRight,
  FilterList
} from "@mui/icons-material";

import styles from "./redpi.module.scss";
import { RedPiCommand, RedPiLog, RedPiObject } from "apps-middleware/types/redpi";
import { useAppDispatch } from "apps-middleware/redux/store/hooks";
import {
  fetchRedPiCommandsThunk,
  fetchRedPiLogsThunk,
  postRedPiCommandThunk
} from "apps-middleware/redux/asyncThunks";
import {
  RedPiCommandResponseThunkResponse,
  RedPiCommandsThunkResponse,
  RedPiLogsThunkResponse
} from "apps-middleware/types/redux";
import LayoverContainer from "components/LayoverContainer/LayoverContainer";
import Loading from "components/Loading/Loading";
import { PostRedPiCommandBody } from "apps-middleware/types/server";
import {
  DropdownChecklist,
  Option
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { DateTime } from "luxon";

let isMounted = false;

export default function RedpiControl(): JSX.Element {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingLogs, setIsFetchingLogs] = useState(false);
  const [redpiCommands, setRedpiCommands] = useState<RedPiCommand[]>([]);
  const [redpiLogs, setRedpiLogs] = useState<RedPiLog[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [commandInputModal, setCommandInputModal] = useState(false);
  const [commandInputModalContent, setCommandInputModalContent] = useState<JSX.Element[]>([]);
  const [commandResponse, setCommandResponse] =
    useState<RedPiCommandResponseThunkResponse | undefined>(undefined);

  let productName = "";
  const logOperations = [
    {
      name: "NONE",
      value: "",
    }, {
      name: "REGISTER",
      value: "REGISTER",
    }, {
      name: "REBOOT",
      value: "REBOOT",
    }, {
      name: "UPLOAD",
      value: "UPLOAD",
    }];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thunk = useRef<any>();

  async function fetchCommands() {
    setIsFetching(true);
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(fetchRedPiCommandsThunk());

    thunk.current?.unwrap().then((response: RedPiCommandsThunkResponse) => {
      if (!isMounted) return;
      setRedpiCommands(response);
    })
      .catch(() => {
        // abort silently
      })
      .finally(() => {
        if (!isMounted) return;
        setIsFetching(false);
        thunk.current = undefined;
      });
  }

  const fetchLogs = async () => {
    setIsFetchingLogs(true);
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(fetchRedPiLogsThunk({
      ggid: device.ggid,
      operation: searchValue || "",
      page: pageNumber,
    }));

    thunk.current?.unwrap().then((response: RedPiLogsThunkResponse) => {
      if (!isMounted) return;
      setRedpiLogs(response);
    })
      .catch(() => {
        // abort silently
      })
      .finally(() => {
        if (!isMounted) return;
        setIsFetchingLogs(false);
        thunk.current = undefined;
      });
  };

  function isRedPiObject(obj: unknown): obj is RedPiObject {
    return (
      typeof obj === "object" &&
      obj !== null &&
      "ggid" in obj &&
      "status" in obj &&
      "statusReported" in obj &&
      "isRegistered" in obj
    );
  }

  // Set the device
  let device: RedPiObject;
  if (isRedPiObject(state)) {
    device = state;
  } else {
    // TODO: Implement single ggid getter on express-server side
    device = {
      ggid: "N/A",
      status: "UNHEALTHY",
      statusReported: "N/A",
      isRegistered: false,
    };
  }

  function goBack() {
    navigate(-1);
  }

  async function executeCommand(id: string, body: PostRedPiCommandBody) {
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(postRedPiCommandThunk({id, body}));

    thunk.current?.unwrap().then((response: RedPiCommandResponseThunkResponse | undefined) => {
      if (!isMounted) return;
      setCommandResponse(response);
    })
      .catch((err: number) => {
        setCommandResponse({
          command: id,
          modalTitle: "Command Error",
          statusCode: err,
        });
      })
      .finally(() => {
        if (!isMounted) return;
        thunk.current = undefined;
      });
  }

  const handleCommandModalClose = () => {
    setCommandResponse(undefined);
  };

  const changeCommandInputModal = () => {
    setCommandInputModal(!commandInputModal);
  };

  function decrementPageValue() {
    setPageNumber(Math.max(pageNumber - 1, 1));
  }

  function incrementPageValue() {
    setPageNumber(pageNumber + 1);
  }

  useEffect(() => {
    fetchCommands();
  }, []);

  useEffect(function onload() {
    isMounted = true;
    return function onUnload() {
      isMounted = false;
    };
  }, []);

  // Command Input Modal
  const handleSendIdCommand = () => {
    let plantIdInput = "";
    setCommandInputModalContent([
      <DialogContent key="send_id input modal">
        <TextField
          defaultValue={device.plantId}
          margin="dense"
          id="plantId"
          label="Plant Id"
          type="text"
          fullWidth
          variant="standard"
          style={{paddingBottom: "0.5rem"}}
          onChange={(event) => plantIdInput = event.target.value}
        />
        <Button
          className={styles.commandModalLink}
          onClick={() => executeCommand("send_id", {
            ggid: device.ggid,
            plantId: plantIdInput,
          })}
        >
          Execute Command
        </Button>
      </DialogContent>
    ]);
    changeCommandInputModal();
  };

  // Set the command content
  let commandContent: JSX.Element[] = [];
  if (redpiCommands.length > 0) {
    redpiCommands.forEach((command) => {
      if (command.id === "send_id") {
        commandContent.push(
          <Tooltip title={command.description} key={command.id}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleSendIdCommand}
            >
              Send Plant Id
            </Button>
          </Tooltip>
        );
      } else {
        commandContent.push(
          <Tooltip title={command.description} key={command.id}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => executeCommand(command.id, {ggid: device.ggid})}
            >
              {command.id}
            </Button>
          </Tooltip>
        );
      }
    });
  } else {
    if (!isFetching) {
      commandContent = [<p key="error">Something went wrong</p>];
    }
  }

  // Set the log content
  let logContent: JSX.Element[] = [];
  if (redpiLogs.length > 0) {
    redpiLogs.forEach((log, index) => {
      log.time = DateTime.fromISO(log.time)
        .toLocal()
        .startOf("second")
        .toISO({suppressMilliseconds: true});
      logContent.push(
        <p className={styles.log} key={index}>{
          log.time.split("T").join(" ")
            .split("+")
            .join(" (UTC+") + ")"
        } - <b>{log.operation.toUpperCase()}</b>: {log.result}  {log.comment}
        </p>
      );
    });
  } else {
    if (!isFetchingLogs) {
      logContent = [<p className={styles.log} key="default">
        Press Refresh Search Button To Get Logs</p>];
    }
  }

  switch (device.configurationType) {
    case "hb-v1":
      productName = "HoneyBadger";
      break;
    case "bm-v1":
      productName = "BlackMax";
      break;
    case "dbs-v1":
      productName = "DropBear";
      break;
    case "cpd-v1":
      productName = "Copperhead";
      break;
    case "srm-v1":
      productName = "SunRise Mini";
      break;
    case "sr-v1":
      productName = "SunRise";
      break;
    case null:
    case undefined:
      productName = "";
      break;
    default:
      productName = device.configurationType;
  }

  let inverterSerialNumbers = "";
  if (typeof device.inverterSerialNumbers === "string") {
    inverterSerialNumbers = device.inverterSerialNumbers;
  } else if (device.inverterSerialNumbers !== undefined) {
    inverterSerialNumbers = device.inverterSerialNumbers.join(", ");
  }

  // eslint-disable-next-line max-len
  const devicePage = <a href={`/device/${device.defaultView}`} target="_blank" rel="noopener noreferrer">{device.defaultView} </a>;

  const commandModalContent: JSX.Element[] = [];
  if (commandResponse) {
    if (commandResponse.command === "secureTunnel") {
      commandModalContent.push(
        <a href={commandResponse.url} target="_blank" rel="noreferrer noopener" key="tunnelModal">
          <Button className={styles.commandModalLink}>
            AWS Secure Tunnel
          </Button>
        </a>
      );
    } else {
      for (const [key, value] of Object.entries(commandResponse)) {
        commandModalContent.push(
          <p key={`error-${key}`}><b>{key}:</b> {value}</p>
        );
      }
    }
  }

  return (
    <div className={styles.container} style={{position: "absolute"}}>
      {/* Header */}
      <div>
        <Button
          onClick={goBack}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<KeyboardBackspace />}>
          Back
        </Button>
        <h1 style={{marginBottom: "0.5rem", marginTop: "0.25rem"}}>{device.ggid}</h1>
        <p style={{margin: 0}}>
          {device.isRegistered ? `Registered to: ${device.name}` : "Not Registered"}
        </p>
        <p style={{margin: 0}}>
          Reported <span className={
            `${device.status === "HEALTHY" ? styles.healthy: styles.unhealthy}`
          }>{device.status}</span> on {device.statusReported}
        </p>
      </div>

      {/* Command Bar */}
      <div style={{display: "inline-block", marginBottom: "1em"}}>
        <p style={{marginBottom: 0, fontSize: "0.75rem"}}><i>Commands</i></p>
        {/** Loading Screen */}
        {
          isFetching && <LayoverContainer>
            <Loading informationText={"Fetching Red Pi Data..."} />
          </LayoverContainer>
        }
        {/** Commands found screen */}
        {
          <div className={styles.commandBar}>
            {commandContent}
          </div>
        }
      </div>

      {/* Command Input Modal*/}
      <Dialog
        open={commandInputModal}
        onClose={changeCommandInputModal}
        aria-describedby="modal-command-input-title"
        PaperProps={{
          style: {
            boxShadow: "0 0 0.5em 0.5em rgba(255, 255, 255, 0.5)",
            textAlign: "center"
          }
        }}
      >
        <div>
          <DialogTitle id="modal-command-title">Input values</DialogTitle>
          {commandInputModalContent}
        </div>
      </Dialog>

      {/* Command Response Modal*/}
      <Dialog
        open={Boolean(commandResponse)}
        onClose={handleCommandModalClose}
        aria-describedby="modal-command-title"
        // bodyStyle={styles.commandModal}
        PaperProps={{
          style: {
            boxShadow: "0 0 0.5em 0.5em rgba(255, 255, 255, 0.5)",
            textAlign: "center"
          }
        }}
      >
        <div>
          <DialogTitle id="modal-command-title">{commandResponse?.modalTitle}</DialogTitle>
          <DialogContent>{commandModalContent}</DialogContent>
        </div>
      </Dialog>

      {/* Plant Info Aside */}
      <aside className={styles.plantInfoBox}>
        <h2>Plant Info</h2>
        <p><b>Name:</b> {device.isRegistered ? device.name : "Not Registered"}</p>
        <p><b>Plant ID:</b> {device.isRegistered ? device.plantId : "Not Registered"}</p>
        <p><b>View ID:</b> {device.isRegistered ? devicePage : "Not Registered"}</p>
        <p><b>Chassis ID:</b> {device.isRegistered ? device.chassisId : "Not Registered"}</p>
        <p><b>Plant Serial Number:</b> {
          device.isRegistered ? device.plantSerialNumber : "Not Registered"
        }</p>
        <p><b>Product Type:</b> {
          device.isRegistered ? productName : "Not Registered"
        }</p>
        <p><b>Inverter Serial Number(s):</b> {
          device.isRegistered ? inverterSerialNumbers : "Not Registered"
        }</p>
        <p><b>Red Pi Serial Number:</b> {
          device.isRegistered ? device.redPiSerialNumber : "Not Registered"
        }</p>
        <p><b>RUT Name:</b> {
          device.isRegistered ? device.rutId : "Not Registered"
        }</p>
      </aside>

      {/* Log View */}
      <div className={styles.box}>
        <div className={styles.searchBar}>
          <DropdownChecklist
            isRadio
            popUpSize={{
              width: window.innerWidth * 0.25,
              height: window.innerHeight * 0.5,
            }}
            items={logOperations}
            defaultSelected={[]}
            onSelectionChange={(items: Option[]) => {
              setSearchValue(items[0]?.value);
            }}
            labelText="Filter By Operation"
            icon={<FilterList/>}
          />
          <Button
            onClick={fetchLogs}
            variant="outlined"
            color="primary"
            size="small"
            className={styles.refreshButton}
          >
            <Refresh/> &nbsp; Refresh
          </Button>
          <div className={styles.pageSelector}>
            <Button size="small" onClick={decrementPageValue} className={styles.arrowButton}>
              <ArrowLeft/>
            </Button>
            <span>Page {pageNumber}</span>
            <Button size="small" onClick={incrementPageValue} className={styles.arrowButton}>
              <ArrowRight/>
            </Button>
          </div>
        </div>
        <div className={styles.logBox}>
          {logContent}
        </div>
      </div>
    </div>
  );
}
