/* A pretty card describing a redpi device, and linking to its device page */
import { DateTime } from "luxon";

import styles from "./RedPiDeviceCard.module.scss";
import { RedPiObject } from "apps-middleware/types/redpi";
import { useNavigate } from "react-router-dom";
import { Verified } from "@mui/icons-material";

export default function DeviceCard({ device }: DeviceCardProps) {
  const navigate = useNavigate();

  const selectCard = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate(`/redpi/${device.ggid}`, { state: device });
  };

  const durationFromNow = DateTime
    .now()
    .diff(DateTime.fromISO(device.statusReported), ["days", "hours", "minutes"])
    .toObject();

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  let displayTime;
  if (durationFromNow.days! > 0) {
    displayTime = DateTime.fromISO(device.statusReported).toISODate();
  } else if (durationFromNow.hours! > 1) {
    displayTime = `${Math.round(durationFromNow.hours!)} hours ago`;
  } else {
    displayTime = `${Math.round(durationFromNow.minutes!)} minutes ago`;
  }
  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  return (
    <li className={
      `${styles.card}
      ${device.status === "HEALTHY" ? styles.healthy: styles.unhealthy}
      ${!device.isRegistered ? styles.notRegistered : ""}`
    }>
      <a onClick={selectCard}>
        <h3>
          {device.isRegistered ? device.name : device.ggid}
        </h3>

        <div style={{marginTop: "auto"}}>
          <p>
            {device.status}
          </p>
          <p>
            Last Reported: {displayTime}
          </p>
          <p>
            <Verified style={{verticalAlign: "middle"}}/>
            <span style={{verticalAlign: "middle", marginLeft: "0.5em"}}>
              {device.isRegistered ? "Registered" : "Not Registered"}
            </span>
          </p>
        </div>
      </a>
    </li>
  );
}

type DeviceCardProps = {
  device: RedPiObject
}