/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Slide } from "@mui/material";
import {
  LabelledTimeRange,
  TIME_RANGES
} from "apps-middleware/util/time";
import { PieChart } from "@mui/x-charts/PieChart";
import styles from "./home.module.scss";
import {
  useAppDispatch,
  useAppSelector
} from "apps-middleware/redux/store/hooks";
import { IDateRange } from "apps-middleware/types/time";
import {
  cumulativeDailyEnvironmentalDataThunk,
  cumulativeDailyFinancialDataThunk,
  fetchAggregatePlantDataThunkAction,
} from "apps-middleware/redux/asyncThunks";
import {
  getIsAggregateDataFetching, getYesterdaysCumulativeData
} from "apps-middleware/redux/selectors/powerData";
import { TimeScaleLineChart } from "components/Charts/TimeScaleLineChart";
import { ITimeScaledGraphData } from "types/graph";
import { getAggregateGraphData } from "redux/graphing-selectors";
import { powerProfileDataKeys } from "constants/graph";
import { DateTime } from "luxon";
import { wattToKiloWatt } from "apps-middleware/util/general";
import { getBodyContentHeight } from "util/general";

function HomeBottom(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const isAggregateDataFetching = useAppSelector(getIsAggregateDataFetching);
  const graphData: ITimeScaledGraphData[] = useAppSelector(getAggregateGraphData);
  // const isGraph = useAppSelector(getHomePageStateIsGraph);
  const isGraph = true;
  const { yesterdayFinData, todaysEnviroData } = useAppSelector(getYesterdaysCumulativeData);

  const [graphHeight, setGraphHeight] = React.useState<number>(
    getBodyContentHeight() * 0.9
  );
  const chartContainer = React.useRef<HTMLDivElement>(null);

  const [plotBounds, setPlotBounds] = useState<LabelledTimeRange>(TIME_RANGES.TODAY);
  const [zoomedDomain, setZoomedDomain] = useState<IDateRange | null>(null);

  useEffect(function onDomainChange() {
    if (!plotBounds || !plotBounds.range) return;
    dispatch(fetchAggregatePlantDataThunkAction(plotBounds.range));
  }, [plotBounds.toMillisDifference]);

  useEffect(function onFirstRender() {
    const now = DateTime.now();

    const range: IDateRange = {
      startDateTime: now.minus({ days: 2 }).startOf("day"),
      endDateTime: now.endOf("day")
    };

    dispatch(cumulativeDailyFinancialDataThunk(range));
    dispatch(cumulativeDailyEnvironmentalDataThunk(range));

    // set resize handlers
    function onResizeHandler() {
      if (chartContainer && chartContainer.current) {
        const graphRect = chartContainer.current.getBoundingClientRect();
        const naturalBodyHeight = getBodyContentHeight();
        const desiredRatioHeight = graphRect.width * 0.5;
        const preferredHeight = desiredRatioHeight < 420
          ? naturalBodyHeight * 0.9 //0.9 ensures all X axis are kept in view
          : desiredRatioHeight;
        //allow for a minimum height of 400
        setGraphHeight(Math.max(preferredHeight, 420));
      }
    }
    window.addEventListener("resize", onResizeHandler);
    onResizeHandler();

    return () => {
      window.removeEventListener("resize", onResizeHandler);
    };

  }, []);

  const pieData = [
    { id: 0, value: wattToKiloWatt(todaysEnviroData.gridImportWattHours), label: "Grid Import (kWh)" },
    {id: 1, value: wattToKiloWatt(todaysEnviroData.gridExportWattHours), label: "Grid Export (kWh)" }
  ];

  return (
    <Grid container spacing={2} style={{ paddingBottom: 20, paddingTop: 0, paddingLeft: 20, paddingRight: 20 }}>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <Paper className={styles.valueBoxBottom}>
          <Slide
            direction="right"
            in={isGraph}
            mountOnEnter
            unmountOnExit
            timeout={{
              enter: 500,
              exit: 0
            }}>
            <Grid container className={styles.graphViewContainer}>
              <Grid item xs={12} ref={chartContainer}>
                <TimeScaleLineChart
                  isLoading={isAggregateDataFetching}
                  data={graphData}
                  leftYAxis={{
                    datakey: powerProfileDataKeys,
                  }}
                  plotBounds={plotBounds.range}
                  graphHeight={graphHeight}
                  zoomedDomain={zoomedDomain}
                  setZoomedDomain={setZoomedDomain}
                  dataKeysToConsider={powerProfileDataKeys}
                />
              </Grid>
            </Grid>
          </Slide>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <Paper className={styles.valueBoxBottom} style={{ padding: 20, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h6">Utility Usage</Typography>
          <Box style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box className={styles.icon} style={{height: "100%", width:"100%"}}>
              <PieChart
                colors={["gray", "lightgreen"]} // Use palette
                margin={{ top: 0, bottom: 40, left: 40, right:40 }}
                series={[{ data: pieData,
                  innerRadius: 50,
                  outerRadius: 150,
                  paddingAngle: 5,
                  cornerRadius: 5,
                  cx: "50%",
                  cy: "50%",
                }]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                  },
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default HomeBottom;
