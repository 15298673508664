import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { useFinancialData } from "apps-middleware/hooks/useCachedData";
import { palette } from "apps-middleware/constants/palette";

import { LabelledTimeRange, TIME_RANGES } from "apps-middleware/util/time";
import { DropdownDateRangePicker }
  from "components/Dropdowns/DropdownRangePicker/DropdownRangePicker";
import { Restore } from "@mui/icons-material";
import {
  electricityPurchasedAccessor,
  energyTradingAccessor,
  selfConsumptionAccessor,
  cryptoAccessor,
} from "apps-middleware/util/accessors";

import { IDateRange } from "apps-middleware/types/time";

const TableCell = ({
  title,
  value,
  colour,
}: {
  title: string;
  value: string;
  colour: string;
}) => (
  <div
    style={{
      border: `solid 1.5px ${"white"}`,
      flex: "25%",
      display: "grid",
      placeItems: "center",
      padding: 10,
    }}
  >
    <Typography
      variant="body2"
      style={{
        color: colour,
        fontWeight: "bold",
      }}
    >
      {title}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </div>
);

interface FinancialImpactPageProps {
  currentPlantId: string | undefined;
}

const FinancialImpactPage: React.FC<FinancialImpactPageProps> = ({
  currentPlantId,
}) => {
  const financialData = useFinancialData({
    plantId: currentPlantId,
  });

  const [plotBounds, setPlotBoundsTrue] = useState<LabelledTimeRange>(
    TIME_RANGES.TODAY
  );
  const setPlotBounds = (arg: LabelledTimeRange) => {
    setZoomedDomain(null);
    setPlotBoundsTrue(arg);
  };

  const [zoomedDomain, setZoomedDomain] = useState<IDateRange | null>(null);

  function resetZoomedDomain() {
    setZoomedDomain(null);
  }

  const cumulativeFinancialData = financialData.daily.cumulativeDayDataForRange(
    {
      startDate: plotBounds.range.startDateTime,
      endDate: plotBounds.range.endDateTime,
    }
  );

  return (
    <Card>
      <CardContent>
        {financialData?.isFetching ? (
          <Typography>Loading...</Typography>
        ) : (
          <Grid container flexDirection={"column"} gap={2}>
            <Typography
              variant="h5"
              align="center"
              style={{ lineHeight: "unset", marginTop: "20px" }}
            >
              Your System's financial Impact
            </Typography>
            {zoomedDomain && (
              <Button
                onClick={resetZoomedDomain}
                style={{ marginRight: 10 }}
                variant="contained"
                color="info"
                startIcon={<Restore />}
              >
                Reset Zoom
              </Button>
            )}
            <DropdownDateRangePicker
              dateRange={plotBounds}
              setDateRange={setPlotBounds}
            />
            <Grid
              container
              flexDirection={"row"}
              flexWrap="wrap"
              gap={0}
              flexBasis="50%"
              style={{ padding: "20px" }}
            >
              <TableCell
                colour={palette.primary.red}
                title="Electricity Purchases"
                value={
                  cumulativeFinancialData
                    ? "$" +
                      (
                        electricityPurchasedAccessor(cumulativeFinancialData) /
                        100
                      ).toFixed(2)
                    : "---"
                }
              />
              <TableCell
                colour={palette.secondary.blue.primary}
                title="Self Consumption"
                value={
                  cumulativeFinancialData
                    ? "$" +
                      (
                        selfConsumptionAccessor(cumulativeFinancialData) / 100
                      ).toFixed(2)
                    : "---"
                }
              />
              <TableCell
                colour={palette.secondary.green.primary}
                title="Energy Trading"
                value={
                  cumulativeFinancialData
                    ? "$" +
                      (
                        energyTradingAccessor(cumulativeFinancialData) / 100
                      ).toFixed(2)
                    : "---"
                }
              />
              <TableCell
                colour={palette.secondary.orange.primary}
                title="Crypto Mining"
                value={
                  cumulativeFinancialData
                    ? "$" +
                      (cryptoAccessor(cumulativeFinancialData) / 100).toFixed(2)
                    : "---"
                }
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default FinancialImpactPage;
