import React from "react";
import { WbSunny, Cloud, BeachAccess, CloudQueue, FlashOn,
  CloudOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { IWeatherResponse } from "apps-middleware/types/server";

type WeatherIconProps = {
  weather: IWeatherResponse | null | undefined;
};

const WeatherIcon: React.FC<WeatherIconProps> = ({ weather}) => {

  const getWeatherIcon = () => {
    switch (weather?.icon) {
      case "Clear":
        return <WbSunny fontSize={"large"}/>;
      case "Clouds":
        return <Cloud fontSize={"large"}/>;
      case "Rain":
        return <CloudQueue fontSize={"large"}/>;
      case "Snow":
        return <BeachAccess fontSize={"large"}/>;
      case "Thunderstorm":
        return <FlashOn fontSize={"large"}/>;
      case "Mist":
        return <CloudOutlined fontSize={"large"}/>;
      case "Fog":
        return <CloudOutlined fontSize={"large"}/>;
      default:
        return <CloudOutlined fontSize={"large"}/>;
    }
  };

  return (
    <div className="weather-icon" style={{ display: "flex", flexDirection: "row",
      alignItems: "center", alignContent: "center" }}>
      {weather !== undefined ? (
        <>
          <div style={{ paddingRight: "5px" }}><CloudOutlined fontSize={"large"}/></div>
          <Typography style={{ fontSize: "0.7em", color: "cyan" }}>{weather?.temp ??
            0} °C</Typography>
        </>
      ) : (
        <>
          <div style={{ paddingRight: "5px" }}>{getWeatherIcon()}</div>
          <Typography style={{ fontSize: "0.7em", color: "cyan" }}>-- °C</Typography>
        </>
      )}
    </div>
  );

};

export default WeatherIcon;
