/* eslint-disable max-len */
import { RequestResult, ServerResponse } from "../types/server";
import { request } from ".";
import { DateTime } from "luxon";
import { SimCommandRequest, StatsPeriod } from "../types/sim";

export const getSimInfo = (
  simId: string
): RequestResult<ServerResponse["v3"]["sims"]["{simId}"]["GET"]["200"]> => {
  return request(
    `v3/sims/${simId}`,
    "GET",
  );
};

export const getSimData = (
  simId: string,
  from: DateTime,
  to: DateTime,
  period: StatsPeriod,
  imsi?: string
): RequestResult<ServerResponse["v3"]["sims"]["{simId}"]["data"]["GET"]["200"]> => {
  const query: Record<string, unknown> = {
    from: from.toMillis().toString(),
    to: to.toMillis().toString(),
    period,
    imsi
  };

  return request(
    `v3/sims/${simId}/data`,
    "GET",
    undefined,
    false,
    query,
  );
};

export const sendSimCommand = (
  simId: string,
  command: SimCommandRequest
): RequestResult<ServerResponse["v3"]["sims"]["{simId}"]["command"]["POST"]["200"]> => {
  return request(
    `v3/sims/${simId}/command`,
    "POST",
    command,
  );
};
