import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import type {RootState, AppDispatch} from "./configureStore";

/*
 Sourced from: https://react-redux.js.org/using-react-redux/usage-with-typescript
 Use throughout your app instead of plain `useDispatch` and `useSelector`
*/
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
