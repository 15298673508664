import { request } from ".";
import { PhysicalPlantId } from "../types/plant";
import {
  ScheduleId,
  SchedulePlantCommandEventCreate,
  SchedulePlantCommandEventUpdate
} from "../types/schedules";
import { RequestResult, ServerResponse } from "../types/server";

/**
 * Get all the schedules for this plant. If you need to ever get
 * all the schedules for all plants you have access to, you can implement the
 * GET: v3/schedules endpoint.
 *
 * @param plantId plantId to get schedules for
 * @returns array of schedules for plant
 */
export function getPlantSchedules(
  plantId: PhysicalPlantId,
): RequestResult<
  ServerResponse["v3"]["plants"]["{plantId}"]["schedules"]["GET"]["200"]
> {
  return request(
    `v3/plants/${plantId}/schedules`,
    "GET",
  );
}

/**
 * Get schedule by schedule Id
 *
 * @param scheduleId scheduleId to get
 * @returns schedule object
 */
export function getPlantScheduleByScheduleId(
  scheduleId: ScheduleId,
): RequestResult<
  ServerResponse["v3"]["schedules"]["{scheduleId}"]["GET"]["200"]
> {
  return request(
    `v3/schedules/${scheduleId}`,
    "GET",
  );
}

/**
 * Create a schedule for a plant
 *
 * @param body schedule to create
 * @returns The created schedule on 200
 */
export function postPlantSchedules(body: SchedulePlantCommandEventCreate): RequestResult<
  ServerResponse["v3"]["schedules"]["POST"]["200"]
> {
  return request(
    "v3/schedules",
    "POST",
    body
  );
}

export function putPlantScheduleByScheduleId(
  scheduleId: ScheduleId,
  body: SchedulePlantCommandEventUpdate
): RequestResult<ServerResponse["v3"]["schedules"]["{scheduleId}"]["PUT"]["200"]> {
  return request(
    `v3/schedules/${scheduleId}`,
    "PUT",
    body
  );
}

export function deleteScheduleByScheduleId(
  scheduleId: ScheduleId
): RequestResult<ServerResponse["v3"]["schedules"]["{scheduleId}"]["DELETE"]["204"]> {
  return request(
    `v3/schedules/${scheduleId}`,
    "DELETE"
  );
}