import {
  IPlantGetPricesV2Params,
  IPlantGetPricesV2Response,
  RequestResult
} from "../types/server";
import { request } from ".";

export function fetchPriceDataForPlant(
  params: IPlantGetPricesV2Params
): RequestResult<IPlantGetPricesV2Response> {
  return request("v2/plant/getPrices", "POST", params);
}
