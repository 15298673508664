import { IPlantData } from "apps-middleware/types/plant";
import { TradingHistory } from "apps-middleware/types/trading";

export enum Graphs {
  energyProfile = "Energy Profile",
  wholesalePrice = "Wholesale Price",
  batteryPercentage = "Battery Percentage",
  pvVoltageCurrent = "PV Voltage & Current",
  batteryVoltageCurrent = "Battery Voltage & Current",
  generatorVoltageCurrent = "Generator Voltage & Current",
  generatorFrequency = "Generator Frequency",
  loadVoltageCurrent = "Load Voltage & Current",
  evVoltageCurrency = "EV Charging Voltage & Current",
}

export interface ITimeScaledGraphData extends Partial<
  Omit<IPlantData, "gridConnectionStatus">
> {
  importPrice?: number | null;
  exportPrice?: number | null;
  mostRecentCommand?: TradingHistory;
}

export type PotentialDataKeys =
  keyof Omit<ITimeScaledGraphData, "unixTimestamp" | "gridConnectionStatus" | "mostRecentCommand">;

export interface IGraphDataKey {
  dataKey: PotentialDataKeys;
  stroke: string;
  unit: string;
}