/** Layover Container Component */
const LayoverContainer = ({
  children
}: {
  children: JSX.Element
}) => (
  <div style={{
    position: "absolute", top: 0, left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
    display: "grid",
    placeContent: "center",
    placeItems: "center"
  }}>{children}</div>
);

export default LayoverContainer;
