import React from "react";
import loadingImg from "assets/img/RedEarthLogoBlinking.svg";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const Loading = ({
  informationText,
  size = 100
}: {
  informationText?: string,
  size?: number,
}): JSX.Element => (
  <Grid container flexDirection="column" alignItems="center" justifyContent="center">
    <Grid item>
      <img src={loadingImg} alt="Loading" width={size} height={size} />
    </Grid>
    {informationText && (
      <Grid item>
        <Typography style={{ color: "white" }}>
          {informationText}
        </Typography>
      </Grid>
    )}
  </Grid>
);

export default Loading;
