import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Button,
} from "@mui/material";

import { KeyboardBackspace } from "@mui/icons-material";
import { useAppSelector } from "apps-middleware/redux/store/hooks";
import { selectCurrentPlantId } from "apps-middleware/redux/selectors/plants";
import { usePlant } from "apps-middleware/hooks/usePlant";
import { useNavigate } from "react-router-dom";
import "./energyStyles.css";
import SimDetailsPage from "./SimDetailsPage";
import EnergyTradingPage from "./EnergyTradingPage";
import PlantConfigurationPage from "./PlantConfigurationPage";
import FinancialImpactPage from "./FinancialImpactPage";
import DashboardHomePage from "./DashboardHomePage";
import GraphPage from "./GraphPage";

function Energy() {
  const [selectedMenu, setSelectedMenu] = useState("Energy Dashboard");

  const handleMenuClick = (item: string) => {
    setSelectedMenu(item);
  };
  const currentPlantId = useAppSelector(selectCurrentPlantId);

  const plant = usePlant({ plantId: currentPlantId });

  const simId = plant?.configurationDetails
    ? (
        plant.configurationDetails as { sim_imei?: string }
    ).sim_imei?.replaceAll(" ", "")
    : undefined;

  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <div className="energy-root">
      <CssBaseline />

      {/* Sidebar Menu */}
      <Drawer
        className="energy-drawer"
        variant="permanent"
        classes={{
          paper: "energy-drawerPaper",
        }}
      >
        <List>
          <ListItemButton
            selected={selectedMenu === "Energy Dashboard"}
            onClick={() => handleMenuClick("Energy Dashboard")}
          >
            <ListItemText primary="Energy Dashboard" />
          </ListItemButton>
          <ListItemButton
            selected={selectedMenu === "Historical Data"}
            onClick={() => handleMenuClick("Historical Data")}
          >
            <ListItemText primary="Historical Data" />
          </ListItemButton>
          <ListItemButton
            selected={selectedMenu === "Financial Impact"}
            onClick={() => handleMenuClick("Financial Impact")}
          >
            <ListItemText primary="Financial Impact" />
          </ListItemButton>
          <ListItemButton
            selected={selectedMenu === "Plant Configuration"}
            onClick={() => handleMenuClick("Plant Configuration")}
          >
            <ListItemText primary="Plant Configuration" />
          </ListItemButton>
          {(plant?.tradingAllowed || plant?.hasPowerRanger) && (
            <ListItemButton
              selected={selectedMenu === "RedEarth PPP"}
              onClick={() => handleMenuClick("RedEarth PPP")}
            >
              <ListItemText primary="RedEarth PPP" />
            </ListItemButton>
          )}
          {simId !== undefined && (
            <ListItemButton
              selected={selectedMenu === "Sim Details"}
              onClick={() => handleMenuClick("Sim Details")}
            >
              <ListItemText primary="Sim Details" />
            </ListItemButton>
          )}
        </List>
      </Drawer>

      {/* Main Content */}
      <div className="energy-content">
        <div style={{ display: "flex", flexDirection: "row",
          alignItems: "center", marginBottom: "20px"}}>
          {window.history.state && window.history.state.idx > 0 ? (
            <Button
              onClick={goBack}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<KeyboardBackspace />}
            >
              Return to system list
            </Button>
          ) : null}
          <Typography variant="h5" className="energy-plant-name">
            {plant?.plantName ?? "--"}
          </Typography>
        </div>
        <Grid >
          {selectedMenu === "Historical Data" && (
            <Grid item xs={12}>
              <Card>
                <GraphPage />
              </Card>
            </Grid>
          )}

          {selectedMenu === "Energy Dashboard" && (
            <Grid item xs={12}>
              <DashboardHomePage />
            </Grid>
          )}

          {selectedMenu === "Financial Impact" && (
            <Grid item xs={12} style={{ width: "100%" }}>
              <FinancialImpactPage currentPlantId={currentPlantId} />
            </Grid>
          )}

          {selectedMenu === "Plant Configuration" && (
            <Grid item xs={12}>
              <PlantConfigurationPage />
            </Grid>
          )}

          {selectedMenu === "RedEarth PPP" && (
            <Grid item xs={12} style={{ width: "100%" }}>
              <Card>
                <EnergyTradingPage />
              </Card>
            </Grid>
          )}

          {selectedMenu === "Sim Details" && (
            <Grid item xs={12}>
              <SimDetailsPage simId={simId} />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Energy;
