import { request } from ".";
import { GenericChallenge } from "../types/challenges";
import { ICustomResponse, IUpdateChallengeParams } from "../types/server";

export const getAllChallengesForPlantID = (plantId: string):
  Promise<ICustomResponse<GenericChallenge[]> | null> => {
  return request(
    "v3/challenges?plantId=" + plantId,
    "GET"
  );
};

export const getSpecificChallenge = (
  challengeId: string,
  plantID: string,
):
  Promise<ICustomResponse<GenericChallenge[]> | null> => {
  return request(
    "v3/challenges/" + challengeId + "?plantId=" + plantID,
    "GET"
  );
};

/**
 *
 * @returns history of all puts made to the same endpoint
 */
export const getAllChallengesForUser = ():
  Promise<ICustomResponse<{
    challengeId: string,
    joinedOn?: number,
    hidden?: boolean,
    seen?: boolean,
  }[]> | null> => {
  return request(
    "v3/users/{userId}/challenges",
    "GET"
  );
};

/**
 *
 * @param body Changes wanting to be made to DB
 * @returns null for 200
 */
export const updateChallenge = (
  body: IUpdateChallengeParams
): Promise<ICustomResponse<null> | null> => {
  return request(
    "v3/users/{userId}/challenges",
    "PUT",
    body
  );
};
