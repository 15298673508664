const TOP_NAV_HEIGHT = 70;
const MIN_WIDTH_TO_ENTER_RATIO = 1600;
export const getBodyContentHeight = () => window.innerHeight - TOP_NAV_HEIGHT;

export const getResponsiveChartAspectRatio = ():number | undefined =>
  window.innerWidth > MIN_WIDTH_TO_ENTER_RATIO ? 1.6 : undefined;

export const capitaliseFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
