// CustomDeviceCardStyles.ts

const fontFamily = "Montserrat, sans-serif";

export const customDeviceCardStyles = {
  // ... (styles)

  DeviceCardManager: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingBottom: 0,
    justifyContent: "space-between",
    borderRadius: 15,
    placeContent: "center",
    placeItems: "center",
  },
  DeviceCardContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 0,
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 15,
  },
  CardActionArea:{
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width:"260px"
  },
  CardActionAreaWrapper:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width:"100%",
  },
  PlantNameHeader:{
    overflow: "hidden",
    textOverflow: "ellipsis", // Add this to handle overflow with ellipsis
    whiteSpace: "nowrap", // Add this to prevent text from wrapping
    width: "100%"
  }
} as const;