import {
  IPasswordRecoveryRequestParams,
  IRegisterUserResponse,
  IRemoveUserRequestParams,
  IUserPersonalDetails,
  RequestResult
} from "../types/server";
import { request } from ".";

export function registerUserWithEmailAndPassword(
  email: string, password: string
): RequestResult<IRegisterUserResponse> {
  return request("v2/auth/registerUser", "POST", { email, password }, true);
}

export function sendVerificationEmail(): RequestResult<void> {
  return request("v2/user/resendVerification", "POST");
}

export function sendPasswordRecovery(params: IPasswordRecoveryRequestParams):
  RequestResult<void> {
  return request("v1/auth/resetPassword", "POST", params, true);
}

export function removeUser(params: IRemoveUserRequestParams)
  : RequestResult<null> {
  return request("v1/auth/removeUser", "POST", params);
}

export function getUserPersonalDetails():
  RequestResult<IUserPersonalDetails> {
  return request("v3/users/{userId}", "GET");
}

export function putUserPersonalDetails(details: IUserPersonalDetails):
  RequestResult<IUserPersonalDetails> {
  return request("v3/users/{userId}", "PUT", details);
}

export function sendGenericEmail(params: {
  subject: string,
  body: string,
}): RequestResult<null> {
  return request("v3/feedback", "POST", params);
}

export function sendFeedback(params: {
  category: string,
  description: string,
  message: string,
}): RequestResult<null> {
  const { category, description, message } = params;
  const subject = `Anonymous feedback has been received regarding ${category}: ${description}`;
  const body = `
    ${subject}
    Message: ${message};
  `;

  return sendGenericEmail({ subject, body });
}
