import { PlantStore, ThunkState } from "../../types/redux";
import {
  ConnectedAppliance,
  IMinifiedPlant,
  IPlant,
  IPlantAlert,
  IPossiblePlant,
  PhysicalPlantId,
  PlantId,
  TariffType
} from "../../types/plant";
import { RootState } from "../store/configureStore";
import { isPlantInfo } from "../../util/type";
import { getIsGridConected, getRenderedName } from "../../util/plant";
import { SchedulePlantCommandEvent } from "./../../types/schedules";
import { DateTime } from "luxon";

export const doesReduxHaveCurrentPlantFullInfo = (state: RootState): boolean => {
  const currentPlantId = selectCurrentPlantId(state);
  if (!currentPlantId || state.appData.plants.data[currentPlantId] === undefined) return false;
  return isPlantInfo(state.appData.plants.data[currentPlantId]);
};

export const selectPlantsArray = (state: RootState): (IPlant | IMinifiedPlant)[] =>
  Object.values(state.appData.plants.data);
export const selectDemoPlantsArray = (state: RootState): (IPlant | IMinifiedPlant)[] =>
  Object.values(state.appData.demoPlants.data);
export const selectPossiblePlantsArray = (state: RootState): (IPossiblePlant)[] =>
  Object.values(state.appData.possiblePlants.data);

export const selectDemoPlants = (state: RootState): PlantStore =>
  state.appData.demoPlants.data;

export const plantsAreFetching = (state: RootState): boolean =>
  state.appData.plants.fetchState !== ThunkState.done;

export const selectNumberOfPlants = (state: RootState): number =>
  selectPlantsArray(state).length;

export const selectNumberOfDemoPlants = (state: RootState): number =>
  selectDemoPlantsArray(state).length;

export const selectNumberOfPossiblePlants = (state: RootState): number =>
  selectPossiblePlantsArray(state).length;

/**
 *
 * @param state
 * @returns full plant object (not minified plant)
 */
export const selectCurrentPlant = (state: RootState): IPlant | undefined => {
  if (state.appData.currentPlantId === undefined) return;

  const currentPlant = state.appData.plants.data[state.appData.currentPlantId];
  const demoPlant = state.appData.demoPlants.data[state.appData.currentPlantId];

  if (currentPlant && isPlantInfo(currentPlant)) return currentPlant;
  else if (demoPlant && isPlantInfo(demoPlant)) return demoPlant;
  else return undefined;
};

/**
 *
 * This selector returns the current plant id. The current Id may be selected
 * from a minified plant which may not have the complete plantInfo object yet.
 *
 * @param state root state
 * @returns the current plant id
 */
export const selectCurrentPlantId = (state: RootState): PlantId | undefined =>
  state.appData.currentPlantId;

/**
 * Return the current plants physical plant Id regardless of plant
 * load state. e.g. can be full info or just minified.
 *
 * @param state Root State
 * @returns current plant selecteds physical plant id
 */
export const selectCurrentPhysicalPlantId = (state:RootState): PhysicalPlantId | undefined => {
  const currentPlantId = selectCurrentPlantId(state);
  if(currentPlantId === undefined) return undefined;
  const currentPlant = state.appData.plants.data[currentPlantId];
  if(currentPlant !== undefined) return currentPlant.physicalPlantId;
};
/**
 *
 * This selector will give you the plantId ONLY if the state has a full plantInfo
 * for the current plant. This is useful if you are using selectors in your component
 * which need additional data from the plantInfo.
 *
 * @param state rootstate
 * @returns boolean or undefined
 */
export const selectCurrentPlantIdAfterFullData = (state: RootState): PlantId | undefined => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return undefined;
  else return state.appData.currentPlantId;
};

export const selectCurrentPlantName = (state: RootState): string | undefined => {
  const plant = selectCurrentPlant(state);
  return plant ? getRenderedName(plant) : undefined;
};

export const selectOnlyDemoPlantsInState = (state: RootState): boolean => (
  selectNumberOfDemoPlants(state) > 0 &&
  selectNumberOfPlants(state) === 0
);

export const isCurrentPlantTrading = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.tradingEnabled;
};

export const isCurrentPlantAllowedToTrade = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.tradingAllowed;
};

export const selectCurrentPlantIsTradingForbidden = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.tradingForbidden;
};

export const selectCurrentPlantIsTradingAllowed = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.tradingAllowed;
};

export const selectCurrentPlantIsOptimumAllowed = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.isOptimus ?? false;
};

export const selectCurrentPlantTradingPurchasePermitted = (state: RootState):
boolean | undefined => {
  const targetPlantId = selectCurrentPlant(state)?.plantId;
  if (targetPlantId === undefined) return undefined;
  const tradingStatus = state.appData.tradingStatus.data[targetPlantId];
  if (tradingStatus) return tradingStatus.plantTradingStatus.tradingManuallyPermitted;
  else return undefined;
};

// for now these are one in the same.
export const selectCurrentPlantPowerRangerPurchasePermitted = (state: RootState):
boolean | undefined => {
  return selectCurrentPlantTradingPurchasePermitted(state);
};

export const selectCurrentPlantIsPowerRangersAllowed = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.hasPowerRanger ?? false;
};

export const selectCurrentPlantTariffType = (state: RootState): TariffType | null => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return null;
  else return currentPlant.tariffType;
};

export const selectCurrentPlantIsPriceConfigured = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return currentPlant.isPricingConfigured;
};

export const selectPlantHasConfigDetails = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return false;
  else return (
    currentPlant.configurationDetails !== undefined &&
    currentPlant.configurationDetails !== null
  );
};

export const selectPlantConfigDetails = (state: RootState): object | undefined => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return undefined;
  else if (
    currentPlant.configurationDetails === undefined ||
    currentPlant.configurationDetails === null
  ) return undefined;
  else return currentPlant.configurationDetails;
};

export const selectCurrentPlantChassisId = (state: RootState) => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return undefined;
  else if (
    currentPlant.chassisId === undefined ||
    currentPlant.chassisId === null
  ) return undefined;
  else return currentPlant.chassisId;
};

export const selectCurrentPlantSerialNumber = (state: RootState) => {
  const currentPlant = selectCurrentPlant(state);
  if (!currentPlant) return undefined;
  else if (
    currentPlant.serialNumber === undefined ||
    currentPlant.serialNumber === null
  ) return undefined;
  else return currentPlant.serialNumber;
};

export const selectCurrentPlantSchedules = (state:RootState):
undefined | SchedulePlantCommandEvent[] => {
  const currentPhysicalPlantId = selectCurrentPhysicalPlantId(state);
  if(currentPhysicalPlantId === undefined) return undefined;
  else return state.appData.schedules.data[currentPhysicalPlantId];
};

export const selectAlertsForCurrentPlantToNotifyUserAbout = (
  state: RootState
): IPlantAlert[] | undefined => {
  const currentPlant = selectCurrentPlant(state);

  if (!currentPlant) return undefined;
  else return currentPlant.alerts
    .filter((alert) => alert.warnUser && !alert.userSeen);
};

export const selectShowAlertModal = (state: RootState): boolean => {
  const toNotify = selectAlertsForCurrentPlantToNotifyUserAbout(state);
  return (toNotify !== undefined && toNotify.length > 0);
};

export const selectCurrentPlantQrId = (state: RootState): string | undefined => {
  return selectCurrentPlant(state)?.qrId;
};

export const selectCurrentPlantExportThreshold = (state: RootState): number | undefined => {
  const threshold = selectCurrentPlant(state)?.exportTradingThreshold;
  return threshold === null ? undefined : threshold;
};

export const selectCurrentPlantIsGridConnected = (state: RootState): boolean => {
  const currentPlant = selectCurrentPlant(state);
  return currentPlant !== undefined
    ? getIsGridConected(currentPlant) : true;
};

export const selectCurrentPlantTimezone = (state: RootState): string | undefined => {
  const currentPlant = selectCurrentPlant(state);
  if (currentPlant) return currentPlant.timezone;
};

export const getEpochOfLatestUpdate = (state: RootState): DateTime | null => {
  const currentPlant = selectCurrentPlant(state);

  if(
    currentPlant === undefined ||
    currentPlant.currentPlantData === undefined ||
    typeof currentPlant.currentPlantData.unixTimestamp !== "number"
  ) return null;
  else {
    return DateTime.fromMillis(currentPlant.currentPlantData.unixTimestamp);
  }
};

export const selectDoesCurrentPlantHaveLoadControllableAccessories =
  (state: RootState): boolean => {
    const currentPlantId = state.appData.currentPlantId;
    if (
      currentPlantId === undefined ||
      selectNumberOfPlants(state) === 0
    ) return false;

    // This is the list of current connected appliances the frontend will consider
    // as being load controllable.
    const ACCEPTABLE_APPLIANCES = Object.values(ConnectedAppliance) as string[];

    //get all the accessory data we have for the plant;
    const accessoriesForPlant = state.appData.accessories.data[currentPlantId];

    //if there is no record for this plant it means no accessories at all.
    if (accessoriesForPlant === undefined) return false;

    //search and find an accessory that matches our conditions for a load controllable
    const validAccessory = Object.values(accessoriesForPlant)
      .find((accessoryObject) => {
        return (
          accessoryObject.info !== undefined &&
          accessoryObject.info.connectedAppliance !== undefined &&
          // this is the important bit. Only return this accessory if its connected
          // appliance is considered an accetable appliance.
          ACCEPTABLE_APPLIANCES.includes(accessoryObject.info.connectedAppliance)
        );
      });

    return validAccessory !== undefined;
  };

/**
 *
 * Util to find the full plant info for a target ID.
 *
 * WARNING: DO NOT PASS THIS INTO USEAPPSELECTOR. It will not work.
 * This function can be used inside another selector but it will not work
 * if passed directly into useAppSelector.
 *
 * @param state Root State
 * @param targetId Target Plant Id
 * @returns full plant id matching target Id or undefined if cant find full plant info
 */

export function getPlantInfoFromStateWithId(
  state: RootState,
  targetId: string
): IPlant | undefined {
  const targetPlant = state.appData.plants.data[targetId];
  if (isPlantInfo(targetPlant)) return targetPlant;
  else return undefined;
}
