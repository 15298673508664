import { IUIFriendlyPowerData } from "../types/redux";
import {
  AlertPriority,
  AlertType,
  HouseMode,
  SystemStatus,
  ConfigurationType
} from "../types/plant";
import { UNITS } from "./general";

export const UnconfirmedPlantKeySemanticName = {
  productName: "Product",
  serialNumber: "Serial Number",
  address: "Address",
  owner: "Owner"
};

export type HouseModeDetails = {
  title: string;
  paragraphs: string[];
}

export const HOUSE_MODE: { [Key in HouseMode]: HouseModeDetails } = {
  "standard": {
    title: "Standard",
    paragraphs: [
      "All devices are free to operate during solar hours and to turn off " +
      "during high price periods.",
      "If required, turn back on overnight during typical off-peak times."
    ]
  },
  "ecoVacation": {
    title: "Eco/Vacation",
    paragraphs: [
      "Devices will only run during daylight hours. Energy trading will always " +
      "allow the battery to charge until 20%. Your energy trading price threshold " +
      "will also go down to 20c."
    ]
  },
};

export const alertPriorityToColour: Record<AlertPriority, string> = {
  "Low": "#ffcc66",
  "Medium": "#ff6600",
  "High": "#ff0000",
};

/**
 * This type to record, to array thing might seems silly,
 * buts its a simple way to get a string array out of a specific
 * type and have Typescript throw an error if we add a new type.
 * So we don't forget to add a property like what may happen
 * if we used a typed array which was hardcoded.
 */
const _systemStatusTypeToRecord: Record<SystemStatus, undefined> = {
  "CREATED": undefined,
  "IN_ASSEMBLY": undefined,
  "ASSEMBLY_COMPLETE": undefined,
  "IN_TESTING": undefined,
  "TESTING_COMPLETE": undefined,
  "IN_TRANSIT": undefined,
  "INSTALLED": undefined
};
export const systemStatusAsArray: SystemStatus[] = Object.keys(
  _systemStatusTypeToRecord
) as SystemStatus[];

export const configurationTypeAsArray: ConfigurationType[] = Object.values(ConfigurationType);

export const alertPriorityToSeverity: Record<AlertPriority, number> = {
  "Low": 1,
  "Medium": 2,
  "High": 3,
};

export const blankPowerData: IUIFriendlyPowerData = {
  pvPower: {
    value: undefined,
    label: "Solar Power",
    unit: UNITS.KILOWATT
  },
  pvFromDC: {
    value: undefined,
    label: "DC Solar Power",
    unit: UNITS.KILOWATT
  },
  pvFromAC: {
    value: undefined,
    label: "AC Solar Power",
    unit: UNITS.KILOWATT
  },
  batteryPower: {
    value: undefined,
    label: "Battery Power",
    unit: UNITS.KILOWATT
  },
  gridPower: {
    value: undefined,
    label: "Battery Power",
    unit: UNITS.KILOWATT
  },
  batterySoc: {
    value: undefined,
    label: "Battery Charge",
    unit: UNITS.PERCENT
  },
  reportedBatterySoc: {
    value: undefined,
    label: "Reported Battery Charge",
    unit: UNITS.PERCENT
  },
  calculatedBatterySoc: {
    value: undefined,
    label: "Calculated Battery Charge",
    unit: UNITS.PERCENT
  },
  loadPower: {
    value: undefined,
    label: "Load Power",
    unit: UNITS.KILOWATT
  },
  batteryCharge: {
    value: undefined,
    label: "Battery Charge",
    unit: UNITS.KILOWATT
  },
  batteryCurrent: {
    value: undefined,
    label: "Battery Current",
    unit: UNITS.AMP
  },
  batteryDischarge: {
    value: undefined,
    label: "Battery Discharge",
    unit: UNITS.KILOWATT
  },
  batteryVoltage: {
    value: undefined,
    label: "Battery Voltage",
    unit: UNITS.VOLT
  },
  generatorCurrent: {
    value: undefined,
    label: "Generator Current",
    unit: UNITS.AMP
  },
  generatorFrequency: {
    value: undefined,
    label: "Generator Frequency",
    unit: UNITS.HERTZ
  },
  generatorVoltage: {
    value: undefined,
    label: "Generator Voltage",
    unit: UNITS.VOLT
  },
  gridConnectionStatus: {
    value: undefined,
    label: "Grid Connection Status",
    unit: "ON/OFF State"
  },
  gridExport: {
    value: undefined,
    label: "Grid Export",
    unit: UNITS.KILOWATT
  },
  gridImport: {
    value: undefined,
    label: "Grid Import",
    unit: UNITS.KILOWATT
  },
  loadVoltage: {
    value: undefined,
    label: "Load Voltage",
    unit: UNITS.VOLT
  },
  loadCurrent: {
    value: undefined,
    label: "Load Current",
    unit: UNITS.AMP
  },
  pvCurrent: {
    value: undefined,
    label: "Solar Current",
    unit: UNITS.AMP
  },
  pvVoltage: {
    value: undefined,
    label: "Solar Voltage",
    unit: UNITS.VOLT
  },
  generatorPower: {
    value: undefined,
    label: "Generator Power",
    unit: UNITS.KILOWATT
  },
  //ev charging values
  evPower: {
    value: undefined,
    label: "EV Power",
    unit: UNITS.KILOWATT
  },
  evCurrent: {
    value: undefined,
    label: "EV Current",
    unit: UNITS.AMP
  },
  evVoltage: {
    value: undefined,
    label: "EV Voltage",
    unit: UNITS.VOLT
  },
  evFrequency: {
    value: undefined,
    label: "EV Frequency",
    unit: UNITS.HERTZ
  },
};

export const tradingRelevantAlerts: AlertType[] = [
  "SYSTEM_UNABLE_TO_TRADE_MISCONFIGURED",
  "SYSTEM_UNABLE_TO_TRADE_INVALID_ENERGY_PROVIDER",
  "PAYMENT_FAIL",
  "INVALID_AMBER",
  "SUBSCRIPTION_LAPSED"
];
