import { useEffect, useRef } from "react";

import { CARBON_OFFSET_PER_KWH, DAILY_CARBON_OFFSET_PER_TREE } from "../constants/general";
import { IAsyncData, PowerData, PriceData, ThunkState } from "../types/redux";

export function arraysIntersection<T>(array1: Array<T>, array2: Array<T>): Array<T> {
  return array1.filter((x) => array2.includes(x));
}

//round to two decimals and make sure there are atleast two 0s
export const roundToTwoDecimals = (num: number): number => (Math.round(num * 100) / 100);
export const roundToOneDecimal = (num: number): number => (Math.round(num * 10) / 10);

export const potentiallyNullRoundToTwoDecimals = (num: number | null): number | undefined => {
  if (num !== null) return Math.round(num * 100) / 100;
  else return undefined;
};

/*
Turn watt value into kilowatt with 2 decimal placs
*/
export const wattToKiloWatt = (num: number): number => roundToTwoDecimals(num / 1000);

export const wattToMegaWatt = (num: number): number => roundToTwoDecimals(num / 1000000);

export const milliWattToWatt = (num: number, round: boolean): number => {
  const watts = roundToTwoDecimals(num / 1000);
  return round ? Math.round(watts) : watts;
};

/**
 *
 * This function returns a cleaned string with a symbol to represent the
 * monetary value of an amount of cents passed in the num column
 *
 * @param num the number value to clean in cents
 * @returns cleaned string with appropriate symbol
 */
export const centsToMoneyWithSymbol = (num: number): string => {
  let isNegative = false;
  if (num < 0) {
    // eslint-disable-next-line no-param-reassign
    num = num * -1;
    isNegative = true;
  }

  if (num < 100) {
    return `${isNegative ? "-" : ""}${Math.round(num)}\u00A2`;
  } else {
    let numberOfDecimals = 0;
    const dollars = num / 100;
    if (dollars < 10 && dollars % 1 !== 0) numberOfDecimals = 2;
    return `${isNegative ? "-" : ""}$${dollars.toFixed(numberOfDecimals)}`;
  }
};

/*
Turn watt value into kilowatt with 2 decimal placs but account for potential null value
*/
export const potentiallyNullWattToKiloWatt = (
  powerDataNumber: number | null
): number | undefined => {
  if (typeof powerDataNumber === "number") return wattToKiloWatt(powerDataNumber);
  else return undefined;
};

export function wattsToDailyTreesOffset(watts: number): number {
  const kwUsedBySolar = watts / 1000;
  const gramsOfCarbonOffset = kwUsedBySolar * CARBON_OFFSET_PER_KWH;
  const treesOffset = gramsOfCarbonOffset / DAILY_CARBON_OFFSET_PER_TREE;
  return treesOffset;
}

export function zeroToOnePercentageToZeroToHundred(
  zeroToOne: number | undefined
): number | undefined {
  if (zeroToOne === undefined) return undefined;
  const zeroToHundred = zeroToOne * 100;
  const finalpercentage = Math.round(zeroToHundred);
  if (finalpercentage < 0) return 0;
  else if (finalpercentage > 100) return 100;
  else return finalpercentage;
}

//this has been grabbed from:
//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: (() => void), delay: number | null): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

//used a lot for testing;
export function clean<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(Number(k))) as K[];
}

/*
I'm using this as a way to create a bit more understanding around
fetching states of our redux state.
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeAsyncDataObject<T>(defaultState: T): IAsyncData<T> {
  return {
    data: defaultState,
    fetchState: ThunkState.neverSent,
    epochOfLastFetched: null
  };
}

export const defaultPowerDataValue = (): IAsyncData<PowerData> =>
  makeAsyncDataObject<PowerData>({});

export const defaultPriceDataValue = (): IAsyncData<PriceData> =>
  makeAsyncDataObject<PriceData>({});

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undefined */
export function objectEquals(x: any, y: any): boolean {
  "use strict";

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(function (i) {
      return objectEquals(x[i], y[i]);
    })
  );
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/* WARNING: arrays must not contain {objects} or behavior may be undefined
    Taken from this thread
    https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
*/
export function arraysEqual<T>(
  a: T[],
  b: T[],
  sortingFn: null | ((a: T, b: T) => number) = null): boolean {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  if (sortingFn !== null) {
    // Need to clone the array as ordering modifies the lists
    const aClone: any[] = [...a];
    const bClone: any[] = [...b];
    aClone.sort(sortingFn);
    bClone.sort(sortingFn);
    for (let i = 0; i < aClone.length; ++i) {
      if (aClone[i] !== bClone[i]) return false;
    }
  } else {
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
  }

  return true;
}

export const doesArticleExistAsFirstWord = (text: string): boolean => {
  const articlesToCheck = ["a", "an", "the"];
  const firstWord = text.split(" ")[0].toLowerCase();
  return articlesToCheck.includes(firstWord);
};

/**
 *
 * A simple compare that stringifies both provided args and check their
 * equality. If you need you can add more depth to this by checking types
 * e.g. array and sorting. You may be better off with arraysEqual function.
 *
 * Small TODO:
 * This is mostly used for quick redux selector comparisons which should
 * eventually develop their own more sophisticated comparision checks to have
 * a tighter control on rendering.
 *
 *
 * @param a any value
 * @param b any value
 * @returns boolean, are the two values equal when stringified
 */
export const simpleStringifyCompare = (a: unknown, b: unknown): boolean =>
  JSON.stringify(a) === JSON.stringify(b);