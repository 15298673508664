import {
  CartesianGrid,
  CurveProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  XAxisProps,
  YAxisProps,
  CartesianGridProps,
  TooltipProps
} from "recharts";

export type SimpleLineData = {
  x: number,
  [key: string]: number,
};

/**
 * `SimpleLineChart` is an easy way to display a chart without much overhead. Simply
 * create an array of `SimpleLineData` and then pass it into the `data` property.
 * It will default to use white strokes, white text and a white line. You can still
 * customize the chart by passing in various properties.
 */
export const SimpleLineChart = ({
  children,
  syncId = "SimpleLineChart",
  height,
  data,
  xAxis,
  yAxis,
  tooltip,
  cartesianGrid,
}: {
  children?: JSX.Element[]
  syncId?: string,
  height?: number,
  data?: SimpleLineData[],
  xAxis?: XAxisProps,
  yAxis?: YAxisProps,
  lines?: CurveProps[],
  tooltip?: TooltipProps<string, string>,
  cartesianGrid?: CartesianGridProps,
  showLineDots?: boolean
}): JSX.Element => {
  // Default properties for the SimpleLineChart which will display
  // the chart against the current theme's dark background
  const xAxisProps: XAxisProps = {
    stroke: "white",
    ...xAxis,
  };
  const yAxisProps: YAxisProps = {
    stroke: "white",
    ...yAxis,
  };
  const cartesianGridProps: CartesianGridProps = {
    horizontal: true,
    vertical: true,
    stroke: "#666666",
    strokeDasharray: "3 3",
    ...cartesianGrid,
  };
  const tooltipProps: TooltipProps<string, string> = {
    labelStyle: { color: "black" },
    ...tooltip,
  };

  return (
    <ResponsiveContainer width="100%" height={height ?? 200}>
      <LineChart
        syncId={syncId}
        data={data}>
        <Tooltip {...tooltipProps} />
        <CartesianGrid {...cartesianGridProps as object} />
        <XAxis {...xAxisProps} dataKey={"x"} />
        <YAxis {...yAxisProps} />
        { children }
      </LineChart>
    </ResponsiveContainer>
  );
};
