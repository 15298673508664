
import { Typography, Box } from "@mui/material";

import { RenderedRealtimeData } from "./constants";

function FactoryText({
  data,
}: {
  data: RenderedRealtimeData;
}): JSX.Element {
  const telemetryTitle = { color: "gold", mb: 0, fontSize: "10px" };
  const telemetryTitleOffGrid = { color: "red", mb: 0, fontSize: "10px" };
  const telemetryValue = { mb: -1.1, fontSize: "15px" };

  return (
    <>
      <Box position="absolute" top="58px" left="290px"
        style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={telemetryValue}>
          {data.PvPower !== undefined ? `${data.PvPower} kW` : "0 kW"}
        </Typography>
        <Typography variant="caption" sx={telemetryTitle}>
          SOLAR
        </Typography>
      </Box>

      <Box position="absolute" top="259px" left="301px"
        style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={telemetryValue}>
          {data.LoadPower !== undefined ? `${data.LoadPower} kW` : "0 kW"}
        </Typography>
        <Typography variant="caption" sx={telemetryTitle}>
          LOAD
        </Typography>
      </Box>

      <Box position="absolute" top="285px" left="10px"
        style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={telemetryValue}>
          {data.EVPower !== undefined ? `${data.EVPower} kW` : "0 kW"}
        </Typography>
        <Typography variant="caption" sx={telemetryTitle}>
          EV CHARGING
        </Typography>
      </Box>

      <Box position="absolute" top="305px" left="173px"
        style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={telemetryValue}>
          {data.BatteryPower !== undefined ? (
            <>{`${Math.abs(data.BatteryPower as number)} kW `}</>
          ) : (
            "0 kW"
          )}
        </Typography>
        <Typography variant="caption" sx={telemetryTitle}>
          BATTERY
        </Typography>
        {data.BatteryPower !== undefined ? (
          <Typography
            variant="caption"
            sx={{ fontSize: "0.8em", marginTop: -0.5, color: "cyan" }}
          >
            {data.BatteryPower as number < 0
              ? "← Charging"
              : data.BatteryPower as number > 0
                ? "→ Discharging"
                : "Idle"}
          </Typography>
        ) : null}
      </Box>

      {data.GeneratorPower && (data.GeneratorPower as number > 0) ? (
        <Box position="absolute" top="305px" left="288px"
          style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={telemetryValue}>
            {data.GeneratorPower !== undefined ? (
              <>{`${Math.abs(data.GeneratorPower as number)} kW `}</>
            ) : (
              "0 kW"
            )}
          </Typography>
          <Typography variant="caption" sx={telemetryTitle}>
            GENERATOR
          </Typography>
          {data.GeneratorPower !== undefined ? (
            <Typography variant="caption" sx={{ fontSize: "0.6em", marginTop: -0.5 }}>
              {(data.GeneratorPower as number < 0)
                ? "← Importing"
                : (data.GeneratorPower as number > 0)
                  ? "← Importing"
                  : "Idle"}
            </Typography>
          ) : null}
        </Box>
      ) : data.GeneratorVoltage && (data.GeneratorVoltage as number < 100) &&
      (!data.GridPower || data.GridPower === 0) ? (
          <Box position="absolute" top="322px" left="288px"
            style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={telemetryTitleOffGrid}>
              OFF GRID
            </Typography>
          </Box>
        ) : (
          <Box position="absolute" top="305px" left="288px"
            style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={telemetryValue}>
              {data.GridPower !== undefined ? (
                <>{`${Math.abs(data.GridPower as number)} kW `}</>
              ) : (
                "0 kW"
              )}
            </Typography>
            <Typography variant="caption" sx={telemetryTitle}>
              {data?.isOffGrid ? <span>GENERATOR</span> : <span>GRID</span>}
            </Typography>
            {data.GridPower !== undefined ? (
              <Typography variant="caption" sx={{ fontSize: "0.6em", marginTop: -0.5 }}>
                {data.GridPower as number < 0
                  ? "→  Exporting"
                  : data.GridPower as number > 0
                    ? "← Importing"
                    : "Idle"}
              </Typography>
            ) : null}
          </Box>
        )}
    </>
  );
}

export default FactoryText;