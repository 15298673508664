import { ProductId } from "./../types/products";
import { request } from ".";
import { RequestResult, ServerResponse } from "../types/server";
import { getStore } from "./../../util/products";

export const getPurchases = (productName?: ProductId): RequestResult<
  ServerResponse["v3"]["purchases"]["{appStore}"]["GET"]["200"]> | null => {

  const store = getStore();
  if (!store) return null;

  return request(`v3/purchases/${store}`, "GET", productName ? { productName } : undefined);
};

export const validatePurchase = (productId: string, body: {
  transactionReceipt?: string,
  androidProductId?: string,
  purchaseToken?: string,
}): RequestResult<
  ServerResponse["v3"]["purchases"]["{appStore}"]["PUT"]["201"]> | null => {

  const store = getStore();
  if (!store) return null;

  return request(
    `v3/purchases/${store}/${productId}`,
    "PUT",
    body
  );
};