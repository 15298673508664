
import Grid from "@mui/material/Grid";

import { Typography, Box, CircularProgress } from "@mui/material";

import { DateTime } from "luxon";
import { UI_HOUR_FORMAT } from "apps-middleware/util/time";

import WeatherIcon from "./WeatherIcon";
import FactoryText from "./FactoryText";
import HouseText from "./HouseText";
import CorePowerFlowComponent from "./CorePowerFlowComponent";
import { RenderedRealtimeData } from "./constants";

function PowerFlowDiagram({
  data,
}: {
  data: RenderedRealtimeData;
}): JSX.Element {

  const lastDataReceived = data.lastDataReceived;
  const timestampMessage = lastDataReceived
    ? `Latest data as of: ${lastDataReceived.toFormat(UI_HOUR_FORMAT)}`
    : "Unable to determine last time of update";

  return (
    <Box position="relative" style={{ width: "100%" }}>
      <Grid
        container
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Grid item>
          <Typography variant="subtitle1">
            Chassis: {data.chassis?.toString()}
          </Typography>
          <Typography variant="body2" style={{ fontSize: "12px" }}>
            {timestampMessage}
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            paddingRight: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "110px",
          }}
        >
          <Typography variant="caption">Realtime</Typography>
          <CircularProgress size={20} color="error" />
        </Grid>
      </Grid>

      <CorePowerFlowComponent data={data} />

      <Box
        position="absolute"
        top="70px"
        left="0px"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <WeatherIcon weather={data?.WeatherData} />
      </Box>
      {data?.ConfigType !== "deye-dingo-v1" ? (
        <HouseText data={data} />
      ) : <FactoryText data={data} />}

    </Box>
  );
}
export default PowerFlowDiagram;
