import {
  successColor,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-react";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  card: {
    textAlign: "center",
    padding: "0.75em",
    borderRadius: 15,
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  cardHeader: {
    paddingBottom: "0px",
    padding: 0,
  },
  cardStatus: {
    width: "15px",
    height: "15px",
    borderRadius: "100px"
  },
  cardName: {
    fontSize: "1.1em",
    lineHeight: "1.5em",
    fontWeight: "bold",
    color: "white",
    margin: "0px",
    padding: "0px",
    textAlign: "center"
  },
  cardValueContainer: {
    display: "flex",
    flexDirection: "column"
  },
  cardValue: {
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "lighter",
    fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    fontSize: "1.2em",
    marginBottom: "0px",
    whiteSpace: "noWrap",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardValueLabel: {
    fontFamily: "Montserrat",
    fontSize: "1.17em",
    margin: "0px",
    fontWeight: "bold"
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  bigRoundButton: {
    backgroundColor: "#4CAF50",
    border: 0,
    color: whiteColor,
    padding: "20px",
    textAlign: "center",
    textDecoration: "",
    display: "inline-block",
    fontSize: "100px",
    margin: "4px 2px",
    borderRadius: "50%"
  },
  open_button_container: {
    display: "grid",
    justifyContent: "start"
  },
  trading_toggle_container: {
    display: "grid",
    justifyContent: "end"
  },
  trading_label: {
    color: "white",
    "& > span": {
      fontSize: "0.95rem"
    }
  },
  muiToggle: {
    "& .MuiSwitch-track": {
      backgroundColor: "#fbf6f6"
    }
  }
};

export default dashboardStyle;
