import { CARBON_OFFSET_PER_KWH, DAILY_CARBON_OFFSET_PER_TREE } from "../constants/general";
import { EnvironmentalData, FinancialData } from "../types/plant";

export const environmentalImpactAccessor = (d: EnvironmentalData): number => Math.max(
  d.pvWattHours - d.gridImportWattHours, 0);

/*  These accessor functions are helpers to decypher the various
varities of values needed from the daily financial data stuff */
export const incomeAccessor = (d: FinancialData): number =>
  (d.withoutPvCost - d.estimatedCost) + d.energyEarnings;
export const costsAccessor = (d: FinancialData): number => d.estimatedCost;
export const profitAccessor = (d: FinancialData): number =>
  incomeAccessor(d) - costsAccessor(d);

export const electricityPurchasedAccessor = (d: FinancialData): number => d.estimatedCost;
export const selfConsumptionAccessor = (d: FinancialData): number =>
  d.withoutPvCost - d.estimatedCost;
export const energyTradingAccessor = (d: FinancialData): number => d.energyEarnings;
export const cryptoAccessor = (d: FinancialData): number =>
  typeof d.cryptoEarningsAUD === "number" ? d.cryptoEarningsAUD : 0;

export function kwhOffsetFromEnviroData(data: EnvironmentalData): number {
  return (data.pvWattHours - data.gridImportWattHours) / 1000;
}
export function kgOffsetFromEnviroData(data: EnvironmentalData): number {
  return (kwhOffsetFromEnviroData(data) * CARBON_OFFSET_PER_KWH) / 1000;
}
export function numberOfTreesFromEnviroData(data: EnvironmentalData): number {
  return (kwhOffsetFromEnviroData(data) * CARBON_OFFSET_PER_KWH) / DAILY_CARBON_OFFSET_PER_TREE;
}