import { request } from ".";
import { INotificationPermissions, INotificationPermissionUpdate } from "../types/notification";
import { IFetchNotificationsResponse, RequestResult } from "../types/server";

export const fetchNotifications = async ():
  RequestResult<IFetchNotificationsResponse> =>
  request("v2/notifications", "GET");

export const fetchNotificationPermissions = async ():
  RequestResult<INotificationPermissions> =>
  request("v3/users/{userId}/notifications", "GET");

export const putUpdateNotificationPermissions = async (
  body: INotificationPermissionUpdate
): RequestResult<INotificationPermissions> =>
  request("v3/users/{userId}/notifications", "PUT", body);
