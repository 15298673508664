import firebase from "firebase";

export type User = firebase.User;

export function getUser() {
  return firebase.auth().currentUser;
}

/**
 *
 * Refresh the current user.
 *
 * @returns True if user successfully refreshed.
 * Null if failed to reload.
 */
export async function reloadUser() {
  try {
    await firebase.auth().currentUser?.reload();
    return true;
  } catch (error) {
    // fail silently
    return null;
  }
}