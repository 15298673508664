import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Collapse,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import Loading from "components/Loading/Loading";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IPlant, IMinifiedPlant } from "apps-middleware/types/plant";
import { useNavigate } from "react-router-dom";
import "./DeviceTableManagerStyles.css"; // Import external stylesheet

interface Props {
  plants?: (IPlant | IMinifiedPlant)[];
}

const isIPlant = (plant: IPlant | IMinifiedPlant): plant is IPlant =>
  "plantId" in plant;

const formatSOC = (soc?: number): string => {
  if (soc !== undefined) {
    return soc.toFixed(0) + "%";
  }
  return "-";
};

const CollapsibleRow: React.FC<{ plant: IPlant }> = ({ plant }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleCollapse = () => setOpen(!open);

  const selectCard = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/device/${plant.plantId}`);
  };

  const renderTableSection = (sectionTitle: string, dataPairs: [string, any][]): JSX.Element => {
    return (
      <TableContainer component={Paper} elevation={3} style={{ marginTop: "1rem" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" gutterBottom>
                  {sectionTitle}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPairs.map(([key, value]) => (
              <TableRow key={key}>
                <TableCell><strong>{key}</strong></TableCell>
                <TableCell>
                  {Array.isArray(value) ? ( // FIX THIS FOR MPPTS
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Barcode</TableCell>
                          <TableCell>Battery Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.map((battery, index) => (
                          <TableRow key={index}>
                            <TableCell>{battery.barcode}</TableCell>
                            <TableCell>{battery.batteryType}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    typeof value === "object" ? JSON.stringify(value, null, 2) : value
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const configDetailsEntries = plant.configurationDetails
    ? Object.entries(plant.configurationDetails) : [];

  return (
    <>
      <TableRow key={plant.plantId}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleCollapse}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {plant.plantName}
        </TableCell>
        <TableCell>{plant.status ?? "-"}</TableCell>
        <TableCell>{formatSOC(plant.currentPlantData?.batterySoc ?? undefined)}</TableCell>
        <TableCell>{plant.online ? "Yes" : "No"}</TableCell>
        <TableCell>{plant.alerts?.length ?? 0}</TableCell>
        <TableCell>
          <Button
            variant="outlined"
            onClick={selectCard}
            size="small"
            color="primary"
          >
            Select Plant
          </Button>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {renderTableSection("General Plant Information", [
                      ["Physical Plant ID", plant.physicalPlantId],
                      ["Plant ID", plant.plantId],
                      ["Timezone", plant.timezone],
                      ["QR ID", plant.qrId],
                      ["Plant Provider", plant.plantProviderName],
                      ["Batteries", plant.batteries],
                      ["Warranty Name", plant.warrantyName],
                    ])}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {renderTableSection("Pricing / Trading Information", [
                      ["Trading Allowed", plant.tradingAllowed ? "Yes" : "No"],
                      ["Trading Enabled", plant.tradingEnabled ? "Yes" : "No"],
                      ["Trading Forbidden", plant.tradingForbidden ? "Yes" : "No"],
                      ["Tariff Type", plant.tariffType],
                      ["Pricing Configured", plant.isPricingConfigured ? "Yes" : "No"],
                    ])}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {renderTableSection("Plant Status", [
                      ["Online", plant.online ? "Yes" : "No"],
                      ["Alerts", plant.alerts?.length ?? 0],
                    ])}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {renderTableSection("PPP Subscriptions", [
                      ["Is VIP", plant.isVip ? "Yes" : "No"],
                      ["Has Optimum", plant.isOptimus ? "Yes" : "No"],
                      ["Has Load Control", plant.hasLoadControl ? "Yes" : "No"],
                      ["Has Smart EV Charger", plant.hasSmartEvCharger ? "Yes" : "No"],
                      ["Has Power Ranger", plant.hasPowerRanger ? "Yes" : "No"],
                    ])}
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const DeviceTableManager: React.FC<Props> = ({ plants }) => {
  if (!plants) {
    return (
      <div style={{ marginTop: "100px" }}>
        <Loading size={150} />
      </div>
    );
  }

  return (
    <Paper style={{ marginTop: "-20px" }}>
      <TableContainer className="tableContainer">
        <Table stickyHeader>
          <TableHead>
            <TableRow className="stickyHeader">
              <TableCell>Plant Name</TableCell>
              <TableCell>Plant Status</TableCell>
              <TableCell>Battery SOC</TableCell>
              <TableCell>Online</TableCell>
              <TableCell>Alerts</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plants
              .filter((plant) => isIPlant(plant))
              .map((plant) => (
                <CollapsibleRow
                  key={plant.plantId}
                  plant={plant as IPlant}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DeviceTableManager;
