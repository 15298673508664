import { Divider, Grid, Typography } from "@mui/material";
import { tradingOrderToSemanticName } from "apps-middleware/constants/trading";
import { arraysIntersection } from "apps-middleware/util/general";
import { LocalDateTimeString } from "apps-middleware/util/time";
import { extractReason, orderToLaymanTerminology } from "apps-middleware/util/trading";
import {
  dataKeyToColour,
  dataKeyToSemanticName,
  dataKeyToUnit,
  powerProfileDataKeys,
  priceProfileDataKeys
} from "constants/graph";
import { DateTime } from "luxon";
import React from "react";
import { ITimeScaledGraphData, PotentialDataKeys } from "types/graph";
import styles from "./charttooltip.module.scss";
import { palette } from "apps-middleware/constants/palette";

export interface IChartTooltipProps {
  active?: boolean; //provided by the recharts tooltip component
  label?: number; //provided by the recharts tooltip component
  fullData: Array<ITimeScaledGraphData>,
  dataKeysToConsider?: Array<PotentialDataKeys>,
}

const ChartTooltip = ({
  active,
  label,
  fullData,
  dataKeysToConsider,
}: IChartTooltipProps): JSX.Element | null => {
  if (active === false || !label || !fullData) return null;
  const currentRow: ITimeScaledGraphData | undefined = fullData
    .find((row) => row.unixTimestamp === label);
  if (!currentRow) return null;

  const shouldRender = (gv: PotentialDataKeys[]): boolean => {
    if (!dataKeysToConsider) return true;
    return gv.filter((v) => dataKeysToConsider.includes(v)).length > 0;
  };

  const Group = ({ title, dataKeys }:
    {
      title: string,
      dataKeys: PotentialDataKeys[]
    }): JSX.Element | null =>
    shouldRender(dataKeys) ? (
      <Grid container item flexDirection="column" xs="auto">
        <Typography sx={{ fontSize: "0.8em", fontWeight: "bold" }}>{title}</Typography>
        {
          dataKeys.map((dk, i) => dataKeysToConsider?.includes(dk)
            ? (
              <DataEntry
                dataKey={dk}
                val={(!currentRow || currentRow[dk] === undefined)
                  ? null : currentRow[dk] as number | null}
                key={i} />
            ) : null)
        }
      </Grid>
    )
      : null
    ;

  const powerDataKeys: Array<PotentialDataKeys> = dataKeysToConsider ? ([
    ...arraysIntersection<PotentialDataKeys>(["gridPower", "generatorPower"], dataKeysToConsider),
    "batteryPower", "loadPower", "pvPower", "evPower", "pvFromDC", "pvFromAC"
  ]) : powerProfileDataKeys;

  const history = currentRow.mostRecentCommand;
  const orderSuccess = history !== undefined && (
    history.completedSuccessfully ||
    (!history.completedSuccessfully && history.success === undefined)
  );

  return <Grid container flexDirection={"column"} gap={1}
    style={{
      backgroundColor: "#f8f8f8",
      color: "black",
      padding: 10,
      position: "relative",
      zIndex: 1000,
    }}>
    <Typography sx={{
      fontSize: "1em",
      fontWeight: "bold",
    }}>
      {DateTime.fromMillis(label).toLocaleString(DateTime.DATETIME_MED)}
    </Typography>
    <Divider />
    <Grid
      container
      flexDirection="row"
      flexWrap="nowrap"
      gap={2}
      justifyContent={"space-between"}>
      <Group
        title={"Energy Profile"}
        dataKeys={powerDataKeys}
      />
      <Group
        title={"Price Data"}
        dataKeys={priceProfileDataKeys}
      />
      <Group
        title="Load Profile"
        dataKeys={["loadVoltage", "loadCurrent"]}
      />
      <Group
        title="Battery Profile"
        dataKeys={["batteryVoltage", "batteryCurrent",
          "reportedBatterySoc", "calculatedBatterySoc"]}
      />
      <Group
        title="Generator Profile"
        dataKeys={["generatorVoltage", "generatorCurrent", "generatorFrequency"]}
      />
    </Grid>
    {
      history &&
      <Grid container flexDirection={"column"} gap={0.5}>
        <Typography sx={{
          fontWeight: "bold",
          fontSize: "0.8em",
        }}>
          Algonese Recent Activity
        </Typography>
        <Grid
          sx={{
            maxWidth: 400
          }}
          container
          flexDirection={"row"}
          alignContent="center"
          alignItems={"center"}>
          <div style={{
            width: 10,
            height: 5,
            backgroundColor: palette.neutral.B2,
            marginRight: 5,
          }}></div>
          <Typography sx={{
            fontSize: "0.8em",
            wordWrap: "break-word",
            whiteSpace: "break-spaces",
            width: "100%",
            flex: 1,
            lineHeight: "1.75em"
          }}>
            Order
            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              {tradingOrderToSemanticName[history.trade.orderDirection]}
            </span>
            &nbsp;
            sent at
            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              {LocalDateTimeString(DateTime.fromMillis(history.timestamp))}
            </span>
            &nbsp;
            {
              orderSuccess ? "succeeded" : "failed"
            }
            .
            <br />
            {
              orderToLaymanTerminology(history.trade.orderDirection, orderSuccess)
            }
            <br />Reason:
            &nbsp;
            {
              history.success &&
              extractReason(history.success)
            }
          </Typography>
        </Grid>
      </Grid>
    }
  </Grid>;
};

interface IDataEntryProps {
  dataKey: PotentialDataKeys,
  val: number | null,
}
const DataEntry = ({ dataKey, val }: IDataEntryProps) => (
  <div className={styles.row}>
    <div
      className={styles.lineColourIndicator}
      style={{ backgroundColor: dataKeyToColour[dataKey] }}></div>
    <Typography style={{ fontSize: "0.8em" }}>
      {dataKeyToSemanticName[dataKey]}:&nbsp;
      {val !== null ? val + dataKeyToUnit[dataKey] : "--"}
    </Typography>
  </div>
);

export default ChartTooltip;
