/* The home screen of the redpi maintenance page */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Typography, TextField } from "@mui/material";
import { FilterList, ListAlt, Refresh } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import LuxonAdapter from "@date-io/luxon";

import styles from "./redpi.module.scss";
import { RedPiObject } from "apps-middleware/types/redpi";
import Loading from "components/Loading/Loading";
import LayoverContainer from "components/LayoverContainer/LayoverContainer";
import { fetchRedPiDevicesThunk } from "apps-middleware/redux/asyncThunks";
import { useAppDispatch } from "apps-middleware/redux/store/hooks";
import { RedPiDeviceThunkResponse } from "apps-middleware/types/redux";
import DeviceCard from "components/RedPiDeviceCard/RedPiDeviceCard";
import {
  DropdownChecklist,
  Option
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { IDateRange } from "apps-middleware/types/time";
import { TIME_RANGES } from "apps-middleware/util/time";
import { DateTime } from "luxon";
import { OnEnterSearchBar } from "components/OnEnterSearchBar";
import { getConfigurationTypeUserFriendlyName } from "apps-middleware/util/plant";

type SearchFilter = "ggid" | "Name" | "View ID" | "Plant ID" | "Chassis ID" | "Product Type" |
  "Inverter Serial Number" | "Red Pi Serial Number" | "RUT Name";
const searchFilters: Array<SearchFilter> = [
  "ggid", "Name", "View ID", "Plant ID", "Chassis ID", "Product Type", "Inverter Serial Number",
  "Red Pi Serial Number", "RUT Name"
];
const searchFilterOptions: Array<Option> = searchFilters.map((filter) => ({
  name: filter as SearchFilter,
  value: filter as SearchFilter
}));

let isMounted = false;
const DEFAULT_SEARCH_FILTERS: Array<SearchFilter> = searchFilters;
const DEFAULT_SEARCH = "";

export default function Redpi(): JSX.Element {
  const dispatch = useAppDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [loadedDevices, setLoadedDevices] = useState<RedPiObject[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<RedPiObject[]>([]);
  const [searchInputValue, setSearchInputValue] = useState(DEFAULT_SEARCH);
  const [searchFilterValue, setSearchFilterValue] = useState(DEFAULT_SEARCH);
  const [searchFilterState, setSearchFilterState] = useState(DEFAULT_SEARCH_FILTERS);
  const [hasPermission, setHasPermission] = useState(true);
  const [healthyFilter, setHealthyFilter] = useState("");
  const [registeredFilter, setRegisteredFilter] = useState<undefined | boolean>(undefined);
  const [dateBounds, setDateBounds] = useState<IDateRange>(TIME_RANGES.YEARLY.range);
  const [initialNow, setInitialNow] = useState(DateTime.now().toMillis());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thunk = useRef<any>();

  function checkFiltersAreInDefaultState(): boolean {
    return (
      searchInputValue === "" &&
      searchFilterState === DEFAULT_SEARCH_FILTERS &&
      healthyFilter === "" &&
      registeredFilter === undefined &&
      dateBounds.startDateTime === dateBounds.startDateTime &&
      dateBounds.endDateTime.toMillis() === initialNow
    );
  }

  function resetFilter() {
    setSearchInputValue(DEFAULT_SEARCH);
    setSearchFilterValue(DEFAULT_SEARCH);
    setHealthyFilter("");
    setRegisteredFilter(undefined);
    setSearchFilterState(DEFAULT_SEARCH_FILTERS);
    setDateBounds(TIME_RANGES.YEARLY.range);
    setInitialNow(DateTime.now().toMillis());
  }

  // Filter the body content
  function filterContent(
    searchValue: string,
    searchFilterState: SearchFilter[],
    healthyFilter: string,
    registeredFilter: undefined | boolean,
    dateBounds: IDateRange
  ) {
    const devices = loadedDevices.filter((device: RedPiObject) => {
      const configurationType = getConfigurationTypeUserFriendlyName(device.configurationType);
      const lastReported = DateTime.fromISO(device.statusReported);
      return (
        // Search bar filter
        searchFilterState.length === 0 ||
        searchFilterState.includes("ggid") && device.ggid.indexOf(searchValue) > -1 ||
        searchFilterState.includes("Name") &&
          device.name && device.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        searchFilterState.includes("View ID") &&
          device.defaultView && device.defaultView.indexOf(searchValue) > -1 ||
        searchFilterState.includes("Plant ID") &&
          device.plantId && device.plantId.indexOf(searchValue) > -1 ||
        searchFilterState.includes("Chassis ID") &&
          device.chassisId && device.chassisId === Number.parseInt(searchValue) ||
        searchFilterState.includes("Product Type") &&
          (device.configurationType === searchValue || configurationType === searchValue) ||
        searchFilterState.includes("Inverter Serial Number") &&
          (typeof device.inverterSerialNumbers === "string" && // Single inverter product
            device.inverterSerialNumbers.indexOf(searchValue) > -1) ||
        searchFilterState.includes("Inverter Serial Number") &&
            (Array.isArray(device.inverterSerialNumbers) && // Multiple inverter product
              device.inverterSerialNumbers.includes(searchValue)) ||
        searchFilterState.includes("Red Pi Serial Number") &&
          device.redPiSerialNumber && device.redPiSerialNumber.indexOf(searchValue) > -1 ||
        searchFilterState.includes("RUT Name") &&
          device.rutId && device.rutId.indexOf(searchValue) > -1
      ) &&
      // Healthy status filter
      (!healthyFilter || device.status === healthyFilter) &&
      // Registered filter
      (registeredFilter === undefined || device.isRegistered === Boolean(registeredFilter)) &&
      // Time filter
      (lastReported > dateBounds.startDateTime && lastReported <= dateBounds.endDateTime);
    });
    setFilteredDevices(devices);
  }

  function handleSearchValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchInputValue(event.target.value);
  }

  async function fetchDevices() {
    setIsFetching(true);
    if (thunk.current) {
      thunk.current.abort();
    }

    thunk.current = dispatch(fetchRedPiDevicesThunk({
      // TODO: timezone
      // TODO: postcode
      // TODO: configurationType
      // TODO: isVip
      // TODO: isOptimus
      // TODO: cryptoEnabled
      // TODO: tradingEnabled
      // TODO: isRegistered
      // TODO: isHealthy
    }));

    thunk.current?.unwrap().then((response: RedPiDeviceThunkResponse) => {
      if (!isMounted) return;
      setHasPermission(true);
      setLoadedDevices(response);
    })
      .catch((statusCode: number) => {
        if (statusCode === 403) {
          setHasPermission(false);
        }
      })
      .finally(() => {
        if (!isMounted) return;
        setFilteredDevices(loadedDevices);
        filterContent(
          searchInputValue, searchFilterState, healthyFilter, registeredFilter, dateBounds
        );
        setIsFetching(false);
        thunk.current = undefined;
      });
  }

  const setSearchFilterValueToSearchInputValue = useCallback(() => {
    setSearchFilterValue(searchInputValue);
  }, [searchInputValue]);

  useEffect(() => {
    fetchDevices();
  }, [searchFilterValue, searchFilterState, healthyFilter, registeredFilter, dateBounds]);

  useEffect(function onload() {
    isMounted = true;
    return function onUnload() {
      isMounted = false;
    };
  }, []);

  // Set the body content
  let bodyContent: JSX.Element[] = [];
  if (loadedDevices.length > 0 && filteredDevices.length === 0 && checkFiltersAreInDefaultState()) {
    // Make sure we populate filter devices on first load.
    filterContent(searchInputValue, searchFilterState, healthyFilter, registeredFilter, dateBounds);
  }
  if (loadedDevices.length > 0) {
    filteredDevices.forEach((device) => {
      bodyContent.push(<DeviceCard key={device.ggid} device={device}/>);
    });
  } else {
    if (!isFetching) {
      bodyContent = [<p key="error">Something went wrong</p>];
    }
  }

  if (!hasPermission) {
    return (
      <div className={styles.container}>
        <LayoverContainer>
          <>
            <Typography variant="h1">403</Typography>
            <div style={{ height: 10 }}></div>
            <Typography>You do not have permission to use this page</Typography>
          </>
        </LayoverContainer>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {/** Search Bar */}
      <div className={styles.searchBar}>
        <OnEnterSearchBar
          subject="Devices"
          inputValue={searchInputValue}
          appliedValue={searchFilterValue}
          onChange={handleSearchValueChange}
          onEnterKeyPress={setSearchFilterValueToSearchInputValue}
        />
        {/* <TextField
          label="Search Devices"
          variant="outlined"
          size="small"
          value={searchInputValue}
          onChange={handleSearchValueChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") setSearchFilterValueToSearchInputValue();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={setSearchFilterValueToSearchInputValue}>
                  <Search />
                </IconButton>
              </InputAdornment>
            )
          }}
        /> */}
        <DropdownChecklist
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.5,
          }}
          defaultSelected={DEFAULT_SEARCH_FILTERS}
          controlledSelected={searchFilterState.map((f) => ({ "name": f, "value": f }))}
          items={searchFilterOptions}
          onSelectionChange={(items) => {
            const val = items.map((o) => o.value as SearchFilter);
            setSearchFilterState(val);
          }}
          labelText={"Search Filters"}
          icon={<ListAlt />}
        />
        <DropdownChecklist
          isRadio
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.5,
          }}
          items={[
            {
              name: "Registered",
              value: "true",
            }, {
              name: "Not Registered",
              value: "false",
            }]}
          defaultSelected={[]}
          onSelectionChange={(items: Option[]) => {
            const val = items[0] ? items[0].value === "true" : undefined;
            setRegisteredFilter(val !== registeredFilter ? val : undefined);
          }}
          labelText="Filter By Registered"
          icon={<FilterList/>}
        />
        <DropdownChecklist
          isRadio
          popUpSize={{
            width: window.innerWidth * 0.25,
            height: window.innerHeight * 0.5,
          }}
          items={[
            {
              name: "HEALTHY",
              value: "HEALTHY",
            }, {
              name: "UNHEALTHY",
              value: "UNHEALTHY",
            }]}
          defaultSelected={[]}
          onSelectionChange={(items: Option[]) => {
            const val = items[0] ? items[0].value : "";
            setHealthyFilter(val);
          }}
          labelText="Filter By Status"
          icon={<FilterList/>}
        />
        <LocalizationProvider dateAdapter={LuxonAdapter}>
          <DateTimePicker
            label="Start Date Time"
            value={dateBounds.startDateTime}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy tt"
            onChange={(val) => {
              if (!val) return;
              setDateBounds({
                ...dateBounds,
                startDateTime: val,
              });
            }}
            maxDate={dateBounds.endDateTime}
          />
          <DateTimePicker
            label="End Date Time"
            value={dateBounds.endDateTime}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy tt"
            onChange={(val) => {
              if (!val) return;
              setDateBounds({
                ...dateBounds,
                endDateTime: val,
              });
            }}
            minDate={dateBounds.startDateTime}
            maxDate={DateTime.now()}
          />
        </LocalizationProvider>
        <Button
          onClick={async () => {
            await fetchDevices();
          }}
          variant="outlined"
          color="primary"
          size="small"
          style={{position: "absolute", right: "3em"}} // Fix to the right of the search bar
          className={styles.refreshButton}
        >
          <Refresh/> &nbsp; Refresh
        </Button>
      </div>

      {/** Device List */}
      <div className={styles.redPiDeviceList}>
        {/** Loading Screen */}
        {
          isFetching && <LayoverContainer>
            <Loading informationText={"Fetching Device List..."} />
          </LayoverContainer>
        }
        {/** No Results Found Screen */}
        {
          !isFetching &&
          filteredDevices.length === 0 &&
          !checkFiltersAreInDefaultState() &&
          <LayoverContainer>
            <>
              <Typography>No devices matching these filters.</Typography>
              <div style={{ height: 10 }}></div>
              <Button
                variant="outlined"
                startIcon={<Refresh />}
                onClick={resetFilter}>
                Reset Filters
              </Button>
            </>
          </LayoverContainer>
        }
        {/** Devices Found Screen */}
        {
          <ul role="list" className={styles.deviceCardGrid}>
            {bodyContent}
          </ul>
        }
      </div>
    </div>
  );
}
