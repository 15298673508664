import { DateTime } from "luxon";
import { ScheduleDayOptions, SchedulePlantCommandEvent } from "../types/schedules";

export const scheduleTimeToSortableNumber = (time:string) => {
  return parseInt(time.split(":")[0]) + (parseInt(time.split(":")[1])/60);
};

export const scheduleDayToSortableNumber = (day: ScheduleDayOptions) => {
  const dayToNumber: Record<ScheduleDayOptions, number> = {
    "daily":8,
    "monday":7,
    "tuesday":6,
    "wednesday":5,
    "thursday":4,
    "friday":3,
    "saturday":2,
    "sunday":1
  };
  return dayToNumber[day];
};

export const scheduleTimeStringToDateTime = (time:string): DateTime => {
  return DateTime.fromFormat(time, "HH:mm");
};

//get today as a value appropriate for the day prop on schedules
export const getTodayAsDayProp = (): ScheduleDayOptions =>
  DateTime.now().weekdayLong.toLowerCase() as ScheduleDayOptions;

/*
Thank you Rowan for sharing this function with me
 */
export function scheduleCollisionExists(
  proposedEvent: SchedulePlantCommandEvent,
  existingEvent: SchedulePlantCommandEvent
): boolean {
  // dont compare these schedules if they are the same schedule
  // or if the existing schedule is considered deleted
  if(
    proposedEvent.id === existingEvent.id ||
    shouldScheduleBeConsideredDeleted(existingEvent)
  ) return false;

  // If the days are not the same and neither are daily, we don't need to check anything.
  if (
    proposedEvent.day !== existingEvent.day &&
    proposedEvent.day !== "daily" &&
    existingEvent.day !== "daily"
  ) {
    return false;
  }
  // Also we assume no conflict if either is not enabled.
  if (proposedEvent.enabled === false || existingEvent.enabled === false) {
    return false;
  }
  const proposedStart = DateTime.fromFormat(proposedEvent.start, "HH:mm");
  const proposedEnd = DateTime.fromFormat(proposedEvent.end, "HH:mm");
  const existingStart = DateTime.fromFormat(existingEvent.start, "HH:mm");
  const existingEnd = DateTime.fromFormat(existingEvent.end, "HH:mm");
  // Case 1: Proposed event starts while existing event is ongoing.
  // existing:    |----------------|
  // proposed:                |------------------|
  //                          ^
  if (existingStart <= proposedStart && proposedStart < existingEnd) {
    return true;
  }
  // Case 2: Proposed event ends while existing event is ongoing.
  // existing:                    |----------------|
  // proposed:       |------------------|
  //                                    ^
  if (existingStart < proposedEnd && proposedEnd <= existingEnd) {
    return true;
  }
  // Case 3: Proposed event surrounds existing event.
  // existing:               |--------|
  // proposed:        |----------------------|
  //                         ^        ^
  if (proposedStart <= existingStart && existingEnd <= proposedEnd) {
    return true;
  }
  return false;
}

export function isScheduleRecurring(schedule: SchedulePlantCommandEvent): boolean {
  return schedule.day === "daily";
}

export function isScheduleExpired(schedule: SchedulePlantCommandEvent): boolean {
  return (
    schedule.expiryDate !== undefined &&
    DateTime.fromMillis(schedule.expiryDate) < DateTime.now()
  );
}

/**
 *
 * A schedule is considered deleted if it is in the past. So if it will not
 * impact the algorithm in any way going forward.
 *
 * @param schedule
 * @returns if the schedule should be considered deleted
 */
export function shouldScheduleBeConsideredDeleted(schedule: SchedulePlantCommandEvent): boolean {
  // if its recurring just let it go
  if(isScheduleRecurring(schedule)) return false;
  // if it not recurring and expired its considered deleted
  if(isScheduleExpired(schedule)) return true;
  // if its for today and time has elapsed but for whatever reason
  // no expiry was avaialble. Also consider it deleted
  if(
    schedule.day === getTodayAsDayProp() &&
    scheduleTimeStringToDateTime(schedule.end) < DateTime.now()
  ) return true;
  // else its not deleted
  return false;
}