import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

interface IWarningTextProps {
  message: string,
  showNetworkWarning?: boolean;
}

export const WarningText = ({ message, showNetworkWarning }: IWarningTextProps): JSX.Element => (
  <Grid container justifyContent="center" alignContent="center">
    <Grid item xs={12}>
      <Typography
        variant="h5"
        style={{ textAlign: "center", color: "white" }}
      >
        {
          `${message}${showNetworkWarning
            ? ((message.endsWith(" ") ? "" : " ") + "Please check your network connection")
            : ""}`
        }
      </Typography>
    </Grid>
  </Grid>);