import {
  getSimDataThunk,
  getSimInfoThunk,
  sendSimCommandThunk
} from "../redux/asyncThunks";
import { RootState } from "../redux/store/configureStore";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import { ReduxSimStoreData } from "../types/redux";
import { useEffect, useState } from "react";
import useIsMounted from "./useIsMounted";
import { DateTime } from "luxon";
import { SimCommandRequest, StatsPeriod } from "../types/sim";
import { simpleStringifyCompare } from "../util/general";

export const useSim = (simId: string) => {
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isDataFetching, setIsDataLoading] = useState(false);
  const [isCommandLoading, setIsCommandLoading] = useState(false);

  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  const sim: ReduxSimStoreData = useAppSelector((state: RootState) => {
    return state.appData.sims.data[simId] ?? {};
  }, simpleStringifyCompare);

  async function fetchInfo() {
    setIsInfoLoading(true);

    return dispatch(getSimInfoThunk({ simId })).finally(() => {
      if (isMounted()) {
        setIsInfoLoading(false);
      }
    });
  }

  async function fetchData(from: DateTime, to: DateTime, period: StatsPeriod, imsi?: string) {
    setIsDataLoading(true);

    return dispatch(getSimDataThunk({ simId, from, to, period, imsi })).finally(() => {
      if (isMounted()) {
        setIsInfoLoading(false);
      }
    });
  }

  async function toggleActiveStatus() {
    const simId = sim?.info?.simId;
    const status = sim?.info?.status;

    if (!simId || !status) {
      return;
    }

    setIsCommandLoading(true);

    const command: SimCommandRequest = {
      active: status !== "active"
    };

    dispatch(sendSimCommandThunk({ simId, command })).finally(() => {
      if (isMounted()) {
        setIsCommandLoading(false);
      }
    });
  }

  useEffect(function onLoad() {
    fetchInfo();
  }, []);

  return {
    ...sim,
    getInfo: {
      isLoading: isInfoLoading,
      fetch: fetchInfo,
    },
    getData: {
      isLoading: isDataFetching,
      fetch: fetchData,
    },
    command: {
      isLoading: isCommandLoading,
      toggleActiveStatus,
    }
  };
};
