import { Button, Grid, TableCell, TableRow, Typography, styled } from "@mui/material";
import { palette } from "apps-middleware/constants/palette";
import { useSim } from "apps-middleware/hooks/useSim";
import { StatsPeriod } from "apps-middleware/types/sim";
import { SimpleLineChart, SimpleLineData } from "components/Charts/SimpleLineChart";
import Loading from "components/Loading/Loading";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Line } from "recharts";
import { capitaliseFirstLetter } from "util/general";

const StyledTableCell = styled(TableCell)({
  border: `solid 1.5px ${"white"}`,
  whiteSpace: "pre"
});

type MonthlyData = {
  // Download
  lastMonthDownloadMB?: number;
  thisMonthDownloadMB?: number;
  // Upload
  lastMonthUploadMB?: number;
  thisMonthUploadMB?: number;
};

export const SimDetails = ({ simId }: { simId: string }): JSX.Element => {
  const [chartData, setChartData] = useState<SimpleLineData[] | undefined>(undefined);
  const [monthlyData, setMonthlyData] = useState<MonthlyData>({});

  const sim = useSim(simId);

  function getMonthlyData() {
    const from = DateTime.now().minus({ month: 1 });
    const to = DateTime.now();
    const period: StatsPeriod = "month";

    sim.getData.fetch(from, to, period);
  }

  function getDailyData() {
    const from = DateTime.now().startOf("month");
    const to = DateTime.now().endOf("month");
    const period: StatsPeriod = "day";

    sim.getData.fetch(from, to, period);
  }

  function toggleActiveStatus() {
    sim.command.toggleActiveStatus();
  }

  useEffect(function onLoad() {
    getMonthlyData();
    getDailyData();
  }, []);

  useEffect(function onMonthlyDataChanged() {
    if (!sim.data?.month || sim.data.month.length === 0) {
      return;
    }

    const yearMonthFormat = "yyyyLL"; // e.g. 202305
    const monthlyData: MonthlyData = {};
    const lastMonthDate = DateTime.now()
      .minus({ month: 1 })
      .toFormat(yearMonthFormat);
    const thisMonthDate = DateTime.now().toFormat(yearMonthFormat);

    // The way monthly data is fetched in this component means we will only see 1-3 records
    // at most so I feel given the small array size the use of `.find` is ok here
    const lastMonthData = sim.data?.month.find((data) => data.date === lastMonthDate);
    const thisMonthData = sim.data?.month.find((data) => data.date === thisMonthDate);

    if (lastMonthData) {
      const dlBytes = Object.values(lastMonthData.dataTrafficStatsMap)[0].downloadByteSizeTotal;
      const ulBytes = Object.values(lastMonthData.dataTrafficStatsMap)[0].uploadByteSizeTotal;
      monthlyData.lastMonthDownloadMB = dlBytes / 1000000;
      monthlyData.lastMonthUploadMB = ulBytes / 1000000;
    }

    if (thisMonthData) {
      const dlBytes = Object.values(thisMonthData.dataTrafficStatsMap)[0].downloadByteSizeTotal;
      const ulBytes = Object.values(thisMonthData.dataTrafficStatsMap)[0].uploadByteSizeTotal;
      monthlyData.thisMonthDownloadMB = dlBytes / 1000000;
      monthlyData.thisMonthUploadMB = ulBytes / 1000000;
    }

    setMonthlyData(monthlyData);
  }, [sim.data?.month]);

  useEffect(function onDailyDataChanged() {
    if (!sim.data?.day || sim.data.day.length === 0) {
      return;
    }

    const newChartData: SimpleLineData[] = [];
    const startOfMonthMs = DateTime.now()
      .startOf("month")
      .toMillis();

    sim.data.day.forEach((stats) => {
      const unixTimeMs = stats.unixtime * 1000;

      // For some reason the API returns one entry from the previous month even though it shouldn't
      // because of the start date we provide. Just ensure each entry is within our expected range
      if (unixTimeMs > startOfMonthMs) {
        const dlBytes = Object.values(stats.dataTrafficStatsMap)[0].downloadByteSizeTotal;
        const dlMb = dlBytes / 1000000;
        const ulBytes = Object.values(stats.dataTrafficStatsMap)[0].uploadByteSizeTotal;
        const ulMb = ulBytes / 1000000;

        newChartData.push({
          x: stats.unixtime * 1000,
          dl: Number(dlMb.toFixed(2)),
          ul: Number(ulMb.toFixed(2)),
        });
      }
    });

    setChartData(newChartData);
  }, [sim.data?.day]);

  const statusColour = sim.info?.status === "active"
    ? palette.secondary.green.primary
    : palette.primary.white;

  const commonLineProps: object = {
    type: "monotone",
    strokeWidth: 1.5,
    dot: false,
  };

  return (
    sim.info // If sim api endpoint gets a 403, then info will not exist.
      ? <Grid container item xs={10}
        justifySelf="center"
        alignSelf={"center"}
        flexDirection="column"
        justifyContent="center">
        <Typography variant="h5" align="center" fontStyle={{ lineHeight: "unset" }}>
        Sim Details
        </Typography>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Name</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Typography variant={"body1"}>{ sim.info?.tags?.name ?? "-" }</Typography>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>SIM / IMEI</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Typography variant={"body1"}>{ simId }</Typography>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Status</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Grid item container style={{ flex: 1, flexDirection: "row" }}>
              <Typography variant={"body1"} style={{ color: statusColour }} >
                {
                  sim.info?.status ? capitaliseFirstLetter(sim.info.status) : "No data"
                }
              </Typography>
              {
                sim.info?.status &&
                  <Button
                    onClick={toggleActiveStatus}
                    variant="contained"
                    color={sim.info?.status === "active" ? "primary": "success"}
                    disabled={sim.command.isLoading}
                    size="small"
                    style={{ marginLeft: 20 }}
                  >
                    { sim.info?.status === "active" ? "Deactivate" : "Activate" }
                  </Button>
              }
              {
                sim.command.isLoading &&
                <Grid style={{ marginLeft: 10 }}>
                  <Loading size={24} />
                </Grid>
              }
            </Grid>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Last Modified</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Typography variant={"body1"} >
              {
                sim.info?.lastModifiedTime
                  ? DateTime.fromMillis(sim.info.lastModifiedTime).toFormat("f")
                  : "-"
              }
            </Typography>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Usage Last Month</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Typography variant={"body1"}>
              {
                monthlyData.lastMonthDownloadMB !== undefined
                  ? monthlyData.lastMonthDownloadMB.toFixed(2) + " MB"
                  : "-"
              }
              {" (Download)"}
            </Typography>
            <Typography variant={"body1"}>
              {
                monthlyData.lastMonthUploadMB !== undefined
                  ? monthlyData.lastMonthUploadMB.toFixed(2) + " MB"
                  : "-"
              }
              {" (Upload)"}
            </Typography>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Usage This Month</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <Typography variant={"body1"}>
              {
                monthlyData.thisMonthDownloadMB !== undefined
                  ? monthlyData.thisMonthDownloadMB.toFixed(2) + " MB"
                  : "-"
              }
              {" (Download)"}
            </Typography>
            <Typography variant={"body1"}>
              {
                monthlyData.thisMonthUploadMB !== undefined
                  ? monthlyData.thisMonthUploadMB.toFixed(2) + " MB"
                  : "-"
              }
              {" (Upload)"}
            </Typography>
          </StyledTableCell>
        </TableRow>

        <TableRow style={{ width: "100%", flexDirection: "row", display: "table" }}>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            <Typography variant={"h6"}>Data Usage Graph</Typography>
            <Typography variant={"body1"}>{ DateTime.now().toFormat("LLLL yyyy") }</Typography>
          </StyledTableCell>
          <StyledTableCell style={{ width: "80%" }}>
            <SimpleLineChart
              data={chartData}
              xAxis={{
                tickFormatter: (value) => DateTime.fromMillis(value).toFormat("d/L")
              }}
              yAxis={{ unit: " MB" }}
              tooltip={{
                formatter: (value) => `${value} MB`,
                labelFormatter: (value) => DateTime.fromMillis(value).toFormat("DDD")
              }}
            >
              <Line {...commonLineProps} stroke="#875cff" name="Downloads" dataKey="dl" />
              <Line {...commonLineProps} stroke="#82ca9d" name="Uploads" dataKey="ul" />
            </SimpleLineChart>
          </StyledTableCell>
        </TableRow>
      </Grid>
      : <></>
  );
};
