import { DateTime } from "luxon";
import { CustomAppState } from "./general";
import {
  EnvironmentalData,
  FinancialData,
  IMinifiedPlant,
  IPlant,
  IPlantData,
  IPossiblePlant,
  PhysicalPlantId,
  PlantId
} from "./plant";
import {
  DailyIntervalResponse,
  IPaginationResponse,
  IPlantGetPricesV2Params,
  IPlantGetPricesV2Response,
  IPriceDataV2,
  IUserPersonalDetails,
  MonthlyIntervalResponse,
  YearlyIntervalResponse
} from "./server";
import { INotification, INotificationPermissions } from "./notification";
import { ChallengeID, GenericChallenge } from "./challenges";
import { PlantTradingStatus, TradingHistory } from "./trading";
import { PurchaseInfo, ProductId } from "./../types/products";
import { AccessoryId, PlantAccessory, ServerAccessoryData } from "./accessory";
import { RedPiObject, RedPiCommand, RedPiLog, RedPiCommandResponse } from "./redpi";
import { SoracomAirStats, SoracomSim } from "./sim";
import { SchedulePlantCommandEvent } from "./schedules";
export interface IUIFriendlyDataFormat {
  value?: number | boolean;
  unit: string;
  label: string;
}

export type IPriceDataThunkParams = IPlantGetPricesV2Params;

export type UnixTimeStamp = number;
export type ISOTimestamp = string;
export type UTCDateFormat = string;
export type PowerIntervalType = 5 | 15 | 30 | 60;
export type PriceIntervalType = 5 | 30

export type PlantIntervalPowerData = Record<UnixTimeStamp, IPlantData>;
export type PlantPowerData = Record<PowerIntervalType, PlantIntervalPowerData>;
export type PowerData = Record<PlantId, PlantPowerData>;

export type PlantIntervalPriceData = Record<UnixTimeStamp, IPriceDataV2>;
export type PlantPriceData = Record<PriceIntervalType, PlantIntervalPriceData>;
export type PriceData = Record<PlantId, PlantPriceData>;

export type AggregateData = Record<UnixTimeStamp, IPlantData>;

export interface ICurrentPriceData {
  importPrice: IUIFriendlyDataFormat;
  exportPrice: IUIFriendlyDataFormat;
}

export type IUIFriendlyPowerData = Record<
  keyof Omit<IPlantData, "unixTimestamp">,
  IUIFriendlyDataFormat
>;

export interface IUIFriendlyPriceData {
  importPrice: IUIFriendlyDataFormat,
  exportPrice: IUIFriendlyDataFormat,
}

export interface IUIFriendlyFinancialData {
  date: string,
  ELECTRICITY_PURCHASES: number,
  SELF_CONSUMPTION: number,
  ENERGY_TRADING: number,
  CRYPTO_MINING: number
}

export enum LoadState { ok = "ok", loading = "loading", fail = "fail" }
export enum ThunkState { pending, done, neverSent }
export interface IUserSettings {
  lastRoute: string | null;
  lastPlantID: string | null;
  showDemoPlants: boolean;
  dismissedNotifications: Array<number>;
}

export interface IFirebaseClaims {
  internalUser: boolean
}

export interface IAsyncData<T> {
  data: T,
  fetchState: ThunkState,
  /** The UnixEpoch when this data was last fetched. Null if it has never been fetched.
   *
   * Why isn't this just DateTime instead of millis? Cause redux doesn't accept serialized values in the store. So
   * millis on the way in and convert to DateTime in the selectors */
  epochOfLastFetched: number | null,
  /** The number of times the fetch action has failed in a row. Optional field */
  numberOfConsecutiveFailedRequests?: number,
  /** Meta property to denote the max available count for paginated calls */
}

export type PlantStore = Record<PlantId, IPlant | IMinifiedPlant>;
export type PossiblePlantStore = Record<PlantId, IPossiblePlant>;

export type ReduxAccessoryData = {
  info: PlantAccessory,
  data?: ServerAccessoryData
}

export type AccessoryStore = Record<
  PlantId,
  Record<
    AccessoryId, ReduxAccessoryData>
>

export type CachedDataPerPlant<T> = (
  DailyIntervalResponse<T> &
  MonthlyIntervalResponse<T> &
  YearlyIntervalResponse<T>
);
export type ScheduleStore = {
  [plantId: PhysicalPlantId]: SchedulePlantCommandEvent[]
}

export type EnvironmentalDataForPlant = CachedDataPerPlant<EnvironmentalData>;
export type FinancialDataForPlant = CachedDataPerPlant<FinancialData>;

type CachedDataStore<T> = Record<
  PlantId, CachedDataPerPlant<T>
>

export type EnvironmentalStore = CachedDataStore<EnvironmentalData>
export type FinancialStore = CachedDataStore<FinancialData>

export type ReduxSimStoreData = {
  info?: SoracomSim,
  data?: {
    minutes?: SoracomAirStats[],
    day?: SoracomAirStats[],
    month?: SoracomAirStats[],
  },
};

export type SimStore = Record<string, ReduxSimStoreData>;

export interface IAppData {
  authUser: string | null,
  currentPlantId: PlantId | undefined,
  settings: IUserSettings | null,
  appState: CustomAppState,
  plants: IAsyncData<PlantStore>,
  lastCurrentPlantUpdateEpoch: number | undefined,
  demoPlants: IAsyncData<PlantStore>,
  possiblePlants: IAsyncData<PossiblePlantStore>,
  alertList: IAsyncData<PlantId[]>
  powerData: IAsyncData<PowerData>,
  priceData: IAsyncData<PriceData>,
  aggregatePlantData: IAsyncData<AggregateData>,
  financialData: IAsyncData<FinancialStore>
  cumulativeFinancialData: IAsyncData<Record<ISOTimestamp, FinancialData>>,
  environmentalData: IAsyncData<EnvironmentalStore>,
  cumulativeEnvironmentalData: IAsyncData<Record<ISOTimestamp, EnvironmentalData>>,
  forceDataRefresh: number,
  firebaseClaims: IFirebaseClaims,
  uiFlags: Record<UIFlagName, string | number | boolean>,
  notifications: IAsyncData<INotification[]>,
  notificationPermissions: IAsyncData<INotificationPermissions>,
  userPersonalDetails: IAsyncData<IUserPersonalDetails>,
  challenges: IAsyncData<
    Record<
      PlantId, Record<
        ChallengeID, GenericChallenge
      >
    >
  >,
  tradingHistory: IAsyncData<
    Record<PlantId, Record<
      UnixTimeStamp, TradingHistory>
    >
  >
  products: IAsyncData<Record<ProductId, PurchaseInfo | undefined>>,
  tradingStatus: IAsyncData<Record<PlantId, {
    plantTradingStatus: PlantTradingStatus,
    freshness: number
  }>>,
  accessories: IAsyncData<AccessoryStore>,
  sims: IAsyncData<SimStore>,
  schedules: IAsyncData<ScheduleStore>,
}

export interface IPlantThunkResponse {
  data: {
    plants: (IPlant | IMinifiedPlant)[],
    possiblePlant?: IPossiblePlant[],
    demoPlants?: IPlant[],
  },
  pagination?: IPaginationResponse
}

export interface IPlantInfoThunkResponse {
  plantId: PlantId,
  data: IPlant,
}

export interface IPowerDataThunkParams {
  plantIds: string | null | string[],
  startTime: DateTime,
  endTime: DateTime,
  minuteInterval: PowerIntervalType
}

export type RedPiDeviceThunkResponse = RedPiObject[];
export type RedPiCommandsThunkResponse = RedPiCommand[];
export type RedPiLogsThunkResponse = RedPiLog[];
export type RedPiCommandResponseThunkResponse = RedPiCommandResponse;

export type fetchPriceDataThunkResponse = IPlantGetPricesV2Response;
export type fetchPowerDataThunkResponse = Record<PlantId, IPlantData[]>;

/*
What is UI Flags?
UI flags is way to store persistent front end exclusive UI choices which
cannot be inferred from alternative redux data or server data. For example,
a modal we want to pop up once as a warning. I am doing this through the redux
state as the scene directing is inferred entirely from the redux state.

If we going stateful once we going stateful everywhere. That's what ma pappy taught me

NOTE: this is different to the UIFlags currently in the mobile app using persitent
storage. That UI flag system utilizes persistent storage on the phone to provide
flags between app closes.

The difference is that system is EMU exclusive where this can be shared between
EMU and webapp. TODO: move over to exclusive UI flag systems for EMU and webapp
so its not in the redux. Having it here means we get a semi ok solution for both
when we can do it better for each platform with platform specific solutions.
*/
export type UIFlagName =
  "dismissedDemoPlantsWarning" |
  "homePageStateIsGraph" |
  "purchaseListenerInProgress" |
  "lastWarrantyReminderTime"