/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Paper } from "@mui/material";

// local imports
import { useAuth } from "auth";

const PREFIX = "Login";

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  fieldSet: `${PREFIX}-fieldSet`,
  error: `${PREFIX}-error`,
  loginButton: `${PREFIX}-loginButton`,
};

const Root = styled("form")(() => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  [`& .${classes.input}`]: {
    margin: "10px",
    maxWidth: "280px",
  },

  [`& .${classes.fieldSet}`]: {
    display: "grid",
    maxWidth: "300px",
    width: "300px",
    border: "0px"
  },

  [`& .${classes.error}`]: {
    color: "red",
    fontWeight: "bold",
    maxWidth: "280px",
    margin: "10px",
  },

  [`& .${classes.loginButton}`]: {
    maxWidth: "280px",
    margin: "10px",
  }
}));

function Login(): JSX.Element {
  const { user, signin } = useAuth();

  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | null>(null);

  function handleLoginSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };
    const email = target.email.value;
    const password = target.password.value;

    async function login() {
      await signin(email, password)
        .then(() => {
          navigate("/home");
        })
        .catch((err) => {
          setLoginError(err.message);
          setLoading(undefined);
        });
    }

    if (email !== "" && password !== "") {
      login();
    }
  }

  useEffect(() => {
    if (user !== null && user.uid !== null) {
      navigate("/home");
    }
  }, [
    user
  ]);

  return (
    <Root
      onSubmit={handleLoginSubmit}
      className={classes.root}
      onChange={() => setLoginError(null)}
    >
      <Paper className={classes.fieldSet}>
        <TextField
          type="email"
          name="email"
          label="E-mail Address"
          variant="outlined"
          fullWidth
          required
          className={classes.input}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          variant="outlined"
          fullWidth
          required
          className={classes.input}
        />
        {loginError ? <Typography className={classes.error}>{loginError}</Typography> : null}
        <Button className={classes.loginButton}
          type="submit" variant="contained" color="primary" disabled={loading}>
          Login
        </Button>
      </Paper>
    </Root>
  );
}

export default Login;
