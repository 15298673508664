/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { Provider } from "react-redux";
import store from "apps-middleware/redux/store/configureStore";
import { ProvideAuth } from "auth";
import Router from "layouts/Router";

const Index = () => {
  const [themeMode, setThemeMode] = useState<"light" | "dark">(() => {
    const savedThemeMode = localStorage.getItem("theme") || "light";
    return savedThemeMode === "light" ? "light" : "dark";
  });

  useEffect(() => {
    const theme = createTheme({
      palette: {
        mode: themeMode,
        primary: {
          main: themeMode === "light" ? "#000000" : "#ffffff",
        },
        secondary: {
          main: "#ff0000",
        },
        error: {
          main: "#c000ff",
        },
        text: {
          primary: themeMode === "light" ? "#000" : "#fff",
        },
      },
    });
    setTheme(theme);
  }, [themeMode]);

  const toggleDarkMode = () => {
    const newThemeMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newThemeMode);
    localStorage.setItem("theme", newThemeMode);
  };

  const [theme, setTheme] = useState(() => createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: themeMode === "light" ? "#000000" : "#ffffff",
      },
      secondary: {
        main: "#ff0000",
      },
      error: {
        main: "#c000ff",
      },
      text: {
        primary: themeMode === "light" ? "#000" : "#fff",
      },
    },
  }));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <ProvideAuth>
            <BrowserRouter basename="">
              <Router toggleDarkMode={toggleDarkMode} />
            </BrowserRouter>
          </ProvideAuth>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReactDOM.render(
  <Index />,
  document.getElementById("root")
);
