export const palette = {
  primary: {
    red: "#EF4239",
    white: "#FFFFFF",
    black: "#000000"
  },
  neutral: {
    "16161A": "#16161A",
    "2C2C33": "#2C2C33",
    "5A5A66": "#5A5A66",
    "8A8A99": "#8A8A99",
    "BEBECC": "#BEBECC",
    //duplicates for potential of many names
    "B1": "#16161A",
    "B2": "#2C2C33",
    "B3": "#5A5A66",
    "B4": "#8A8A99",
    "B5": "#BEBECC",
  },
  secondary: {
    red: {
      dark: "#B22626",
      primary: "#EF4239",
      light: "#FFCCCC",
    },
    orange: {
      dark: "#D69D27",
      primary: "#FFD378",
      light: "#FFEECC",
    },
    green: {
      dark: "#4EB27F",
      primary: "#69F2AC",
      light: "#CCFFE5",
    },
    blue: {
      dark: "#3B80B2",
      primary: "#50AEF2",
      light: "#CCEAFF",
    },
    purple: {
      dark: "#9234B2",
      primary: "#B140D9",
      light: "#F2CCFF"
    }
  }

};