import { PlantId } from "apps-middleware/types/plant";
import { TradeHistoryFidelity } from "apps-middleware/types/trading";
import { DEFAULT_GRAPH_VISBILITY_STATE } from "constants/graph";
import { Graphs } from "types/graph";

//anticipating some other local storage requests in the future
export enum LocalStorageKeys {
  plantGraphVisibility = "plantGraphVisibility",
  energyChartOptions = "energyChartOptions"
}

type plantGraphVisibilityValue = Record<Graphs, boolean>;

type EnergyChartOptions = {
  tradeHistoryFidelity: TradeHistoryFidelity,
}

const getKey = <T>(key: LocalStorageKeys): T => {
  const val = localStorage[key];
  return val ? JSON.parse(val) : {}; // all values must be stored as objects
  // so we can stringify in and out
};

const setKey = <T>(key: LocalStorageKeys, newObj: T): void => {
  if (typeof newObj !== "object") {
    // eslint-disable-next-line no-console
    console.warn("WARNING: attempting to set local store with none object value");
    return;
  }
  localStorage[key] = JSON.stringify(newObj);
};

// //////////////////////////////////////////////////////////////////////////////////////

export function setGraphVisbilityForPlant(
  plantId: PlantId,
  newObject: plantGraphVisibilityValue
): void {
  const newVal = getKey<Record<PlantId, plantGraphVisibilityValue>>(
    LocalStorageKeys.plantGraphVisibility
  );
  const allTrue = !Object.values(newObject).includes(false);
  // if all values are true we're back at the default state. Don't store
  // this, just delete it so we don't clog the local storage with unecessary
  // data. Next time we fetch it will create the right object
  if (allTrue && newVal[plantId]) delete newVal[plantId];
  else if (!allTrue) newVal[plantId] = newObject;
  setKey<Record<PlantId, plantGraphVisibilityValue>>(
    LocalStorageKeys.plantGraphVisibility,
    newVal
  );
}

export function getGraphVisibilityForPlant(plantId: PlantId): plantGraphVisibilityValue {
  const result = getKey<Record<PlantId, plantGraphVisibilityValue>>(
    LocalStorageKeys.plantGraphVisibility)[plantId];
  return result ? result : (DEFAULT_GRAPH_VISBILITY_STATE);
}

// /////////////

export function setEnergyChartOptions(
  newEnergyChartOptions: EnergyChartOptions
): void {
  setKey<EnergyChartOptions>(
    LocalStorageKeys.energyChartOptions,
    newEnergyChartOptions
  );
}

export function getEnergyChartOptions(): EnergyChartOptions {
  const dataInLocalStore = getKey<Record<string, unknown>>(
    LocalStorageKeys.energyChartOptions
  );

  const toReturn: EnergyChartOptions = {
    tradeHistoryFidelity: TradeHistoryFidelity.ConsequentialFails,
    ...dataInLocalStore
  };

  return toReturn;
}