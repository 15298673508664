import { alertPriorityToSeverity } from "../constants/plant";
import {
  AlertPriority,
  IMinifiedPlant,
  IMinifiedPlantExt,
  IPlant,
  IPlantAlert,
  SystemStatus,
  ConfigurationType,
  TariffType
} from "../types/plant";
import { isConfigurationType } from "./type";

export const systemStatusToSemanticName: Record<SystemStatus, string> = {
  "ASSEMBLY_COMPLETE": "Assembly Complete",
  "CREATED": "Created",
  "INSTALLED": "Installed",
  "IN_ASSEMBLY": "In Assembly",
  "IN_TESTING": "In Testing",
  "IN_TRANSIT": "In Transit",
  "TESTING_COMPLETE": "Testing Complete"
};

export const tariffTypeToSemanticName: Record<TariffType, string> = {
  "single": "Single",
  "timeOfUse": "Time of Use",
  "wholesale": "Wholesale"
};

export const configurationTypeToSemanticName: Record<ConfigurationType, string> = {
  [ConfigurationType.BlackMax]: "BlackMax",
  [ConfigurationType.CopperHead]: "CopperHead",
  [ConfigurationType.BlackMaxDeye]: "BlackMax - Deye",
  [ConfigurationType.BushChookDeye]: "BushChook - Deye",
  [ConfigurationType.DingoDeye]: "Dingo - Deye",
  [ConfigurationType.DropBearDeye]: "DropBear - Deye",
  [ConfigurationType.GeckoDeye]: "Gecko - Deye",
  [ConfigurationType.DropBear]: "DropBear",
  [ConfigurationType.HoneyBadger]: "HoneyBadger",
  [ConfigurationType.Matrix]: "Matrix",
  [ConfigurationType.StocklandsBespokeSystem]: "Stocklands Bespoke System",
  [ConfigurationType.SunRiseMini]: "SunRise Mini",
  [ConfigurationType.SunRise]: "SunRise",
  [ConfigurationType.Vault6]: "Vault-6"
};

/**
 * @param configurationType Possible configuration type
 * @returns UI friendly string representation of system type
 */
export function getConfigurationTypeUserFriendlyName(
  configurationType: ConfigurationType | string | null | undefined
):string {
  if(typeof configurationType !== "string") {
    // if its not a string, then return an obvious empty string
    return "---";
  } else if (isConfigurationType(configurationType)) {
    // return the semantic name if we have a familiar type
    return configurationTypeToSemanticName[configurationType];
  } else {
    // else return just the string configuration type
    return configurationType;
  }
}

export function getHighestAlertPriority(alerts: IPlantAlert[]): AlertPriority {
  let returnPriority: AlertPriority = "Low";
  let highestRating = alertPriorityToSeverity[returnPriority];

  for (let i = 0; i < alerts.length; i++) {
    const alert = alerts[i];
    const currentRating = alertPriorityToSeverity[alert.priority];
    if (currentRating !== undefined &&
      highestRating !== undefined &&
      currentRating > highestRating) {
      highestRating = currentRating;
      returnPriority = alert.priority;
    }
    if (returnPriority === "High") break; //auto leave if its already at max priority
  }
  return returnPriority;
}

/**
* Finds a total severity number of an a list of alerts and their priorities
*  High +3, Medium +2, Low +1, None +0
* @param alerts: Array of alerts
* @returns total severity rating number of alert list.
*/
export function calculateTotalAlertSeverity(alerts: IPlantAlert[]): number {
  let severityRating = 0;
  alerts.forEach((alert: IPlantAlert) => {
    severityRating += alertPriorityToSeverity[alert.priority] ?? 0;
  });
  return severityRating;
}

/**
 *
 * There are multiple names associated with a single plant/view and depending
 * on who is viewing and what they're viewing will change what name is appropriate
 * This function provides a name that we want to show to customers and on the UI
 * as a general display name for a plant.
 *
 * Note: this is a util which will most likely be used inside hooks and selectors.
 * If you are using this function to directly render a name into the UI you can
 * most likely accomplish what you need to do in a more efficient manor with
 * usePlant hook or the getCurrentPlant redux selector.
 *
 * @param plant plant or minified plant object
 * @returns the UI friendly, display name for a plant which customers should see
 */
export const getRenderedName = (plant: IMinifiedPlant | IMinifiedPlantExt | IPlant): string => {
  return plant.plantViewName ?? plant.plantName;
};

/**
 *
 * The plant info has a couple different mechanisms for telling us if the
 * plant is grid connected or not. This function sorts through all of the
 * mechanisms and returns a definitive boolean.
 *
 * @param plant Full plant info
 * @returns If that plant should be considered connected to the grid for the UI
 */
export const getIsGridConected = (plant: IPlant): boolean => {
  const DEFAULT_GRID_STATUS = true; //default to grid connected. The more common situation
  if (typeof plant.forceGridStatus === "boolean") return plant.forceGridStatus;
  else return plant.gridConnected ?? DEFAULT_GRID_STATUS;
};

/**
 *
 * @param plant
 * @returns state of "off grid" toggle
 */
export function getOffGridToggleState(plant: IPlant | undefined): boolean {
  if (plant === undefined) return false;
  //default to inverse of gridConnected. For an offline toggle we want
  // it to be true if its gridConnected === false.
  let forceOfflineModeToggleState = !plant.gridConnected ?? false;
  if (typeof plant.forceGridStatus === "boolean") {
    // treat the inverse of forceGridStatus as the toggle state as its an offline mode
    // toggle.
    forceOfflineModeToggleState = !plant.forceGridStatus;
  }
  return forceOfflineModeToggleState;

}

/**
 *
 * Parse through the config object which is unknown and find the
 * various inverter serial numbers that we can in it.
 *
 *
 * @param config plant config object
 * @returns array of serial numbers
 */
export function getInverterSerialFromPlantConfig(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: Record<string, any> | null | undefined
): string[] {
  const result: string[] = [];

  if (config === null || config === undefined) return result;
  if (
    "inverter" in config &&
    (typeof config.inverter === "object" && config.inverter !== null) &&
    "serial" in config.inverter &&
    config.inverter["serial"] !== undefined &&
    typeof config.inverter.serial === "string"
  ) {
    result.push(config.inverter.serial);
  } else if (
    "sp_pro_inverters" in config &&
    Array.isArray(config.sp_pro_inverters)
  ) {
    config.sp_pro_inverters.forEach((inv) => {
      if (
        typeof inv === "object" &&
        inv !== null &&
        "serial" in inv &&
        typeof inv.serial === "string"
      ) {
        result.push(inv.serial);
      } else if (typeof inv === "string") {
        result.push(inv);
      }
    });
  }

  return result;
}