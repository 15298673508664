import { Refresh } from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Button,
  Grid, IconButton, Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styles from "./monitoring.module.scss";
import {
  DropdownChecklist,
  Option
} from "components/Dropdowns/DropdownChecklist/DropdownChecklist";
import { Filter } from "@mui/icons-material/";
import { Link } from "react-router-dom";
import {
  labelFromDateRange,
  LabelledTimeRange,
  TIME_RANGES
} from "apps-middleware/util/time";
import { DateTime } from "luxon";
import {
  useAppDispatch,
  useAppSelector
} from "apps-middleware/redux/store/hooks";
import {
  AlertPriority,
  IMinifiedPlant,
  IPlant,
  IPlantAlert,
  PlantBrand,
  PlantId
} from "apps-middleware/types/plant";
import { IDateRange } from "apps-middleware/types/time";
import {
  DropdownDateRangePicker
} from "components/Dropdowns/DropdownRangePicker/DropdownRangePicker";
import {
  alertPriorityToColour,
  alertPriorityToSeverity
} from "apps-middleware/constants/plant";
import {
  getAlertListThunk,
  updatePlantThunk
} from "apps-middleware/redux/asyncThunks";
import { RootState } from "apps-middleware/redux/store/configureStore";
import { isPlantInfo } from "apps-middleware/util/type";
import {
  IFetchAlertsQueryParams,
  IFetchAlertsResponse,
  IPagination
} from "apps-middleware/types/server";
import Loading from "components/Loading/Loading";
import { usePlant } from "apps-middleware/hooks/usePlant";
import { FeatureIconRow } from "components/PlantFeatureIcons/iconRow";
import { arraysEqual } from "apps-middleware/util/general";
import { palette } from "apps-middleware/constants/palette";
import AlertEntry from "components/AlertEntry/AlertEntry";
import { OnEnterSearchBar } from "components/OnEnterSearchBar";

const UNSEEN_BACKGROUND = "rgba(255,0,0,0.1)";

interface IFilterState {
  search: string,
  seenState: string[],
  timeBounds: LabelledTimeRange
}

enum URLParams {
  search = "search",
  seenState = "seenState",
  timeBounds = "timeBounds",
}

const allSeenStateValue: Array<Option> = [
  {
    name: "seen",
    value: "seen"
  },
  {
    name: "unseen",
    value: "unseen",
  }
];

const defaultFilter: IFilterState = {
  search: "",
  seenState: ["seen", "unseen"],
  timeBounds: TIME_RANGES.TWENTY_FOUR_HOURS
};

function getFilterObjectFromParams(urlParams: URLSearchParams): IFilterState {
  const urlFilterParams: IFilterState = { ...defaultFilter };

  const search: string | null = urlParams.get(URLParams.search);
  if (search !== null) urlFilterParams.search = search;

  const tSeen: string | null = urlParams.get(URLParams.seenState);
  if (tSeen !== null) urlFilterParams.seenState = tSeen.split(",");

  const tTimeBounds: string | null = urlParams.get("timeBounds");
  if (tTimeBounds !== null) {
    if (!tTimeBounds.includes(",")) {
      const matchingLabelledRange = Object.values(TIME_RANGES)
        .find((val) => val.toUrlParam === tTimeBounds);
      if (matchingLabelledRange) urlFilterParams.timeBounds = matchingLabelledRange;
    } else {
      const incomingBounds = tTimeBounds.split(",");
      const newDateRange: IDateRange = {
        startDateTime: DateTime.fromISO(incomingBounds[0]),
        endDateTime: DateTime.fromISO(incomingBounds[1]),
      };
      urlFilterParams.timeBounds = new LabelledTimeRange(
        labelFromDateRange(newDateRange),
        "Custom",
        () => newDateRange,
        true
      );
    }

  }
  return urlFilterParams;
}

let rendered = false;

function Monitoring(): JSX.Element {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = useRef<any>();
  const [firstRenderCompleted, setFirstRenderCompleted] = useState(false);
  const [expandRecord, setExpandRecord] = React.useState<Array<PlantId>>([]);
  /**
   * Why aren't we using useSearchParams from react-router-dom?
   *
   * the setURLParams function apart of the routing library will
   * trigger a re-render of the entire component tree anytime we update
   * the url through it. This is a bit overkill, for this page, we dont
   * actually change any functionality based on url changes, the change
   * to the url is only a sideeffect of changing the filter states which
   * adjust the data displayed without needing the url updated. The only time
   * the url comes into play is on first paint and someone opens with
   * filters in the url params. IN this case, we read the url and adjust.
   *
   * When filter options change in use, we just use the default js method
   * of replacing the URL which won't cause excessive renders of the react
   * component tree.
   */
  const urlParams = new URLSearchParams(window.location.search);
  const [
    filterState,
    setFilterState
  ] = React.useState<IFilterState>(getFilterObjectFromParams(urlParams));
  const [searchInputValue, setSearchInputValue] = useState(filterState.search);
  const [searchFilterValue, setSearchFilterValue] = useState(filterState.search);

  const targetValueForSearchFilter = searchFilterValue;

  const [alertListIds, setAlertListIds] = useState<PlantId[]>([]);
  const [totalAvailable, setTotalAvailable] = useState(Infinity);

  const [loading, setLoading] = useState(true);
  const DEFAULT_PAGE_NUMBER = 25;
  const resetFilter = () => {
    setFilterState(defaultFilter);
    setSearchInputValue("");
    setSearchFilterValue("");
  };

  useEffect(function onLoad() {
    rendered = true;

    setFirstRenderCompleted(true);
    return function onUnload() {
      rendered = false;
    };
  }, []);

  const virtuoso = React.useRef<VirtuosoHandle | null>(null);

  const filteredPlants: (IPlant | IMinifiedPlant)[] = useAppSelector((state: RootState) => {
    const result: (IPlant | IMinifiedPlant)[] = [];
    const allPlants: (IPlant | IMinifiedPlant)[] = Object.values(state.appData.plants.data);
    alertListIds.forEach((id: string) => {
      const plant = allPlants.find((p) => p.plantId === id);
      if (!plant) return;

      const filteredPlant = isPlantInfo(plant) ? filterPlant(plant) : plant;
      if (filteredPlant) result.push(filteredPlant);
    });

    return result;
  });

  useEffect(function searchChangeToFilterParams() {
    setFilterState((state) => ({
      ...state,
      search: targetValueForSearchFilter
    }));
  }, [targetValueForSearchFilter]);

  const setSearchInputToSearchFilter = useCallback(() => {
    setSearchFilterValue(searchInputValue);
  }, [searchInputValue]);

  async function getAlerts(pagination: IPagination) {
    const alertListArgs: IFetchAlertsQueryParams = {
      pagination: pagination,
      startUnixTimestamp: filterState.timeBounds.range.startDateTime.toMillis(),
      endUnixTimestamp: filterState.timeBounds.range.endDateTime.toMillis(),
    };
    if (!filterState.seenState.includes("seen")) alertListArgs.seen = false;
    if (!filterState.seenState.includes("unseen")) alertListArgs.seen = true;
    if (filterState.search !== "") alertListArgs.nameQuery = filterState.search;

    if (promise !== undefined && promise.current) promise.current.abort();
    if (rendered) setLoading(true);
    promise.current = dispatch(getAlertListThunk(alertListArgs));
    promise.current
      .unwrap()
      .then((res: undefined | Record<string, unknown>) => {
        if (rendered) setLoading(false);
        /**
         * TODO: replace this garbage with v3/view/alerts
         *
         * Whats happening here?
         *
         * We get back a list of plantIds from the getAlertList
         * call. We need plantinfos. The current solution is to
         * get the plantIds then make individual plant/getInfo calls
         * for each id. This is inefficient. We will soon make a new
         * alert list call which will return the plant infos rather
         * than just plantIds.
         */
        if (
          res &&
          typeof res === "object" &&
          "plantIds" in res &&
          Array.isArray(res["plantIds"])
        ) {
          const data = res as IFetchAlertsResponse;
          setAlertListIds((inList) => [...inList, ...data.plantIds]);
          setTotalAvailable(data.pagination.totalAvailable);
          data.plantIds.forEach((plantId: unknown) => {
            if (plantId !== undefined && typeof plantId === "string") {
              dispatch(updatePlantThunk(plantId));
            }
          });
        }
      })
      .catch(() => {
        // abort silently
      });
  }

  function getNextPage() {
    getAlerts({
      currentPage: Math.max(
        Math.floor(alertListIds.length / DEFAULT_PAGE_NUMBER) + 1,
        1
      ),
      numberPerPage: DEFAULT_PAGE_NUMBER,
    });
  }

  /**
 * Reset and get alerts when the time range changes
 */
  React.useEffect(() => {
    setAlertListIds([]);
    getAlerts({
      currentPage: 1,
      numberPerPage: DEFAULT_PAGE_NUMBER
    });
    if (virtuoso && virtuoso.current) virtuoso.current.scrollToIndex(0);

    if (!firstRenderCompleted) return;
    constructUrlParams();

  }, [JSON.stringify(filterState)]);

  function constructUrlParams() {
    const newParams: URLSearchParams = new URLSearchParams(urlParams.toString());

    if (filterState.search !== defaultFilter.search) {
      newParams.set(URLParams.search, filterState.search);
    } else {
      newParams.delete(URLParams.search);
    }

    if (
      !arraysEqual<string>(
        filterState.seenState,
        defaultFilter.seenState,
        (a, b) => a.localeCompare(b)
      )
    ) {
      newParams.set(URLParams.seenState, filterState.seenState.join(","));
    } else {
      newParams.delete(URLParams.seenState);
    }

    if (filterState.timeBounds !== null && filterState.timeBounds !== defaultFilter.timeBounds) {
      newParams.set(URLParams.timeBounds, filterState.timeBounds.toUrlParam);
    } else {
      newParams.delete(URLParams.timeBounds);
    }
    if (
      !arraysEqual(
        Array.from(urlParams.values()),
        Array.from(newParams.values())
      )
    ) {
      window.history.replaceState({}, "", `${location.pathname}?${newParams}`);
    }
  }

  /**
*  Determines if an alert passes the current filter state
* @param alert: alert to be checked
* @returns whether this alert passes the fitler
*/
  function fitlerAlerts(alert: IPlantAlert): boolean {
    const isOutOfTimeBounds = filterState.timeBounds
      ? alert.created < filterState.timeBounds.range.startDateTime.toMillis() ||
      alert.created > filterState.timeBounds.range.endDateTime.toMillis()
      : false;
    const isHiddenFromSeenState = !filterState.seenState.includes(alert.seen ? "seen" : "unseen");
    return (!isOutOfTimeBounds && !isHiddenFromSeenState);
  }

  /**
*  Determines if a plant passes the current filter state
* @param plant: plant to be checked
* @returns whether this alert passes the fitler
*/
  function filterPlant(plantToCheck: IPlant): IPlant | null {
    const plant = { ...plantToCheck };
    // if (filterState.hiddenPlants.includes(plant.plantId)) return null;
    if (!filterState.seenState.includes("seen") && !filterState.seenState.includes("unseen")) {
      return null;
    }

    // reapply the alerts based on a filtered version before checking other
    // conditions
    plant.alerts = plant.alerts
      .slice()
      .filter(fitlerAlerts)
      .sort((a, b) =>
        alertPriorityToSeverity[b.priority] - alertPriorityToSeverity[a.priority]);
    return plant;
  }

  const onEndReach = () => {
    if (alertListIds.length >= totalAvailable) return;
    getNextPage();
  };

  const Footer = () => (
    alertListIds.length < totalAvailable
      ? <Grid
        container
        justifyContent={"center"}
        alignContent="center"
        style={{ height: 75 }}
        flexDirection="column"
      >
        <Typography>
          {alertListIds.length} of {totalAvailable} currently loaded.
        </Typography>
        <Button onClick={onEndReach}>
          Load More
        </Button>
      </Grid>
      : <Typography textAlign={"center"} style={{
        opacity: filteredPlants.length > 0 ? 0.75 : 0,
        height: 75,
        lineHeight: "75px",
      }}>{
          "No more alerts to load."
        }</Typography>
  );
  const EmptyPlaceholder = () => (
    <div style={{ justifySelf: "center", alignSelf: "center" }}>
      <Grid item container xs={12}
        alignItems="center" flexDirection={"column"}>
        <br /><br />
        <Typography textAlign="center">
          No alerts to show for your selected filter options.
        </Typography>
        <br />
        <Button
          variant="outlined"
          startIcon={<Refresh />}
          onClick={resetFilter}>
          Reset Filters
        </Button>
      </Grid>
    </div >
  );

  return (
    <div className={styles.container}>
      <Grid container gap={2} item xs={12} alignItems={"center"}
        className={styles.controlsContainer}>
        <Grid item xs container alignContent="center">
          <OnEnterSearchBar
            subject="Alerts"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchInputValue(event.target.value);
            }}
            autoFocus
            key={"uniquekeyhere"}
            inputValue={searchInputValue}
            appliedValue={searchFilterValue}
            onEnterKeyPress={setSearchInputToSearchFilter}

          />
        </Grid>
        <Grid item xs={"auto"}>
          <DropdownChecklist
            items={allSeenStateValue}
            defaultSelected={filterState.seenState}
            onSelectionChange={(items) => {
              setFilterState({
                ...filterState,
                seenState: items.map((option) => option.value),
              });
            }}
            labelText={"Filter Seen"}
            icon={<Filter />}
          />
        </Grid>
        <Grid item xs={"auto"}>
          <DropdownDateRangePicker
            dateRange={filterState.timeBounds}
            includeAllTime
            setDateRange={(r) => {
              setFilterState({
                ...filterState,
                timeBounds: r
              });
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            children={<Refresh />}
            onClick={resetFilter}
          />
        </Grid>
      </Grid>
      <div style={{ height: "100%", position: "relative" }}>
        {
          loading
            ? <Grid container
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 10
              }}>
              <Loading informationText="Loading Alerts" />
            </Grid> : null
        }
        <Virtuoso
          overscan={5}
          ref={virtuoso}
          totalCount={filteredPlants.length}
          style={{ height: "100%" }}
          defaultItemHeight={75}
          components={{ Footer, EmptyPlaceholder }}
          itemContent={(index: number) => {

            const plant: IPlant | IMinifiedPlant = filteredPlants[index];
            const isFullPlantInfo = isPlantInfo(plant);

            const seenAlertCount: Record<AlertPriority, number> = {
              "High": 0,
              "Medium": 0,
              "Low": 0,
            };
            const unseenAlertCount: Record<AlertPriority, number> = {
              "Low": 0,
              "Medium": 0,
              "High": 0
            };

            if (isFullPlantInfo) {
              plant.alerts.forEach((alert) => alert.seen
                ? seenAlertCount[alert.priority]++ : unseenAlertCount[alert.priority]++);
            }

            return <MemoPlantToAlertAccordion
              key={plant.plantId}
              plantInfoIsLoading={!isFullPlantInfo}
              plant={plant}
              toggleExpanded={() => {
                if (!isFullPlantInfo) return;
                const newState = expandRecord;
                if (expandRecord.includes(plant.plantId)) {
                  newState.splice(expandRecord.indexOf(plant.plantId), 1);
                } else {
                  newState.push(plant.plantId);
                }
                setExpandRecord([
                  ...newState
                ]);
              }}
              isExpanded={!isFullPlantInfo ? false : expandRecord.includes(plant.plantId)}
              unseenAlertCount={unseenAlertCount}
              seenAlertCount={seenAlertCount}
              isOdd={index % 2 !== 0}>
              <Grid container gap={1}>
                {
                  isPlantInfo(plant) && plant.alerts
                    .map((alert, i) => <AlertEntry
                      plantId={plant.plantId}
                      alert={alert}
                      key={i}
                    />
                    )
                }
              </Grid>
            </MemoPlantToAlertAccordion>;
          }}
        />
      </div>

    </div>
  );
}

interface IAlertToAccordionProps {
  children: JSX.Element,
  plant: IMinifiedPlant | IPlant,
  plantInfoIsLoading: boolean,
  isOdd: boolean,
  isExpanded: boolean,
  toggleExpanded: () => void,
  seenAlertCount: Record<AlertPriority, number>
  unseenAlertCount: Record<AlertPriority, number>
}

function PlantToAlertAccordion({
  plant,
  isOdd,
  isExpanded,
  toggleExpanded,
  plantInfoIsLoading,
  children,
  seenAlertCount,
  unseenAlertCount
}: IAlertToAccordionProps): JSX.Element {
  const dispatch = useAppDispatch();
  const plantHook = usePlant({
    plantId: plant.plantId
  });

  useEffect(function onRender() {
    dispatch(updatePlantThunk(plant.plantId));
  }, []);

  const AlertCount = (
    {
      count,
      priority,
      noColour = false
    }: {
      count: number,
      priority: AlertPriority,
      noColour?: boolean
    }): JSX.Element | null => (
    count > 0 ? <Grid item>
      <Typography variant="subtitle2" sx={noColour
        ? {} : { color: alertPriorityToColour[priority] }}>
        {count} {priority}
      </Typography>
    </Grid> : null);

  const ExternalDashboardButton = (): JSX.Element | null => {
    if (!plantHook?.plantProviderName) return null;
    const links: Partial<Record<PlantBrand, string>> =
    {
      "Sungrow": `https://portalau.isolarcloud.com/#/plantDetail/overview?psId=${plantHook?.plantProviderId}`,
      "SwitchDin": `https://pwa.switchdin.com/unit/${plantHook?.plantProviderId}/overview`,
    };
    return (plantHook?.plantProviderName && links[plantHook?.plantProviderName])
      ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          variant="outlined"
          href={links[plantHook?.plantProviderName] as string}
          target={"_blank"}
        >
          {plantHook?.plantProviderName}
        </Button>
      )
      : null;
  };

  const totalSeenCount = Object.values(seenAlertCount)
    .reduce((partialSum, a) => partialSum + a, 0);
  const totalUnseenCount = Object.values(unseenAlertCount)
    .reduce((partialSum, a) => partialSum + a, 0);

  return (
    <div style={{ minHeight: 100 }}>
      <Accordion
        sx={{
          minHeight: 100,
          position: "relative"
        }}
        expanded={isExpanded}
        onChange={toggleExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid container gap={2}
            direction="row"
            flexWrap={"nowrap"}
            justifyContent="space-between"
            alignItems="center">
            <Grid item container gap={1}>
              <Grid item container flexDirection={"column"} gap={0.5} alignItems="flex-start">
                <Typography variant="h6">
                  {plant.plantName}
                </Typography>
                <FeatureIconRow
                  plantId={plant.plantId}
                  rowWidth={125}
                  iconColour={palette.neutral.B2} />
              </Grid>

              <Grid container flexDirection="column" gap={1} width="fit-content">
                {
                  totalUnseenCount > 0
                    ? <Grid container flexDirection="row" gap={2}
                      paddingX={1}
                      style={{
                        backgroundColor: UNSEEN_BACKGROUND,
                        border: "solid 2px rgba(255,0,0,0.25)",
                        borderRadius: 5
                      }}>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {totalUnseenCount} Unseen Alerts:
                        </Typography>
                      </Grid>
                      <AlertCount count={unseenAlertCount.High} priority={"High"} />
                      <AlertCount count={unseenAlertCount.Medium} priority="Medium" />
                      <AlertCount count={unseenAlertCount.Low} priority="Low" /></Grid> : null
                }
                {
                  totalSeenCount > 0
                    ? <Grid container flexDirection="row" gap={2}
                      paddingX={1}
                      style={
                        {
                          opacity: 0.8,
                          borderLeft: "solid 2px transparent",
                        }}>
                      <Grid item>
                        <Typography variant="subtitle2">
                          {totalSeenCount} Seen Alerts:
                        </Typography>
                      </Grid>
                      <AlertCount count={seenAlertCount.High} priority={"High"} noColour />
                      <AlertCount count={seenAlertCount.Medium} priority="Medium" noColour />
                      <AlertCount count={seenAlertCount.Low} priority="Low" noColour />
                    </Grid> : null
                }
              </Grid>

            </Grid>
            {
              plantInfoIsLoading ? null
                : <Grid xs="auto" item container gap={1} justifyContent="flex-end">
                  <ExternalDashboardButton />
                  <Button
                    component={Link}
                    to={"/device/" + plant.plantId}
                    target="_blank"
                    variant="outlined"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}>
                    Red Earth
                  </Button>
                </Grid>
            }
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
const MemoPlantToAlertAccordion = React.memo(PlantToAlertAccordion);

export default Monitoring;
