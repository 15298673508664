import {
  Button, Checkbox, ClickAwayListener,
  FormControlLabel, Menu, MenuItem, MenuProps, Radio, Typography
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";

type UniqueValue = string;

export const DEFAULT_ENTRY_HEIGHT = 55;

export type Option = {
  value: UniqueValue,
  name: string,
}

interface IDropdownChecklistProps {
  icon?: JSX.Element,
  labelText?: string
  items: Option[],
  defaultSelected?: UniqueValue[],
  controlledSelected?: Option[],
  onSelectionChange: (items: Option[]) => void
  style?: CSSProperties,
  popUpSize?: {
    width: number,
    height: number,
  },
  isRadio?: boolean,
}

export const DropdownChecklist = (props: IDropdownChecklistProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<Option[]>(
    props.defaultSelected !== undefined
      ? props.items
        .filter((option) =>
          props.defaultSelected !== undefined &&
          props.defaultSelected.includes(option.value))
      : props.items
  );
  const targetSelected = props.controlledSelected ? props.controlledSelected : selected;
  const targetOnSelectionChange = props.controlledSelected ? props.onSelectionChange : setSelected;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(function onSelectionChange() {
    if (props.controlledSelected) return;
    props.onSelectionChange(selected);
  }, [selected]);

  function handleItemClick(clickedItem: Option, indexInSelection: number) {
    const newState = props.isRadio ? [] : [...targetSelected];
    if (indexInSelection > -1 && !props.isRadio) {
      //go for remove
      newState.splice(indexInSelection, 1);
    } else {
      // add it
      newState.push(clickedItem);
    }
    targetOnSelectionChange(newState);
  }

  const selectAll = () => {
    targetOnSelectionChange(props.items);
  };
  const clearAll = () => {
    targetOnSelectionChange([]);
  };

  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <div style={props.style}>
        <Button onClick={handleClick} startIcon={props.icon}>
          <Typography>{props.labelText}</Typography>
        </Button>
        <AnchorMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <div style={{
            padding: 10,
          }}>
            {
              !props.isRadio &&
              <div>
                <Button
                  disabled={props.items.length === targetSelected.length}
                  onClick={selectAll}>
                    Select All
                </Button>
                <Button
                  disabled={targetSelected.length === 0}
                  onClick={clearAll}>
                  Clear All
                </Button>
              </div>
            }
            {
              props.isRadio &&
              <div>
                <Button
                  disabled={targetSelected.length === 0}
                  onClick={clearAll}>Reset Selection</Button>
              </div>
            }
            <div style={{
              flex: 1,
              ...(
                props.popUpSize ? props.popUpSize
                  : {
                    width: window.innerWidth * 0.25,
                    height: window.innerHeight * 0.25
                  }
              ),
              maxWidth: 400,
              maxHeight: props.items.length * DEFAULT_ENTRY_HEIGHT
            }}>
              <Virtuoso
                overscan={20}
                style={{ height: "100%" }}
                totalCount={props.items.length}
                defaultItemHeight={DEFAULT_ENTRY_HEIGHT}
                itemContent={(index) => {
                  const option = props.items[index];
                  const indexInChecked = targetSelected.findIndex((o) => o.value === option.value);
                  return <MenuItem
                    key={option.value}
                    onClick={() => handleItemClick(option, indexInChecked)}
                  >
                    <FormControlLabel
                      style={{ pointerEvents: "none" }}
                      control={
                        props.isRadio
                          ? <Radio checked={indexInChecked > -1}
                            inputProps={{ "aria-label": "controlled" }} />
                          : <Checkbox
                            checked={indexInChecked > -1}
                            inputProps={{ "aria-label": "controlled" }} />
                      }
                      label={option.name} />
                  </MenuItem>;
                }}
              />
            </div>
          </div>
        </AnchorMenu>
      </div>
    </ClickAwayListener>
  );
};

const AnchorMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    transitionDuration={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
);

export { };