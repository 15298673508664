import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
interface TimeAgoProps {
  timestamp?: number | null;
}

const LastUpdatedFeature: React.FC<TimeAgoProps> = ({ timestamp }) => {
  if (timestamp === null || timestamp === undefined) {
    return (
      <span>
        <AccessTimeIcon fontSize="inherit" style={{ marginRight: "3px" }} />
        No Monitoring Data
      </span>
    );
  }

  const calculateTimeAgo = (timestamp: number): string => {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - timestamp;

    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  };

  return (
    <span style={{paddingTop: "1px"}}>
      <AccessTimeIcon fontSize="inherit" style={{ marginRight: "3px" }} />
      {calculateTimeAgo(timestamp)}
    </span>
  );
};

export default LastUpdatedFeature;
