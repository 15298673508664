import React, { useEffect, useState } from "react";

import {
  matchPath, Navigate, Route, Routes,
  useLocation
} from "react-router-dom";
import { useAuth } from "auth";

import makeStyles from "@mui/styles/makeStyles";

import routes from "routes";

import styles from "assets/jss/material-dashboard-react/layouts/routerStyle";

import { FixMeLater } from "apps-middleware/types/general";
import { useAppDispatch, useAppSelector } from "apps-middleware/redux/store/hooks";
import {
  updatePlantThunk
} from "apps-middleware/redux/asyncThunks";
import { setCurrentPlant } from "apps-middleware/redux/slices/appDataSlice";
import { doesReduxHaveCurrentPlantFullInfo } from "apps-middleware/redux/selectors/plants";
import ResponsiveAppBar from "components/Navbars/ResponsiveAppBar";

//part of the template. Not worth replacing
const useStyles = makeStyles(styles as FixMeLater);

interface IRouterLayoutProps {
  toggleDarkMode: () => void; // Add the toggleDarkMode prop
}

export default function Router({ toggleDarkMode }: IRouterLayoutProps): JSX.Element {
  // styles
  const classes = useStyles();
  const { user, completedInitialSetup } = useAuth();
  const currentPlantIsUpToDate = useAppSelector(doesReduxHaveCurrentPlantFullInfo);

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef<HTMLDivElement>();
  const [hasAuth, setHasAuth] = useState<boolean | undefined>(undefined);
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    // Load dark mode preference from local storage, default to false
    const savedDarkMode = localStorage.getItem("darkMode");
    return savedDarkMode ? JSON.parse(savedDarkMode) : false;
  });

  const dispatch = useAppDispatch();

  // Save dark mode preference to local storage
  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    if (completedInitialSetup) {
      // Only set has auth when the user is setup
      setHasAuth(user !== null && user.uid !== null);
    }
  }, [user, completedInitialSetup]);

  useEffect(function makeSureCurrentPlantIsAlwaysUpToDate() {
    if (!currentPlantIsUpToDate && hasAuth) dispatch(updatePlantThunk());
  }, [currentPlantIsUpToDate, hasAuth]);
  /**
   * Setup all the routes inside the switch, then force a redirect if the
   * auth is false (no possible way to nav to anything other than login)
   */
  function ResgisterRoutesPostAuthSetup() {
    if (completedInitialSetup && hasAuth !== undefined) {
      const currentRoutes = [...routes
        // Don"t register any routes which you shouldn"t be allowed too.
        .filter((r) => (hasAuth || r.name === "Login"))
        .map((prop, key) => <Route
          path={prop.path}
          element={prop.component}
          key={key}
        />
        ),
      <Route
        path="/"
        key="route_root"
        element={<Navigate replace to={!hasAuth ? "/login" : "/home"} />} />,
      <Route
        path="*"
        key="route_wildcard"
        element={<Navigate replace to={!hasAuth ? "/login" : "/home"} />} />
      ];
      return (<Routes>
        {currentRoutes}
      </Routes>);
    }
    return null;
  }

  return (
    <>
      <AppStateController />
      <div className={classes.wrapper}>
        <div className={classes.mainPanel} ref={mainPanel}>

          <ResponsiveAppBar
            toggleDarkMode={() => {
              toggleDarkMode();
            }}
            setDarkMode={setDarkMode}
            routes={routes.filter((t) => hasAuth && t.onNav)}
          />

          <div className={classes.content}>
            <ResgisterRoutesPostAuthSetup />
          </div>
        </div>
      </div>
    </>
  );
}

/*
  App state controller is the location I"d like to keep all the logic which
  aligns our redux appState and the routing.

  E.g. I"d like it so whenever we navigate to device/:plantId our redux state
  considers that a selection of a currentPlant. Alternatively once we navigate
  to a different route the redux store deselects that plant so the property
  for currentPlant in the redux is set undefined.

  This component can listen for url changes, determine what change has been
  made and adjust dispatch redux store actions to keep the store in line
  with the route info
*/
const AppStateController = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(function watchForPathChanges() {
    const devicePathInfo = matchPath("/device/:plantId", pathname);
    const newPlantId = devicePathInfo ? devicePathInfo.params.plantId : undefined;

    dispatch(setCurrentPlant(newPlantId));

  }, [pathname]);

  return null;
};
