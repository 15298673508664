/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/** @JSX JSX */

// @mui/icons-material
import Dashboard from "@mui/icons-material/Dashboard";
import PublicIcon from "@mui/icons-material/Public";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import TtyIcon from "@mui/icons-material/Tty";
import NotesIcon from "@mui/icons-material/Notes";

// core components/views for Admin layout

import DevicesPage from "views/Devices/devices";
import EnergyPage from "views/Energy/energy";
import TradingLogsPage from "views/Energy/tradingLogs";
import HomePage from "views/Home/home";
import LoginPage from "views/Login/login";
import Monitoring from "views/Monitoring/monitoring";
import RedpiPage from "views/Redpi/redpi";
import RedpiControlPage from "views/Redpi/redpiControl";
import { IRouteInfo } from "types/routes";

const dashboardRoutes: IRouteInfo[] = [
  {
    path: "/login",
    name: "Login",
    icon: PublicIcon,
    layout: "/admin",
    component: <LoginPage />,
    onNav: false
  },
  {
    path: "/home",
    name: "Home",
    icon: PublicIcon,
    layout: "/admin",
    component: <HomePage />,
    onNav: true
  },
  {
    path: "/devices",
    name: "Devices",
    icon: Dashboard,
    layout: "/admin",
    component: <DevicesPage />,
    onNav: true
  },
  {
    path: "/device/:plantId",
    name: "Energy",
    icon: TimelineIcon,
    layout: "/admin",
    component: <EnergyPage />,
    onNav: false
  },
  {
    path: "/device/:plantId/trading-logs",
    name: "Trading Logs",
    icon: NotesIcon,
    layout: "/admin",
    component: <TradingLogsPage />,
    onNav: false
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    icon: AssessmentIcon,
    layout: "/admin",
    component: <Monitoring />,
    onNav: true
  },
  {
    path: "/redpi",
    name: "Red Pi",
    icon: DeveloperBoardIcon,
    layout: "/admin",
    component: <RedpiPage />,
    onNav: true
  },
  {
    path: "/redpi/:ggid",
    name: "Red Pi Control",
    icon: TtyIcon,
    layout: "/admin",
    component: <RedpiControlPage />,
    onNav: false
  }
];

export default dashboardRoutes;

export const useRouteName = (): string => {
  let name = "";
  dashboardRoutes.forEach((route) => {
    if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      name = route.name;
    }
  });
  return name;
};