
import HomeBottom from "./homeBottom";
import HomeTop from "./homeTop";
function Home(): JSX.Element | null {
  return (
    <div style={{ width: "100%"}}>
      <HomeTop />
      <HomeBottom />
    </div>
  );
}
export default Home;
