import {
  OrderDirection,
  TradingAlgorithms,
  TradeHistoryFidelity
} from "../types/trading";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractReason = (x: any): string => {
  if (!x) {
    return "Unable to determine reason.";
  }

  //sungrow reason
  if (x.success) {

    const firstRow = x.success[0];

    if (firstRow?.task_name) {
      const str = firstRow.task_name;

      // eslint-disable-next-line max-len
      return str.split("Reason: ")[1];
    }

  }

  return "Unable to determine reason";
};

export const orderToLaymanTerminology = (order: OrderDirection, succeeded: boolean): string => {
  const SelfConsumptionCommand: OrderDirection[] = [
    "SelfConsumption",
    "SelfConsumptionBanExports",
  ];

  const buyCommand: OrderDirection[] = [
    "MaximiseImports",
    "ChargeBattery",
    "ChargeBatteryBanExports",
    "TrickleCharge",
    "MaximiseImportsBanExports",
  ];
  const sellCommand: OrderDirection[] = ["MaximiseExports"];
  const noCommand: OrderDirection[] = ["NoData", "None"];

  if (buyCommand.includes(order)) {
    return succeeded
      ? "Plant is buying from grid."
      : "Order failed to instruct plant to buy from grid. Continuing as is.";
  } else if (SelfConsumptionCommand.includes(order)) {
    return succeeded
      ? "Plant is self consuming."
      : "Order failed to instruct plant to self consume. Continuing as is";
  } else if (sellCommand.includes(order)) {
    return succeeded
      ? "Plant is selling to grid."
      : "Order failed to instruct plant to sell to grid. Continuing as is.";
  } else if (noCommand.includes(order)) {
    return "Plant continuing as is.";
  } else {
    return "Unable to determine if buying or selling.";
  }
};

export const tradingHistoryFidelityToSemanticName: Record<TradeHistoryFidelity, string> = {
  [TradeHistoryFidelity.NoFails]: "Hide Trade Order Failures",
  [TradeHistoryFidelity.ConsequentialFails]: "Show Consequential Trade Order Failures",
  [TradeHistoryFidelity.AllFails]: "Show All Trade Order Failures"
};

export const algorithmToName: Record<TradingAlgorithms, string> = {
  [TradingAlgorithms.SelfSufficiency]: "Self Sufficiency",
  [TradingAlgorithms.AggressiveExports]: "Aggressive Exports"
};

export const algorithmToDescription: Record<TradingAlgorithms, string> = {
  // eslint-disable-next-line max-len
  [TradingAlgorithms.SelfSufficiency]: "This mode prioritises your energy independence from the grid. So it ends trading either when your price thresholds aren't met or it hits the battery state of charge you need to make it through the night (based on your previous 30 days of nighttime usage).",
  // eslint-disable-next-line max-len
  [TradingAlgorithms.AggressiveExports]: "This mode prioritises making money over energy independence. So long as your sales price threshold is met, it will discharge to the grid until your battery hits its lower limit. This should improve your revenues but will likely require overnight energy purchases from the grid"
};