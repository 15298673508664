import { IPriceDataV2 } from "../../types/server";
import { DateTime } from "luxon";
import {
  IUIFriendlyPriceData,
  PlantPriceData,
  PriceData,
  ThunkState,
} from "../../types/redux";
import { RootState } from "../store/configureStore";
import { priceDataToUIFriendlyPriceData } from "../utils";
import { selectCurrentPlantId } from "./plants";

export const getAllPriceData = (state: RootState): PriceData => state.appData.priceData.data;

export const getCurrentPlantPriceData = (state: RootState): null | PlantPriceData => {
  const currentPlantId = selectCurrentPlantId(state);
  if (!currentPlantId) return null;

  const allPriceData = getAllPriceData(state);
  const currentPlantPriceData = allPriceData[currentPlantId];
  return currentPlantPriceData;
};

export const findCurrentPriceDataFromArray = (
  priceData: IPriceDataV2[]): IPriceDataV2 | null => {
  const goal = DateTime.now().toMillis();

  const allValuesNotForcasted = Object.values(priceData)
    .filter((row) => row.unixTimestamp <= goal);
  if (allValuesNotForcasted.length === 0) return null;

  const current = allValuesNotForcasted.reduce(function (prev, curr) {
    return (Math.abs(curr.unixTimestamp - goal) < Math.abs(prev.unixTimestamp - goal)
      ? curr : prev);
  });
  if (!current) return null;

  return current;

};

export const getCurrentPlantMostRecentPriceData = (state: RootState):
  IUIFriendlyPriceData | null => {

  const currentPlantPriceData = getCurrentPlantPriceData(state);
  if (!currentPlantPriceData) return null;

  const current = findCurrentPriceDataFromArray(
    Object.values(currentPlantPriceData[5])
  );

  if (current === null) return null;
  else return priceDataToUIFriendlyPriceData(current);
};

export const isPriceDataFetching = (state: RootState): boolean =>
  state.appData.priceData.fetchState !== ThunkState.done;
