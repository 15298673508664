import React from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Search } from "@mui/icons-material";

/**
 * Search input which provides functionality to only work whenever an on press
 * is entered and also when an icon button is pressed (with the same on enter handler).
 *
 * @returns Search Input
 */
export function OnEnterSearchBar({
  subject,
  appliedValue,
  inputValue,
  onChange,
  onEnterKeyPress,
  defaultSearchValue = "",
  autoFocus,
}: {
  subject: string,
  inputValue: string,
  appliedValue: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onEnterKeyPress: () => void,
  defaultSearchValue?: string,
  autoFocus?: TextFieldProps["autoFocus"]
}) {
  return (
    <TextField
      autoFocus={autoFocus}
      label={appliedValue === defaultSearchValue
        ? `Search ${subject}` : `Current Search: ${appliedValue}`}
      variant="outlined"
      size="small"
      value={inputValue}
      onChange={onChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") onEnterKeyPress();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onEnterKeyPress}>
              <Search />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}