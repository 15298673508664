/* eslint-disable no-console */
import React, { useState, useEffect, useContext, createContext } from "react";
import { firebaseConfig } from "./constants";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { getUser } from "./getUser";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export async function signOut(): Promise<void> {
  return firebase.auth().signOut();
}

export const getClaims = async () => {
  const user = getUser();
  if (user === null) return null;
  const idTokenResult = await user.getIdTokenResult();
  if (
    idTokenResult &&
    idTokenResult.claims !== undefined
  ) return idTokenResult.claims;
  else return null;
};

export async function getIdToken(forceRefresh?: boolean): Promise<string | null> {
  const user = getUser();
  const token = user ? await user.getIdToken(forceRefresh) : null;
  return token;
}

export async function getIdTokenResult(): Promise<Record<string, unknown> | null> {
  const user = getUser();
  const tokenResult = await user?.getIdTokenResult();
  return tokenResult ? tokenResult.claims : null;
}

interface IAuthContext {
  user: firebase.UserInfo | null;
  // Below is required because when we initally use firebase.auth().currentUser
  // which will return null initally, but the listner will not actually be
  // completed yet, so we use the below to track if the currentUser has
  // actually completed before doing inital auth routing stuff
  completedInitialSetup: boolean;
  signin: (e: string, p: string) => Promise<firebase.UserInfo | null>;
  signout: () => Promise<void>;
  signup: (e: string, p: string) => Promise<firebase.UserInfo | null>;
  sendPasswordResetEmail: (e: string) => Promise<boolean>;
  confirmPasswordReset: (c: string, p: string) => Promise<boolean>;
}

const authContext = createContext<IAuthContext>({
  user: firebase.auth().currentUser,
  completedInitialSetup: true,
  signin: async (e: string, p: string) => {
    console.warn(`No signin method defined! ${e === ""}${p === ""}`);
    return null;
  },
  signout: async () => {
    console.warn("No signout method defined!");
  },
  signup: async (e: string, p: string) => {
    console.warn(`No signup method defined! ${e === ""}${p === ""}`);
    return null;
  },
  sendPasswordResetEmail: async (e: string) => {
    console.warn(`No signin method defined! ${e === ""}`);
    return false;
  },
  confirmPasswordReset: async (c: string, p: string) => {
    console.warn(`No signin method defined! ${c === ""}${p === ""}`);
    return false;
  }
});

interface IProvideAuth {
  children: React.ReactNode;
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: IProvideAuth): JSX.Element {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): IAuthContext => useContext(authContext);

// Provider hook that creates auth object and handles state
function useProvideAuth(): IAuthContext {
  const initalUser = firebase.auth().currentUser;
  const [user, setUser] = useState<firebase.User | null>(initalUser);
  const [completedInitialSetup, setCompletedInitialSetup] = useState<boolean>(false);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (
    email: string,
    password: string
  ): Promise<firebase.UserInfo | null> => {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    try {
      setUser(response.user);
      return response.user;
    } catch (error) {
      console.warn("signin error: ", error);
      return null;
    }
  };

  const signup = async (
    email: string,
    password: string
  ): Promise<firebase.UserInfo | null> => firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((response) => {
      setUser(response.user);
      return response.user;
    });

  const signout = async (): Promise<void> => {
    try {
      await firebase.auth().signOut();
      setUser(null); //setUser is a function to update user state
    } catch (error) {
      console.error("Error during signout:", error);
    }
  };
  const sendPasswordResetEmail = async (email: string): Promise<boolean> => firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => true);
  const confirmPasswordReset = async (
    code: string,
    password: string
  ): Promise<boolean> => firebase
    .auth()
    .confirmPasswordReset(code, password)
    .then(() => true);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setCompletedInitialSetup(true);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [
  ]);

  // Return the user object and auth methods
  return {
    user,
    completedInitialSetup,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}
