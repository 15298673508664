import {
  transition
} from "assets/jss/material-dashboard-react";
import { Theme } from "@mui/material";

const appStyle = (theme: Theme): Record<string, unknown> => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: "100%"
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    height: "100vh",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    width:"100%",
    minHeight: "calc(100vh - 70px)",
    display:"flex",
  },
});

export default appStyle;
