//TODO: fix FixMeLater.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FixMeLater = any;

export enum states {
  queensland = "QLD",
  newsouthwhales = "NSW",
  victoria = "VIC",
  act = "ACT",
  tasmanaia = "TAS",
  southaustralia = "SA",
  westernaustralia = "WA",
  northernterritory = "NT"
}

export enum CustomAppState {
  active = "active",
  background = "background"
}

//this is just a quality of life thing so we can get a better idea
//of what unit we're dealing with
export type Watts = number;

export type RecursiveRequired<T> = Required<{
  [P in keyof T]: T[P] extends object | undefined ? RecursiveRequired<Required<T[P]>> : T[P];
}>;
