import { DateTime } from "luxon";
import { TradingAlgorithms, UserOverrideCommand } from "./trading";

export interface IPossiblePlant {
  plantId: string,
  physicalPlantId?: string,
  owner?: string,
  productName?: string,
  address?: string,
  serialNumber?: string,
}

export type PlantBrand = "Sungrow" | "SwitchDin" | "Manual Upload";
export type AlertPriority = "Low" | "Medium" | "High";

export enum ConfigurationType {
  BlackMax = "bm-v1",
  CopperHead= "cpd-v1",
  BlackMaxDeye= "deye-blackmax-v1",
  BushChookDeye= "deye-bushchook-v1",
  DingoDeye= "deye-dingo-v1",
  DropBearDeye= "deye-dropbear-v1",
  GeckoDeye= "deye-gecko-v1",
  DropBear= "dbs-v1",
  HoneyBadger= "hb-v1",
  Matrix= "mat-v1",
  StocklandsBespokeSystem= "stl-v1",
  SunRiseMini= "srm-v1",
  SunRise= "sr-v1",
  Vault6= "vault-6-v1",
}

export type SystemStatus = "CREATED" |
  "IN_ASSEMBLY" |
  "ASSEMBLY_COMPLETE" |
  "IN_TESTING" |
  "TESTING_COMPLETE" |
  "IN_TRANSIT" |
  "INSTALLED";

export type AlertType = (
  "BAT_VOLTAGE_ALARM" |
  "NO_GRID" |
  "OFFLINE_24" |
  "LOW_PV_24" |
  "CRYPTO_OFFLINE" |
  "CRYPTO_LOW_HASHRATE" |
  "SYSTEM_MODE_MISMATCH" |
  "EXPORTING_AT_NEGATIVE" |
  "SOC_LOW_24" |
  "SOC_HIGH_24" |
  "OFFLINE_1" |
  "NONE" |
  "SYSTEM_UNABLE_TO_TRADE_MISCONFIGURED" |
  "SYSTEM_UNABLE_TO_TRADE_INVALID_ENERGY_PROVIDER" |
  "PAYMENT_FAIL" |
  "INVALID_AMBER" |
  "SUBSCRIPTION_LAPSED"
)
export interface IPlantAlert {
  alertId?: string,
  type: AlertType,
  created: number,
  description: string,
  priority: AlertPriority,
  seen: boolean,
  userSeen: boolean,

  supportTicket?: string,
  resolvedTime?: number,
  plantDataSourceId: number,

  plantViewId?: PlantId,
  warnUser?: boolean,
  warning?: string,
}

export interface IPlantStatus {
  fault_status: number | string;
  fault_status_descriptions: Record<string, string>;
  online: boolean;
  timestamp: number;
  export_price_set_point?: number;
  daily_totals?: Record<string, string | number>;
}

export interface IHouseModes {
  eco: string,
  standard: string,
  vacation: string,
}

export type HouseMode = "standard" | "ecoVacation";

export enum ConnectedAppliance {
  PoolPump = "poolPump",
  WaterHeater = "waterHeater",
  EVCharging = "evCharging",
}

export interface IEV {
  allowed: boolean;
  enabled: boolean;
  evCharging: boolean;
  evChargePercentage: number;
}

export interface ICrypto {
  allowed: boolean;
  enabled: boolean;
}

export type AccessType = "direct" | "demo" | "group" | "support";
export type PermissionLevel = "HOME" | "INSTALLER" | "SUPPORT" | "ADMIN" | "DEMO"

// TO BE DEPRECATED. DO NOT USE
export enum AccessLevel {
  DEMO = "DEMO",
  BASIC = "BASIC",
  COLLECTOR = "COLLECTOR",
  HOME_ADMIN = "HOME_ADMIN",
  INSTALLER = "INSTALLER",
  REDEARTH_ADMIN = "REDEARTH_ADMIN"
}

export type PlantId = string; // view_
export type PhysicalPlantId = string; // plant_

export interface IMinifiedPlant {
  plantId: PlantId,
  plantName: string,
  plantViewName?: string;
  physicalPlantId?: string,
  accessType?: AccessType;
  permissionLevel?: PermissionLevel;

}

export interface IMinifiedPlantExt extends IMinifiedPlant {
  isFullInfoPresent: boolean,
}

export interface IPlant extends IMinifiedPlant {
  // minifiedPlant physicalPlantId?: string,
  // minifiedPlant plantName: string,
  // minifiedPlant plantId: PlantId,
  timezone: string;
  plantProviderName: PlantBrand;
  plantProviderId?: string;
  providerDataAccessPlantId?: string;
  tradingEnabled: boolean;
  tradingAllowed: boolean;
  tradingForbidden: boolean;
  tariffType: TariffType | null;
  isPricingConfigured: boolean;
  online: boolean;
  alerts: Array<IPlantAlert>
  accessLevel: AccessLevel; // to be deprecated
  singlePhase?: boolean;
  threePhase?: boolean;
  installedSolarCapacity?: number | null;
  batteries?: number;
  batteryInfo?: BatteriesInfo;
  serialNumber?: string | null;
  installDateUnixTimestamp?: number | null;
  productName?: string | null;
  userLocation?: {
    address?: string | null;
    postcode?: string | null;
    state?: string | null;
  };
  tradingType?: number;
  importPriceSetPoint?: number;
  importTradingThreshold: number | null;
  exportPriceSetPoint?: number;
  exportTradingThreshold: number | null;
  maxExportWatts?: number;
  cryptoEnabled?: boolean;
  cryptoAllowed?: boolean;
  defaultView?: string | null;
  chassisId?: number | null;
  salesOrderNo?: string | null;
  // TODO: Actually type this one properly
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderDetails?: object | null;
  currentPlantData?: IPlantData;
  configurationType?: ConfigurationType | string;
  configurationDetails?: object | null;

  warrantyName?: string | null;
  status?: SystemStatus;
  isVip?: boolean;
  isOptimus?: boolean;
  gridConnected?: boolean;
  // minifiedPlant plantViewName?:string
  // minifiedPlant accessType?: AccessType;
  // minifiedPlant permissionLevel?: PermissionLevel;
  houseMode: HouseMode;
  qrId?: string,
  forceGridStatus?: boolean,
  tradingAlgorithmType?: TradingAlgorithms
  hasSmartEvCharger?: boolean,
  hasLoadControl?: boolean;
  userOverrideCommand?: UserOverrideCommand
  hasPowerRanger?:boolean,
}

export interface IPlantData {
  //battery values
  batteryCharge: number | null,
  batteryCurrent: number | null,
  batteryDischarge: number | null,
  batteryPower: number | null,
  batterySoc: number | null,
  reportedBatterySoc: number | null,
  calculatedBatterySoc: number | null,
  batteryVoltage: number | null,

  //generator values
  generatorCurrent: number | null,
  generatorFrequency: number | null,
  generatorPower: number | null,
  generatorVoltage: number | null,

  gridConnectionStatus: boolean,

  //grid in/out values
  gridExport: number | null,
  gridImport: number | null,
  gridPower: number | null,

  //home usage values
  loadCurrent: number | null,
  loadPower: number | null,
  loadVoltage: number | null,

  //solar values
  pvCurrent: number | null,
  pvPower: number | null,
  pvFromDC: number | null,
  pvFromAC: number | null,
  pvVoltage: number | null,

  //ev values
  evPower: number | null,
  evCurrent: number | null,
  evVoltage: number | null,
  evFrequency: number | null

  unixTimestamp: number | null,
}

export interface IPlantDataV1 {
  "1": number;
  "2": number;
  "3": number;
  "4": number;
  "5": number;
  timestamp: DateTime;
}

export type CachedData = {
  plantId: string,
  date?: string,
  unixTimestamp: number,
}
export type EnvironmentalData = CachedData & {
  pvWattHours: number,
  nonRenewableWattHours: number,
  activePowerUsageWattHours: number,
  gridImportWattHours: number,
  gridExportWattHours: number,
  batteryDischargeWattHours: number,
  batteryChargeWattHours: number,
  selfSufficiency?: number,
  evWattHours?: number,
  generatorWattHours?: number,
}

export type FinancialData = CachedData & {
  estimatedCost: number,
  withoutPvCost: number,
  energyEarnings: number,
  estimatedReturn?: number,
  cryptoEarningsETH?: number,
  cryptoEarningsAUD?: number,
  evPowerSavings?: number,
}

export type TariffType = "single" | "timeOfUse" | "wholesale";
export const tariffTypeArray: Array<TariffType> = [
  "single",
  "timeOfUse",
  "wholesale"
];

//System status refers to individual components associated with a plant. e.g. solar array or ev/crypto capacity
export enum DeviceStatus {
  Unavailable = "unavailable",
  Enabled = "enabled",
  Disabled = "disabled"
}

// ************************************************** beware of below. TODO: Refactor and remove below

export interface BatteryInfo {
  batteryType: string;
  capacity: number;
  serialNumber?: string | undefined;
  description: string;
}

export type BatteriesInfo = {
  capacityCurrent: number;
  capacityPotential: number;
  batteryCount: number;
  batteries: BatteryInfo[];
}

export type PlantDataType = Record<keyof IPlantData, number>;

export type WarrantyPutParams = {
  firstName?: string,
  lastName?: string,
  email?: string[],
  phone?: string,
  street?: string,
  suburb?: string,
  state?: string,
  postcode?: string,
  receiveMarketingEmails?: boolean,
  installerName?: string,
  installerContact?: string,
}

export type WarrantyGetResponse = WarrantyPutParams & {
  customerId?: number,
  userId?: string,
  qrId?: string,
  plantId: string,
  linkedAtMillis?: number,
}