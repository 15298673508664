import { DateTime } from "luxon";
import { IDateRange } from "../types/time";
import { CachedDataIntervals } from "../types/server";

export class LabelledTimeRange {
  _name: string;

  _shortName: string | null;

  _rangeFn: (() => IDateRange);

  _isCustom: boolean;

  constructor(name: string, shortName: string | null, rangeFn: () => IDateRange, isCustom = false) {
    this._name = name;
    this._shortName = shortName;
    this._rangeFn = rangeFn;
    this._isCustom = isCustom;
  }

  public get shortName(): string {
    if (this.shortName === null) {
      return this._name;
    }
    return this.shortName;
  }

  public get name(): string {
    return this._name;
  }

  public get range(): IDateRange {
    if (this._rangeFn === null) {
      throw "Can't get a custom range!";
    }
    return this._rangeFn();
  }

  public get toUrlParam(): string {
    if (this._isCustom) {
      return this.range.startDateTime.toISO() + "," + this.range.endDateTime.toISO();
    } else {
      return this.name;
    }
  }

  public get toMillisDifference(): number {
    return this.range.endDateTime.toMillis() - this.range.startDateTime.toMillis();
  }

  public get dateRange(): IDateRange {
    return {
      startDateTime: this.range.startDateTime.startOf("day"),
      endDateTime: this.range.endDateTime.endOf("day")
    };
  }

  public get dates(): string[] {
    return getDatesFromRange(this.range);
  }

  public get bestFitMonth(): string {
    return this.range.endDateTime.monthLong;
  }

  public get bestFitYear(): string {
    return (this.range.endDateTime.year).toString();
  }

}

export const TIME_RANGES = {
  TODAY: new LabelledTimeRange("Today", null, () => ({
    startDateTime: DateTime.now().startOf("day"),
    endDateTime: DateTime.now().endOf("day")
  })),
  SINCE_MIDNIGHT: new LabelledTimeRange("Since Midnight", null, () => ({
    startDateTime: DateTime.now().startOf("day"),
    endDateTime: DateTime.now()
  })),
  THREE_HOURS: new LabelledTimeRange("Last 3 Hours", "3h", () =>
    ({ startDateTime: DateTime.now().minus({ hours: 3 }), endDateTime: DateTime.now() })),
  SIX_HOURS: new LabelledTimeRange("Last 6 Hours", "6h", () =>
    ({ startDateTime: DateTime.now().minus({ hours: 6 }), endDateTime: DateTime.now() })),
  TWENTY_FOUR_HOURS: new LabelledTimeRange("Last 24 Hours", "24h", () =>
    ({ startDateTime: DateTime.now().minus({ days: 1 }), endDateTime: DateTime.now() })),
  THREE_DAYS: new LabelledTimeRange("Last 3 Days", "3d", () =>
    ({ startDateTime: DateTime.now().minus({ days: 3 }), endDateTime: DateTime.now() })),
  WEEKLY: new LabelledTimeRange("Weekly", null, () =>
    ({ startDateTime: DateTime.now().startOf("week"), endDateTime: DateTime.now() })),
  MONTHLY: new LabelledTimeRange("Monthly", null, () =>
    ({ startDateTime: DateTime.now().startOf("month"), endDateTime: DateTime.now() })),
  YEARLY: new LabelledTimeRange("Yearly", null, () =>
    ({ startDateTime: DateTime.now().startOf("year"), endDateTime: DateTime.now() })),
  THIRTYDAYS: new LabelledTimeRange("Last 30 Days", null, () =>
    ({ startDateTime: DateTime.now().minus({ days: 30 }), endDateTime: DateTime.now() })),
  ALL_TIME: new LabelledTimeRange(
    "All Time", null, () => (
      {
        endDateTime: DateTime.now(),
        startDateTime: DateTime.fromMillis(0),
      }
    ))
};

export const currentTimeRangesToCachedIntervals: Record<CachedDataIntervals, LabelledTimeRange> = {
  [CachedDataIntervals.Yearly]: TIME_RANGES.ALL_TIME,
  [CachedDataIntervals.Monthly]: TIME_RANGES.YEARLY,
  [CachedDataIntervals.Daily]: TIME_RANGES.MONTHLY
};

export const makeLabelledTimeRange = (startDateTime: DateTime, endDateTime: DateTime) => {
  return new LabelledTimeRange(
    "custom",
    null,
    () => ({
      startDateTime: startDateTime,
      endDateTime: endDateTime
    }),
    true
  );
};

export const HOURS_IN_A_DAY = 24;
export const MINUTES_IN_AN_HOUR = 60;
export const SECONDS_IN_A_MINUTE = 60;
export const MS_IN_A_SECOND = 1000;
export const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR;
export const MS_IN_AN_HOUR = MS_IN_A_SECOND * SECONDS_IN_AN_HOUR;
export const MS_IN_A_DAY = MS_IN_AN_HOUR * 24;
export const MS_IN_A_WEEK = MS_IN_A_DAY * 7;
export const UTC_DATE_FORMAT = "yyyy-MM-dd";
export const UI_DATE_FORMAT = "dd/MM";
export const UI_GRAPH_YEAR_FORMAT = "yyyy";
export const UI_GRAPH_MONTH_FORMAT = "LLL";
export const UI_GRAPH_DATE_FORMAT = "dd/MM";
export const UI_HOUR_FORMAT = "HH:mm";

export const LocalDateTimeString = (d: DateTime): string =>
  d.toFormat(`${UI_DATE_FORMAT} ${UI_HOUR_FORMAT}`);
export const LocalDateString = (d: DateTime): string => d.toFormat(UI_DATE_FORMAT);
export const LocalTimeString = (d: DateTime): string => d.toFormat(UI_HOUR_FORMAT);

export const dateTimeToHHMMWithPMAM = (dateTime: DateTime, options?: {
  removeSpaceBetweenTimeAndAmPM?:true,
}): string => {
  let baseString = dateTime.toFormat("hh:mm a");
  // remove any leading zeros
  baseString = baseString.replace(/^0+/, "");
  if(options?.removeSpaceBetweenTimeAndAmPM === true) {
    baseString = baseString.replace(/\s/g, "");
  }
  return baseString.toLowerCase();
};
/**
 * Gets a ui friendly string describing a date range.
 *
 * @param range the start and end dates to get the name for
 * @returns a ui friendly string
 */
export function labelFromDateRange(range: IDateRange): string {
  return `${LocalDateTimeString(range.startDateTime)} - ${LocalDateTimeString(range.endDateTime)}`;
}

/**
 * Gets a number of milliseconds between a dateRange object.
 *
 * @param range the start and end dates to get the list for
 * @returns number of milliseconds between range
 */
export const dateRangeToMillisDuration = (range: IDateRange): number =>
  range.endDateTime.toMillis() - range.startDateTime.toMillis();

/**
 * Gets a list of utc formatted dates between and including the start and end
 * dates.
 * @param range the start and end dates to get the list for
 * @returns a list of date string between and including the range
 */
export function getDatesFromRange(range: IDateRange): Array<string> {
  const result: Array<string> = [];
  let current = range.startDateTime;

  while (current.endOf("day") <= range.endDateTime) {
    result.push(current.toFormat(UTC_DATE_FORMAT));
    current = current.plus({ days: 1 });
  }

  return result;
}

/**
 * Return the provided datetime as a nicely formatted date time
 * @param date the datetime to format
 * @param showYear if the year will also be shown on the date output
 * @returns the formatted string
 */
export function GetUIFriendlyDateTimeString(date: DateTime, showYear = false): string {
  return GetUIFriendlyDateString(date, showYear) + " " + GetUIFriendlyTimeString(date);
}

/**
 * Return the provided datetime as a nicely formatted date
 * @param date the datetime to format
 * @param showYear if the year will also be shown on the date output
 * @returns the formatted string
 */
export function GetUIFriendlyDateString(date: DateTime, showYear = false): string {
  return date.toLocaleString({
    day: "numeric",
    month: "short",
    year: showYear ? "2-digit" : undefined
  });
}

/**
 * Return the provided datetime as a nicely formatted time
 * @param date the datetime to format
 * @returns the formatted string
 */
export function GetUIFriendlyTimeString(date: DateTime): string {
  return date.toLocaleString({
    hour: "2-digit",
    minute: "2-digit"
  });
}

/**
 * Gets a nicely formatted '@param d1 to @param d2' string
 * @param d1 The first time to be formatted
 * @param d2 The second time to be formatted
 * @returns A nicely formatted "to" string
 */
export function GetUIFriendlyToString(d1: DateTime, d2: DateTime): string {
  const showYear = d1.year === d2.year;
  if (isSameDate(d1, d2)) {
    if (isSameDate(d1, DateTime.now())) {
      // If it is same date and today, then just show time
      return `${GetUIFriendlyTimeString(d1)} to ${GetUIFriendlyTimeString(d2)}`;
    } else {
      // If it is same date but not today, show both full date times
      return (
        // eslint-disable-next-line max-len
        `${GetUIFriendlyDateTimeString(d1, showYear)} to ${GetUIFriendlyDateTimeString(d2, showYear)}`
      );
    }
  }
  return `${GetUIFriendlyDateString(d1, showYear)} to ${GetUIFriendlyDateString(d2, showYear)}`;
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}((\.\d*)|(\+\d{2}:\d{2}))?$/;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isIsoDateString = (value: any): boolean =>
  value && typeof value === "string" && isoDateFormat.test(value);

const DATETIME_RESPONSE_PARAM_KEYS = ["timestamp"];

/**
 * This function intercepts responses from the backend and turns any iso compliant
 * datetime stamps as luxon DateTimes, also checks for specials keys as specified
 * by @DATETIME_RESPONSE_PARAM_KEYS
 * @param input the response or recursive subkey of response
 * @returns the response updated with the datetimes
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function adaptIsoStrings(input: any): any {
  Object.keys(input).forEach((key) => {
    if (input[key] !== null && typeof input[key] === "object") {
      adaptIsoStrings(input[key]);
      // Check if the object is an iso compliant string
    } else if (isIsoDateString(input[key])) {
      // Turn it into a luxon datetime
      input[key] = DateTime.fromISO(input[key]);
    } else if (DATETIME_RESPONSE_PARAM_KEYS.includes(key)) {
      input[key] = DateTime.fromMillis(input[key] * 1000);
    }
  });
  return input;
}

export const datetimeToDateKey = (dateTime: DateTime): string =>
  dateTime.toFormat(UTC_DATE_FORMAT);

export const isSameDate = (d1: DateTime, d2: DateTime): boolean =>
  d1.day === d2.day && d1.month === d2.month && d1.year === d2.year;