import { AccessTime, ExpandMore } from "@mui/icons-material";
import { Button, Divider, Grid, Menu, TextField, Typography } from "@mui/material";
import { IDateRange } from "apps-middleware/types/time";
import {
  labelFromDateRange,
  LabelledTimeRange,
  TIME_RANGES,
} from "apps-middleware/util/time";
import { DateTime } from "luxon";
import React, { useState } from "react";
import styles from "./DropdownRangePicker.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import LuxonAdapter from "@date-io/luxon";

interface IDropdownDateRangePickerProps {
  dateRange: LabelledTimeRange | null;
  setDateRange: (newDateRange: LabelledTimeRange) => void,
  stretchButton?: boolean;
  includeAllTime?: boolean;
}

export const DropdownDateRangePicker = (
  {
    dateRange,
    setDateRange,
    stretchButton = false,
    includeAllTime = false
  }: IDropdownDateRangePickerProps): JSX.Element => {
  const [customBounds, setCustomBounds] = useState<IDateRange>(
    dateRange ? dateRange.range : TIME_RANGES.TODAY.range
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  function handleApplyCustomRange() {
    setDateRange(new LabelledTimeRange(
      labelFromDateRange(customBounds),
      "Custom",
      () => customBounds,
      true));
    handleClose();
  }

  return (
    <div style={{
      width: stretchButton ? "100%" : "initial",
      display: "grid",
      placeItems: "center"
    }}>
      <Button
        fullWidth={stretchButton}
        style={{
          justifyContent: stretchButton ? "space-between" : "initial",
          background: "linear-gradient(180deg, #EF4239 87.5%, #B22626 100%)",
          minHeight: stretchButton ? 50 : "unset",
          maxWidth: stretchButton ? 340 : "unset",
        }}
        variant="contained"
        color="primary"
        id="basic-menu"
        aria-controls="dense-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : "false"}
        onClick={handleClick}
        startIcon={<AccessTime />}
        endIcon={<div style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          width: 28,
          height: 28,
          borderRadius: "100%",
          display: "grid",
          placeItems: "center",
        }}>
          <ExpandMore />
        </div>}>
        <span style={{
          textAlign: stretchButton ? "left" : "center",
          flexGrow: stretchButton ? 1 : "unset"
        }}>
          {dateRange ? dateRange.name : "No Range Selected"}
        </span>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handleClose}>
        <Grid container className={styles.timePickerContainer}
          flexDirection={"column"} padding={1}>
          <Grid container item flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Grid item xs={8}>
              <Typography variant="h5" className={styles.matchButtonPadding}>
                Select Time Range
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent={"end"}>
              <Button onClick={handleClose}>Close</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.divider}><Divider /></Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} container
              flexDirection={"column"}
              flexWrap={"nowrap"}
              alignItems={"start"}
              className={styles.relativeContainer}>
              <Typography className={styles.matchButtonPadding}>
                Relative Time Range
              </Typography>
              {
                Object.values(TIME_RANGES).map((range, i) => {
                  if (range.name === "Weekly" ||
                    range.name === "Monthly" ||
                    range.name === "Since Midnight" ||
                    range.name === "Yearly" ||
                    range.name === "Last 30 Days" ||
                    (!includeAllTime ? range.name === "All Time" : false)
                  ) return null;
                  return <Button
                    key={i}
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setDateRange(range);
                      handleClose();
                    }}>
                    {range.name}
                  </Button>;
                })
              }
            </Grid>
            <Grid item xs={6}>
              <Grid container
                height="100%"
                flexDirection={"column"}
                justifyContent="space-between">
                <Grid item>
                  <Typography>Custom Date Range</Typography>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={LuxonAdapter}>
                    <DatePicker
                      label="Start Date"
                      value={customBounds.startDateTime}
                      renderInput={(params) => <TextField {...params} />}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newVal) => {
                        if (!newVal) return;
                        setCustomBounds({
                          ...customBounds,
                          startDateTime: newVal?.startOf("day")
                        });
                      }}
                      maxDate={customBounds.endDateTime}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={LuxonAdapter}>
                    <DatePicker
                      label="End Date"
                      value={customBounds.endDateTime}
                      renderInput={(params) => <TextField {...params} />}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newVal) => {
                        if (!newVal) return;
                        setCustomBounds({
                          ...customBounds,
                          endDateTime: newVal?.endOf("day")
                        });
                      }}
                      minDate={customBounds.startDateTime}
                      maxDate={DateTime.now().plus({ days: 2 })}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleApplyCustomRange}>
                    Apply Custom Range
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
};