/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IPaginationResponse, IPriceDataV2 } from "../types/server";
import {
  ConfigurationType,
  EnvironmentalData,
  FinancialData,
  IPlant,
  IPlantData
} from "../types/plant";
import { IFirebaseClaims, IPlantThunkResponse } from "../types/redux";
import { INotificationPermissions } from "../types/notification";
import { TradingHistory } from "../types/trading";
import { PurchaseInfo } from "./../types/products";
import { PlantAccessory, ServerAccessoryData } from "../types/accessory";
import { SchedulePlantCommandEvent } from "../types/schedules";
import { configurationTypeAsArray } from "../constants/plant";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectContainsOfType = (obj: any, key: string, type: string): boolean => {
  const keys = Object.keys(obj);

  if (keys.includes(key) && (typeof obj[key] === type)) {
    return true;
  }
  return false;
};

export function isPlantInfo(object: unknown | undefined): object is IPlant {
  if (object === undefined) return false;
  return Object.prototype.hasOwnProperty.call(object, "plantId") &&
    Object.prototype.hasOwnProperty.call(object, "plantName") &&
    Object.prototype.hasOwnProperty.call(object, "tariffType") &&
    Object.prototype.hasOwnProperty.call(object, "alerts");
}

export function isPlantData(object: unknown | undefined): object is IPlantData {
  if (object === undefined) return false;
  return Object.prototype.hasOwnProperty.call(object, "pvPower") &&
    Object.prototype.hasOwnProperty.call(object, "batteryPower") &&
    Object.prototype.hasOwnProperty.call(object, "gridPower") &&
    Object.prototype.hasOwnProperty.call(object, "batterySoc") &&
    Object.prototype.hasOwnProperty.call(object, "loadPower") &&
    Object.prototype.hasOwnProperty.call(object, "unixTimestamp");
}

export function isPriceV2Data(object: unknown): object is IPriceDataV2 {
  return Object.prototype.hasOwnProperty.call(object, "unixTimestamp") &&
    Object.prototype.hasOwnProperty.call(object, "importPrice") &&
    Object.prototype.hasOwnProperty.call(object, "exportPrice") &&
    Object.prototype.hasOwnProperty.call(object, "spotPrice") &&
    Object.prototype.hasOwnProperty.call(object, "renewableMix") &&
    Object.prototype.hasOwnProperty.call(object, "resolution");
}

export function isPlantThunkResponse(object: unknown): object is IPlantThunkResponse {
  return Object.prototype.hasOwnProperty.call(object, "plants") &&
    Object.prototype.hasOwnProperty.call(object, "possiblePlant") &&
    Object.prototype.hasOwnProperty.call(object, "demoPlants");
}

export function isPaginationResponse(object: unknown): object is IPaginationResponse {
  const props: Array<keyof IPaginationResponse> = [
    "currentPage", "numberPerPage", "totalAvailable"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isNotificationPermissions(object: unknown): object is INotificationPermissions {
  const props: Array<keyof INotificationPermissions> = [
    "achievements", "batteryPercentages", "forecast", "outcomes",
    "trading", "updates", "weatherForecasts", "data"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isTradingHistory(object: unknown): object is TradingHistory {
  const props: Array<keyof TradingHistory> = [
    "completedSuccessfully", "timestamp"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isFirebaseClaims(object: unknown): object is IFirebaseClaims {
  const props: Array<keyof IFirebaseClaims> = [
    "internalUser"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isProduct(object: unknown): object is PurchaseInfo {
  if (object === undefined || object === null) return false;
  const props: Array<keyof PurchaseInfo> = [
    "id", "productName", "purchased"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isPlantAccessory(object: unknown): object is PlantAccessory {
  if (object === undefined || object === null) return false;
  const props: Array<keyof PlantAccessory> = [
    "id", "plantId", "type"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isServerPlantAccessoryStatus(object: unknown): object is ServerAccessoryData {
  if (object === undefined || object === null) return false;
  const props: Array<keyof ServerAccessoryData> = [
    "status"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isFinancialData(object: unknown): object is FinancialData {
  if (object === undefined || object === null) return false;
  const props: Array<keyof FinancialData> = [
    "unixTimestamp", "plantId", "estimatedCost", "withoutPvCost"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isEnvironmentalData(object: unknown): object is EnvironmentalData {
  if (object === undefined || object === null) return false;
  const props: Array<keyof EnvironmentalData> = [
    "unixTimestamp", "plantId", "pvWattHours", "nonRenewableWattHours"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isSchedulePlantCommandEvent(object: unknown): object is SchedulePlantCommandEvent {
  if (object === undefined || object === null) return false;
  const props: Array<keyof SchedulePlantCommandEvent> = [
    "start", "plantId", "end", "day"
  ];
  return props.every((key) => Object.prototype.hasOwnProperty.call(object, key));
}

export function isConfigurationType(object:unknown): object is ConfigurationType {
  if(typeof object !== "string") return false;
  return configurationTypeAsArray.includes(object as ConfigurationType);
}