import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { IRouteInfo } from "types/routes";
import { useAuth } from "../../auth";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import redearth_black from "../../assets/img/RedEarth-Red-Black-2023-logo.png";
import redearth_white from "../../assets/img/RedEarth-Red-White-2023-Logo.png";

const settings = ["Logout"];

interface INavBarProps {
  routes: IRouteInfo[];
  toggleDarkMode: () => void; // Add the toggleDarkMode prop
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResponsiveAppBar(props: INavBarProps): JSX.Element {

  const { routes } = props;

  const toggleDarkMode = props.toggleDarkMode;

  const location = useLocation();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName: string): boolean {
    return location.pathname === routeName;
  }

  const navigate = useNavigate();
  const { signout } = useAuth();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async (): Promise<void> => {

    await signout();
    navigate("/login");
  };

  const storedTheme = localStorage.getItem("theme");
  const logoSrc = storedTheme === "dark" ? redearth_white : redearth_black; // Change Logos Here

  return (
    <AppBar position="sticky" color="inherit">
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Box
            component="img"
            sx={{width: 150, display: { xs: "none", md: "flex" }, mr: 1}}
            alt="RedEarth Energy Storage"
            src={logoSrc}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((prop, key) => (
                <MenuItem
                  key={prop.name}
                  onClick={handleCloseNavMenu}
                >
                  <NavLink to={prop.path} key={key} style={{ textDecoration: "none",
                    color: "inherit" }}>
                    <Typography color={activeRoute(prop.path) ? "red" : "inherit"}
                      textAlign="center">
                      {prop.name}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            sx={{width: 150, display: { xs: "flex", md: "none" }, mr: 1 }}
            alt="RedEarth Energy Storage"
            src={logoSrc}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes.map((prop, key) => (
              <MenuItem color="inherit" key={prop.name} onClick={handleCloseNavMenu}>
                <NavLink style={{ color: "inherit", textDecoration: "inherit"}}
                  to={prop.path} key={key}>
                  <Typography color={activeRoute(prop.path) ? "red" : "inherit"}
                    textAlign="center">
                    {prop.name}
                  </Typography>
                </NavLink>
              </MenuItem>
            ))}
          </Box>
          <IconButton style={{marginRight:"15px"}}
            onClick={() => {
              toggleDarkMode();
              props.setDarkMode((prevMode) => !prevMode);
            }}
          >
            {storedTheme === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, key) => (
                <MenuItem onClick={handleLogout} key={key}>
                  <Typography color="warning" textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
