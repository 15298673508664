/* eslint-disable comma-spacing */
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../redux/store/hooks";
import { CachedData, EnvironmentalData, FinancialData, PlantId } from "../types/plant";
import { CachedDataIntervals } from "../types/server";
import { simpleStringifyCompare } from "../util/general";
import { UTC_DATE_FORMAT } from "../util/time";
import { useCallback, useState } from "react";
import { fetchEnvironmentalData, fetchFinancialData } from "../api/plant";
import { environmentalDataThunk, financialDataThunk } from "../redux/asyncThunks";
import { selectCurrentPlantId } from "../redux/selectors/plants";
import useIsMounted from "./useIsMounted";
import { CachedDataPerPlant } from "../types/redux";
type variant = "financial" | "environmental";

type CachedDataProps = {
  plantId?: PlantId
}

const useCachedData = <T>({
  variant,
  plantId,
}: {
  variant: variant
} & CachedDataProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const targetId = plantId ?? useAppSelector(selectCurrentPlantId);
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();

  const now = DateTime.now();
  const dayStringFormat = UTC_DATE_FORMAT;
  const splitFormat = UTC_DATE_FORMAT.split("-");
  const monthStringFormat = splitFormat[0] + "-" + splitFormat[1];
  const yearStringFormat = splitFormat[0];

  const targetData = useAppSelector(
    (state) => {
      const emptyReturnData = {
        [CachedDataIntervals.Daily]: {},
        [CachedDataIntervals.Monthly]: {},
        [CachedDataIntervals.Yearly]: {}
      };
      if (!targetId) return emptyReturnData;

      if (variant === "environmental") {
        const targetData = state.appData.environmentalData.data[targetId];
        return targetData ?? emptyReturnData;
      } else {
        const targetData = state.appData.financialData.data[targetId];
        return targetData ?? emptyReturnData;
      }
    },
    simpleStringifyCompare
  ) as unknown as CachedDataPerPlant<T>;

  const getEmptyData = (
    dateTime: DateTime,
  ): T => {
    if (variant === "environmental") {
      const data: EnvironmentalData = {
        plantId: targetId ?? "",
        unixTimestamp: dateTime.toMillis(),
        pvWattHours: 0,
        nonRenewableWattHours: 0,
        activePowerUsageWattHours: 0,
        gridImportWattHours: 0,
        gridExportWattHours: 0,
        batteryDischargeWattHours: 0,
        batteryChargeWattHours: 0
      };
      return data as unknown as T;
    } else {
      const data: FinancialData = {
        plantId: targetId ?? "",
        unixTimestamp: dateTime.toMillis(),
        estimatedCost: 0,
        withoutPvCost: 0,
        energyEarnings: 0
      };
      return data as unknown as T;
    }
  };

  const refreshFinancialData = useCallback(
    (
      interval?: Parameters<typeof fetchFinancialData>[1],
      queryParams?: Parameters<typeof fetchFinancialData>[2]
    ) => {
      if (targetId === undefined) return;
      setIsFetching(true);
      return dispatch(
        financialDataThunk({
          plantId: targetId,
          interval: interval,
          queryParams
        }))
        .finally(() => {
          if (isMounted()) setIsFetching(false);
        });
    },
    [targetId]
  );

  const refreshEnvironmentalData = useCallback(
    (
      interval?: Parameters<typeof fetchEnvironmentalData>[1],
      queryParams?: Parameters<typeof fetchEnvironmentalData>[2]
    ) => {
      setIsFetching(true);
      return dispatch(environmentalDataThunk({
        plantId: targetId,
        interval,
        queryParams
      }))
        .finally(() => {
          if (isMounted()) setIsFetching(false);
        });
    },
    [targetId]
  );

  const dayArray = Object.entries(targetData["daily"]) as [string, T][];
  const monthArray = Object.entries(targetData["monthly"]) as [string, T][];
  const yearArray = Object.entries(targetData["yearly"]) as [string, T][];

  /**
   *
   * @param targetYear in yearStringFormat of "yyyy"
   * @returns array of monthly data recorded in that year
   */
  function monthDataForYear(targetYear: string) {
    const targetDateTime = DateTime.fromFormat(targetYear, yearStringFormat);

    return monthArray
      .filter(([timeString,]) => {
        const dataDate = DateTime.fromFormat(timeString, monthStringFormat);
        return targetDateTime.year === dataDate.year;
      })
      .map((row) => row[1]);
  }

  /**
 *
 * @param targetMonth in yearStringFormat of "yyyy-MM"
 * @returns array of monthly data recorded in that year
 */
  function dayDataForMonth(targetMonth: string) {
    const targetDateTime = DateTime.fromFormat(targetMonth, monthStringFormat);

    return dayArray
      .filter(([timeString,]) => {
        const dataDate = DateTime.fromFormat(timeString, dayStringFormat);
        return targetDateTime.month === dataDate.month && targetDateTime.year === dataDate.year;
      })
      .map((row) => row[1]);
  }

  /**
   *
   * Get a single cached data object which includes all the data
   * for dates inbetween the start and end date.
   *
   * @param startDate start date in daily date format
   * @param endDate end date in daily date format
   * @returns combined cached data object for all days
   */
  function cumulativeDayDataForRange({
    startDate,
    endDate
  }: {
    startDate: DateTime,
    endDate: DateTime,
  }): T {
    const startDateTime = startDate.startOf("day");
    const endDateTime = endDate.endOf("day");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: Record<string, any> = {};
    //this will be used later as props to ignore when tallying up all the data
    const keysToIgnore: Array<keyof CachedData> = [
      "date",
      "plantId",
      "unixTimestamp",
    ];

    dayArray
      // get the data we have that sits inbetween the date range
      .filter(([timeString,]) => {
        const dataDate = DateTime.fromFormat(timeString, dayStringFormat);
        return dataDate >= startDateTime && dataDate <= endDateTime;
      })
      .forEach(([, data]) => {
        const dataAlias = data as unknown as CachedData;
        // for the first data we provide its date, unix and plant id
        // so the cached object is completed.
        if (result["date"] === undefined) {
          keysToIgnore.forEach((key) => result[key] = dataAlias[key]);
        }
        Object.entries(dataAlias)
          // go through and tally up all the values we find in all of the properties
          // for all the objects not in our ignore list
          .forEach(([key, value]) => {
            if (
              !keysToIgnore.includes(key as keyof CachedData) &&
              typeof value === "number"
            ) {
              if (result[key] === undefined) result[key] = value;
              else result[key] = result[key] + value;
            }
          });
      });
    return result as unknown as T;
  }

  /**
   *
   * TODO:
   * eventually, we may need an equivalent of this function
   * where we can get a specific month in a specific year
   * in daily interval. At that time, just modify this function
   * to take a month & year arg and then default it to the current
   * month if nothing is passed.
   *
   * This function finds all the data we have in daily interval
   * for each day of the current month.
   *
   * @returns An array of cached data for every day of the month
   */
  function getThisMonthInDays() {
    const thisMonth: Array<T> = [];
    for (let day = 1; day <= now.day; day++) {
      //make a datetime to match the day we are looping through
      const targetDayDT = DateTime
        .fromObject({ "day": day, "month": now.month, "year": now.year });
      // push the data for the day
      thisMonth.push(
        //try and get the daily data if we have it in the redux
        targetData["daily"][targetDayDT.toFormat(dayStringFormat)] ??
        //if its not in the redux. Default to a empty piece of data.
        getEmptyData(targetDayDT)
      );
    }
    return thisMonth;
  }

  function getThisYearInMonths() {
    const thisYear: Array<T> = [];
    for (let month = 1; month <= now.month; month++) {
      //make a datetime to match the month we are looping through
      const targetDayDT = DateTime
        .fromObject({ "month": month, "year": now.year });
      // push the data for the day
      thisYear.push(
        //try and get the daily data if we have it in the redux
        targetData["monthly"][targetDayDT.toFormat(monthStringFormat)] ??
        //if its not in the redux. Default to a empty piece of data.
        getEmptyData(targetDayDT)
      );
    }
    return thisYear;
  }

  function getAllYears() {
    const years: Array<T> = [];
    if (yearArray.length === 0) return years;

    const allYearUnixTimestamps = yearArray.map(([, data]) => {
      const typeAlias = data as unknown as CachedData;
      return typeAlias.unixTimestamp;
    });

    const indexOfEarliest = allYearUnixTimestamps.indexOf(Math.min(...allYearUnixTimestamps));
    const earliestData = yearArray[indexOfEarliest][1] as unknown as CachedData;
    const earliestYearDateTime = DateTime.fromMillis(earliestData.unixTimestamp);

    for (let year = earliestYearDateTime.year; year <= now.year; year++) {
      const targetDayDT = DateTime.fromObject({ "year": year });
      years.push(
        targetData["yearly"][targetDayDT.toFormat(yearStringFormat)] ??
        getEmptyData(targetDayDT)
      );
    }

    return years;
  }

  return {
    isFetching,
    get: variant === "environmental"
      ? refreshEnvironmentalData
      : refreshFinancialData,
    daily: {
      forMonth: dayDataForMonth,
      today: targetData["daily"][now.toFormat(dayStringFormat)] as unknown as T,
      thisMonth: getThisMonthInDays(),
      cumulativeDayDataForRange: cumulativeDayDataForRange
    },
    monthly: {
      forYear: monthDataForYear,
      thisYear: getThisYearInMonths()
    },
    yearly: {
      allTime: getAllYears()
    },
    stringFormats: {
      dayStringFormat,
      monthStringFormat,
      yearStringFormat
    }

  };
};

export const useFinancialData = (props: CachedDataProps) => useCachedData<FinancialData>({
  "variant": "financial",
  ...props
});

export const useEnvironmentalData = (props: CachedDataProps) => useCachedData<EnvironmentalData>({
  "variant": "environmental",
  ...props
});
