interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

// RedEarth account
export const firebaseConfig: IFirebaseConfig = {
  apiKey: "AIzaSyCMmGy_m2pId9le--rmbhxW8ystLZKlWeg",
  authDomain: "redearthdev-25c04.firebaseapp.com",
  databaseURL:
    "https://redearthdev-25c04-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "redearthdev-25c04",
  storageBucket: "redearthdev-25c04.appspot.com",
  messagingSenderId: "71208737264",
  appId: "1:71208737264:web:26e1a30cabcdd224d6a535"
};
