import React from "react";
import { Grid, Card, CardContent } from "@mui/material";

import { PlantConfig } from "./plantConfig";

function PlantConfigurationPage() {
  return (
    <Grid container spacing={3} >
      <Grid item xs={12}>
        <Card>
          <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <PlantConfig />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PlantConfigurationPage;
